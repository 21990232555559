import { memo, useEffect, Fragment } from "react";
import { useState } from "react";
import {
  addBooksInCartFromsets,
  removeABookInCartforsets,
  removeValiditySetCart
} from "../../assets/Js/script";
import CustomDropDown from "../../components/CustomDropDown";
import CrossIco from "../../assets/images/cross.png";

const Input = ({ onChange, defaultValue, disabled, ...props }) => (
  <input
    className={`mt-0 h-[50px] border-b border-b-[#5F5F5F] w-full bg-[#F5F6FA] font-normal text-center  focus:outline-none ${
      disabled ? "bg-[#EDEDED] text-[#70296370]" : "text-secondary-light"
    }`}
    onChange={onChange}
    defaultValue={defaultValue}
    disabled={disabled}
    type="number"
    min={1}
    {...props}
  />
);

const CartSetCard = (
  ({
    toast,
    set,
    idx,
    accesIdDetails,
    setSetsDetails,
    updateCartDetails,
    setAccesIdDetails,
    currentAccessCodes,
    validityUpdateHandler,
    handleCharges
  }) => {
    const [accessIds, setAccessIds] = useState(
      accesIdDetails
        .filter((a) => a.setId == set.id)
        .sort((a, b) => a.validity - b.validity)
    );
    const [dropDownOptions, setDropDownOptions] = useState([]);

    const addRow = async (setId, maxValidity) => {
      let validity;
      const alreadyAccessIds = accessIds.map((a) => a.validity);
      console.log(alreadyAccessIds, "aa");
      const numbers = [1, 2, 3, 4, 5];

      numbers.forEach((n) => {
        console.log(!alreadyAccessIds.includes(n), n, "inc");
        if (!alreadyAccessIds.includes(n) && !validity) {
          validity = n;
        }
      });
      const { response, error } = await addBooksInCartFromsets(
        [{ id: setId, validity, maxValidity }],
        true
      );
      if (response.status === "success") {
        console.log(response);

        setAccessIds((prev) => [...prev, response.addedSets[0]]);
        setAccesIdDetails((prev) => [
          ...prev.filter((a) => a.setId != set.id),
          ...accessIds,
          response.addedSets[0]
        ]);
      } else {
        toast(response?.message ? response?.message : "Something went wrong");
      }
    };

    async function handleRemoveSet() {
      const { response, error } = await removeABookInCartforsets(set.id, true);
      console.log(response, "resp");
      if ((response.status = "success")) {
        setSetsDetails((prevState) => [
          ...prevState.filter((b) => b.id != set.id)
        ]);
        setAccesIdDetails((prev) => [...prev.filter((a) => a.setId != set.id)]);
      } else {
        toast(response?.message ? response?.message : "Something went wrong");
      }
      if (error) {
        console.log(error, "eeee");
        toast("Something went wrong");
      }
    }

    const changeQuantity = (type, value, validity) => {
      console.log(type, value, validity, "type, value, validity,");
      if (value != "" && Number(value) < 0) {
        value = 0;
      }
      let tmpAccessIds = accessIds;
      let selAid;

      // if (value == '') return
      accessIds.forEach((aid, idx) => {
        if (aid.validity == validity) {
          selAid = aid;
          selAid.aidCount = selAid.numberOfCopies - selAid.qrCodeCount;
          selAid[type] = Number(value ?? 0);

          selAid.numberOfCopies = selAid.aidCount + selAid.qrCodeCount;

          // console.log(selAid)
          tmpAccessIds[idx] = selAid;
        }
      });
      if (selAid) {
        // selAid = { ...selAid, [type]: value, numberOfCopies } // this is to update latest value
        updateCartDetails(selAid.id, selAid.numberOfCopies, selAid.qrCodeCount);
      }
      setAccessIds(tmpAccessIds);

      //updateAccessIdDetails
      let tmpAccessIdDetails = [...accesIdDetails];
      accesIdDetails.forEach((aid, idx) => {
        if (aid.id == selAid.id) {
          tmpAccessIdDetails[idx] = selAid;
        }
      });
      setAccesIdDetails([...tmpAccessIdDetails]);
    };

    const dropDownOptionsHandler = () => {
      let nums = [1, 2, 3, 4, 5];

      const alreadyAccessIds = accessIds.map((a) => a.validity);
      const final = nums
        .filter(
          (a) => a <= accessIds[0].maxValidity && !alreadyAccessIds.includes(a)
        )
        .map((a) => ({ name: a.toString(), value: a.toString() }));
      setDropDownOptions(final);
    };
    const removeRow = async (e, cartId, maxValidity) => {
      e.stopPropagation();
      const { response, error } = await removeValiditySetCart(cartId, true);
      if (response.status === "success") {
        console.log(response);

        setAccessIds((prev) => [...prev.filter((a) => a.id != cartId)]);
        setAccesIdDetails((prev) => [...prev.filter((a) => a.id != cartId)]);
      } else {
        toast(response?.message ? response?.message : "Something went wrong");
      }
    };

    useEffect(() => {
      dropDownOptionsHandler();
    }, [accessIds, accesIdDetails]);

    return (
      <div
        key={set.id}
        className=" flex bg-neutral-0 mb-4 min-h-[226px] border-1 border-solid border-primary-gray"
      >
        <div
          className="flex justify-center items-center w-40"
          style={{ background: set?.backgroundColor }}
        >
          <img
            src={`https://${process.env.REACT_APP_AWS_BUCKET_NAME}.s3.amazonaws.com/setCovers/${set.cover}`}
            alt="front cover"
            className="lg:h-[85%] object-cover shadow-xl"
          />
        </div>
        <div className="p-4 w-id-card">
          <div className="flex justify-between items-center mb-6 ">
            <h3 className="text-heading font-semibold text-p1 text-left capitalize">
              {set.title}
            </h3>
            <div
              title="Remove set"
              onClick={handleRemoveSet}
              className="ml-[2px] mr-[15px] cursor-pointer"
            >
              <svg
                width="14"
                height="17"
                viewBox="0 0 10 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.999674 10.0833C0.999674 10.725 1.59967 11.25 2.33301 11.25H7.66634C8.39967 11.25 8.99967 10.725 8.99967 10.0833V3.08333H0.999674V10.0833ZM9.66634 1.33333H7.33301L6.66634 0.75H3.33301L2.66634 1.33333H0.333008V2.5H9.66634V1.33333Z"
                  fill="#585858"
                />
              </svg>
            </div>
          </div>
          <table class="table-auto text-center align-middle">
            <tr className="text-secondary-dark font-[500] text-h5 ">
              <th className="px-3">Validity</th>
              <th className="px-3">AID’s</th>
              <th className="px-3">QID’s</th>
              <th className="px-3">Quantity</th>
              <th className="px-3">Tokens</th>
              <th className="px-3"></th>
            </tr>

            {accessIds.map((aid) => (
              <Fragment key={aid.validity }>
                <tr className="text-secondary-light text-[10px]">
                  <th className=""></th>
                  <th className="">
                    <p className="">
                      Unused:{" "}
                      {currentAccessCodes.find(
                        (a) =>
                          a.validity == aid.validity &&
                          a.bookId == set.parentId &&
                          a.isQr == false
                      )?.count ?? 0}
                    </p>
                  </th>
                  <th className="">
                    <p className="">
                      Unused:{" "}
                      {currentAccessCodes.find(
                        (a) =>
                          a.validity == aid.validity &&
                          a.bookId == set.parentId &&
                          a.isQr == true
                      )?.count ?? 0}
                    </p>
                  </th>
                  <th className=""></th>
                  <th className=""></th>
                </tr>
                <tr>
                  <td className="px-3">
                    {/* <Input value={aid.validity} /> */}
                    <CustomDropDown
                      values={[{ name: aid.validity, value: aid.validity }]}
                      onChange={(v) => validityUpdateHandler(aid.id, v[0].name)}
                      options={dropDownOptions}
                      labelField={"name"}
                      noDataRenderer={() => (
                        <p className="p-1 text-[12px]">No Validity Available</p>
                      )}
                      style={{
                        // marginTop: 12,
                        border: "none",
                        borderBottom: "1px solid #5F5F5F",
                        backgroundColor: "#F5F6FA",
                        color: "#8F959E",
                        height: 50
                      }}
                    />
                  </td>
                  <td className="px-3">
                    <Input
                      value={
                        aid.aidCount ??
                        Number(aid.numberOfCopies) - Number(aid.qrCodeCount)
                      }
                      onChange={(e) =>
                        changeQuantity(
                          "aidCount",
                          Number(e.target.value),
                          aid.validity
                        )
                      }
                    />
                  </td>
                  <td className="px-3">
                    <Input
                      defaultValue={aid.qrCodeCount }
                      onChange={(e) =>
                        changeQuantity(
                          "qrCodeCount",
                          e.target.value,
                          aid.validity
                        )
                      }
                    />
                  </td>
                  <td className="px-3">
                    <Input value={Number(aid.numberOfCopies)} disabled />
                  </td>
                  <td className="px-3">
                    <Input
                      value={handleCharges(
                        aid.validity,
                        aid.numberOfCopies,
                        aid.setId
                      )}
                      disabled
                    />
                  </td>
                  <td>
                    {accessIds.length > 1 && (
                      <img
                        title="Remove validity"
                        onClick={(e) => removeRow(e, aid.id)}
                        src={CrossIco}
                        className="w-3 h-3 object-contain cursor-pointer"
                      />
                    )}
                  </td>
                </tr>
              </Fragment>
            ))}
          </table>

          {accessIds[0]?.maxValidity > accessIds.length && (
            <p
              onClick={() => addRow(set.id, accessIds[0].maxValidity)}
              className="text-thumb mt-3 mx-6 cursor-pointer min-w-min w-20"
            >
              Add Row
            </p>
          )}
        </div>
      </div>
    );
  }
);

export default CartSetCard;
