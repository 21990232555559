export const ddmmyyyyhhmm = inputTimestamp => {
  if (!inputTimestamp) return;

  const date = new Date(inputTimestamp);

  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();
  let hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const ampm = hours >= 12 ? "pm" : "am";
  if (hours > 12) hours -= 12;

  return `${day}-${month}-${year} ${hours}:${minutes} ${ampm}`;
};
