import { useState, useEffect } from "react";
import Input from "../../components/form/input";
import { BtnBlock, Loader } from "../../components";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import * as ROUTES from "../../constant/routes";
// import * as API from "../../constant/api";
import login1 from "../../assets/images/login/login1.png";
import login2 from "../../assets/images/login/login2.png";
import login3 from "../../assets/images/login/login3.png";
import { handleLogin } from "../../assets/Js/script";
import { useAuth } from "../../hooks";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactTooltip from "react-tooltip";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import HtmlAni from "./HtmlAni";

export default function Login() {
  const [slideShowButtons, setSlideShowButtons] = useState([
    { id: 0, active: true },
    { id: 1, active: false },
    { id: 2, active: false },
  ]);
  const navigate = useNavigate();
  const [getStarted, setGetStarted] = useState(false);
  const [login, setLogin] = useState(false);
  const [data, setData] = useState({
    email: "",
    password: "",
  });

  let settings = {
    // dots: true,
    // infinite: true,
    speed: 1000,
    slidesToShow: 1,
    infinite: true,
    slidesToScroll: 1,
    autoplay: true,
  };
  const [showPassword, setShowPassword] = useState(false);

  const [isLoaderActive, setIsLoaderActive] = useState(false);

  // login --------------------------------------------
  const notify = (response) =>
    toast.warn(response, {
      position: "top-center",
    });

  const { setAuth, setUser } = useAuth();

  function handleChange({ target }) {
    const { name, value } = target;

    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setIsLoaderActive(true);
    const { response } = await handleLogin(data);

    if (response.status === "success") {
      setAuth(response.token);
      setUser({ ...response.data.user });
      setIsLoaderActive(false);
    } else {
      setIsLoaderActive(false);
      notify(response.message);
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [login]);

  return (
    <div className="min-w-screen flex min-h-screen box-border ">
      <div className="w-1/2  justify-center items-center border-r-8 relative  border-r-secondary-yellow border-solid hidden sm:flex">
        <form
          onSubmit={handleSubmit}
          className="flex justify-between flex-col md:w-[300px] xl:w-354 h-100 "
        >
          <h1 className="md:text-tablet-h1 lg:text-laptop-h1 xl:text-h1 text-tertiary-dark font-bold ">
            Welcome
          </h1>
          <Input
            label="Email Address"
            type="text"
            placeholder="Enter Email Address"
            name="email"
            value={data.email}
            onChange={handleChange}
            required
          />
          <div className="relative">
            <Input
              label="Password"
              type={showPassword ? "text" : "password"}
              placeholder="Enter Password"
              name="password"
              value={data.password}
              onChange={handleChange}
              className='focus:outline-none'
              required
            />
            <button
              className="absolute focus:outline-none  right-4 top-[60px]"
              onClick={(e) => {
                e.preventDefault();
                setShowPassword((prevState) => !prevState);
              }}
              type='button'
            >
              {showPassword ? (
                <svg
                  data-tip="Hide Password"
                  width="27"
                  height="12"
                  viewBox="0 0 27 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="focus:outline-none"
                >
                  <path
                    d="M17.5465 5.99788C17.5465 7.58673 15.7315 8.87475 13.4927 8.87475C11.2538 8.87475 9.43889 7.58673 9.43889 5.99788C9.43889 4.40901 11.2538 3.12101 13.4927 3.12101C15.7315 3.121 17.5465 4.40903 17.5465 5.99788ZM13.5 0.0078125C11.1819 0.0154292 8.77919 0.433446 6.50466 1.23376C4.81586 1.85246 3.17003 2.72536 1.74137 3.80278C1.03968 4.35276 0.144698 5.14913 0 5.99881C0.0171 6.73483 1.08292 7.6433 1.74137 8.19486C3.08108 9.22996 4.68405 10.0785 6.50466 10.7645C8.62576 11.527 10.9735 11.966 13.5 11.9905C15.8203 11.9827 18.2225 11.5599 20.4945 10.7645C22.1833 10.1458 23.83 9.27228 25.2586 8.19486C25.9603 7.64488 26.8553 6.8485 27 5.99881C26.9829 5.2628 25.9171 4.3543 25.2586 3.80275C23.9189 2.76765 22.3151 1.91976 20.4945 1.23373C18.3745 0.471796 16.0209 0.0357958 13.5 0.0078125ZM13.4983 1.49528C17.011 1.49528 19.8585 3.51185 19.8585 5.99946C19.8585 8.48706 17.011 10.5036 13.4983 10.5036C9.98561 10.5036 7.1381 8.48705 7.1381 5.99946C7.1381 3.51185 9.98561 1.49528 13.4983 1.49528Z"
                    fill="#ADAAAA"
                  />
                </svg>
              ) : (
                <svg
                  data-tip="See Password"
                  width="27"
                  height="18"
                  viewBox="0 0 27 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="focus:outline-none"
                >
                  <path
                    d="M15.0689 3.55894C14.5538 3.51767 14.0305 3.49402 13.5 3.48814C11.1819 3.49575 8.77898 3.91342 6.50446 4.71374C4.81565 5.33244 3.17056 6.20589 1.74188 7.28331C1.04022 7.83331 0.144697 8.62968 0 9.47935C0.0171 10.2154 1.08344 11.1238 1.74188 11.6754C3.0816 12.7105 4.68385 13.559 6.50446 14.245C6.56633 14.2672 6.62843 14.2892 6.69067 14.3109L5.00155 16.4959L7.29666 17.5007L19.7035 1.50013L17.4943 0.5L15.0689 3.55894V3.55894ZM20.3077 4.65026L18.6218 6.81454C19.3974 7.56091 19.8578 8.48131 19.8578 9.47935C19.8578 11.967 17.011 13.9838 13.4983 13.9838C13.3465 13.9838 13.1993 13.9716 13.0501 13.9642L11.9344 15.3949C12.449 15.4357 12.9692 15.4654 13.5 15.4706C15.8202 15.4629 18.2218 15.0404 20.4939 14.245C22.1827 13.6263 23.8294 12.7528 25.2581 11.6754C25.9598 11.1254 26.8553 10.329 27 9.47935C26.9829 8.74333 25.9165 7.83486 25.2581 7.28329C23.9184 6.24819 22.3145 5.39974 20.4939 4.71371C20.4324 4.69164 20.3695 4.67179 20.3077 4.65026V4.65026ZM13.4984 4.97497C13.6524 4.97497 13.8052 4.97954 13.9565 4.98717L12.6497 6.66319C10.8156 6.93863 9.43945 8.09453 9.43945 9.47815C9.43945 9.82571 9.52587 10.1585 9.68499 10.4669C9.68517 10.4672 9.68481 10.4678 9.68499 10.4681L8.37486 12.149C7.59746 11.402 7.13889 10.4785 7.13889 9.47933C7.13891 6.99174 9.98568 4.97496 13.4984 4.97497V4.97497ZM17.3002 8.50889L14.3553 12.2882C16.1795 12.0076 17.5457 10.8569 17.5457 9.47815C17.5457 9.13691 17.4538 8.81249 17.3002 8.50889V8.50889Z"
                    fill="#ADAAAA"
                  />
                </svg>
              )}
            </button>
          </div>
          <RouterLink
            className="text-h4 mb-2 text-right font-semibold"
            to={ROUTES.FORGOT_PASSWORD}
          >
            Forgot Password?
          </RouterLink>
          <BtnBlock>Login</BtnBlock>
          <p className="text-h4 text-center font-semibold">
            Don't have an account?{" "}
            <RouterLink
              to={ROUTES.SIGN_UP}
              className="text-tertiary-dark border-b-2 border-b-solid border-b-primary-dark"
            >
              Register
            </RouterLink>
          </p>
        </form>
        <button className="text-p absolute bottom-3 right-3 text-tertiary-dark underline">
          <a target={"_blank"} href="https://forms.gle/1QNX2Pyh1ZKKzLzc6">
            Issue with Login
          </a>
        </button>
      </div>
      {login ? (
        <div className="py-8 relative px-5 lg:p-0 w-full lg:w-3/5 flex justify-center items-start md:hidden">
          <form
            onSubmit={handleSubmit}
            className="flex justify-between flex-col w-354 h-100 "
          >
            <h1 className="text-mobile-h1 lg:text-h1 text-tertiary-dark font-bold ">
              Welcome back
            </h1>
            <Input
              label="Email Address"
              type="text"
              placeholder="Enter Email Address"
              name="email"
              value={data.email}
              onChange={handleChange}
              required
            />
            <Input
              label="Password"
              type="password"
              placeholder="Enter Password"
              name="password"
              value={data.password}
              onChange={handleChange}
              required
            />
            <RouterLink
              className="text-mobile-h4 lg:text-h4 mb-2 text-right font-semibold"
              to={ROUTES.FORGOT_PASSWORD}
            >
              Forgot Password ?
            </RouterLink>
            <BtnBlock onClick={notify}>Login</BtnBlock>
            <p className="text-mobile-h4 lg:text-h4 text-center font-semibold">
              Don't have an account?{" "}
              <RouterLink
                to={ROUTES.SIGN_UP}
                className="text-tertiary-dark border-b-2 border-b-solid border-b-primary-dark"
              >
                Register
              </RouterLink>
            </p>
          </form>
          <button className="text-p absolute bottom-3 left-[40%] text-tertiary-dark underline">
            <a target={"_blank"} href="href: https://forms.gle/1QNX2Pyh1ZKKzLzc6">
              Issue with Login
            </a>
          </button>
        </div>
      ) : (
        <>
          <div className=" w-full lg:w-2/5 lg:h-screen justify-center items-center relative bg-primary-dark flex sm:hidden">
            <div className="bg-primary-dark flex flex-col items-center w-11/12 ">
              <div className="w-full">
                <img
                  className="h-11/12 px-3 lg:px-0 lg:h-full block"
                  src={login1}
                  alt="login slideshow 1"
                />
              </div>
              <p className="text-secondary-light mt-10 text-h4 font-semibold text-center">
                Lorem ipsum dolor sit amet,
                <br /> consectetur adi
              </p>
              {getStarted ? (
                <div className="lg:hidden flex justify-center items-center flex-col mt-10">
                  <button
                    onClick={() => navigate(ROUTES.SIGN_UP)}
                    className="mb-5 w-[300px] h-[51px] bg-primary-white text-tertiary-dark text-p1 font-semibold"
                  >
                    Sign up
                  </button>
                  <button
                    onClick={() => setLogin(true)}
                    className="mb-5 w-[300px] h-[51px] bg-primary-dark border-1 border-solid border-primary-white text-primary-white text-p1 font-semibold"
                  >
                    Log in
                  </button>
                </div>
              ) : (
                <button
                  onClick={() => setGetStarted(true)}
                  className="mt-10 w-[200px] h-14 bg-primary-white text-heading text-p1 font-semibold md:hidden"
                >
                  Get Started
                </button>
              )}
              <div className="w-24 h-4 mt-8 lg:mt-10 flex justify-evenly mx-auto items-center">
                {slideShowButtons.map((item) =>
                  item.active ? (
                    <button
                      key={item.id}
                      className="w-8 h-2 bg-primary-white rounded-l-xl rounded-r-xl"
                    ></button>
                  ) : (
                    <button
                      key={item.id}
                      className="w-2 h-2 bg-primary-white rounded-full "
                    ></button>
                  )
                )}
              </div>
            </div>

            {/* relative elements */}
            <div className="absolute top-8 -left-12">
              <svg
                width="115"
                height="164"
                viewBox="0 0 115 164"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g filter="url(#filter0_f_1766_5585)">
                  <path
                    d="M71.6674 140.24C86.3853 124.86 124.585 92.5501 109.206 51.2368C93.8275 9.92345 2.64301 -36.1161 2.71204 54.3921C2.73783 88.394 -0.691774 137.369 9.64573 151.697C26.8222 175.508 62.48 149.839 71.6674 140.24Z"
                    fill="#ECEDFF"
                    fillOpacity="0.3"
                  />
                </g>
                <defs>
                  <filter
                    id="filter0_f_1766_5585"
                    x="0.186523"
                    y="0.140991"
                    width="114.603"
                    height="163.396"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                  >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="BackgroundImageFix"
                      result="shape"
                    />
                    <feGaussianBlur
                      stdDeviation="1"
                      result="effect1_foregroundBlur_1766_5585"
                    />
                  </filter>
                </defs>
              </svg>
            </div>

            <div className="absolute top-0 right-0">
              <svg
                width="77"
                height="148"
                viewBox="0 0 77 148"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g filter="url(#filter0_f_1766_5588)">
                  <path
                    d="M38.5882 9.9185C24.8818 26.4457 -11.2997 61.7583 7.60116 101.69C26.502 141.621 122.697 180.101 115.404 89.8871C112.665 55.9957 112.237 6.89761 100.605 -6.54347C81.2764 -28.8822 47.1443 -0.396586 38.5882 9.9185Z"
                    fill="#ECEDFF"
                    fillOpacity="0.3"
                  />
                </g>
                <defs>
                  <filter
                    id="filter0_f_1766_5588"
                    x="0.25"
                    y="-16.8807"
                    width="117.548"
                    height="163.97"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                  >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="BackgroundImageFix"
                      result="shape"
                    />
                    <feGaussianBlur
                      stdDeviation="1"
                      result="effect1_foregroundBlur_1766_5588"
                    />
                  </filter>
                </defs>
              </svg>
            </div>

            <div className="absolute bottom-8 left-0">
              <svg
                width="115"
                height="164"
                viewBox="0 0 115 164"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g filter="url(#filter0_f_1766_5585)">
                  <path
                    d="M71.6674 140.24C86.3853 124.86 124.585 92.5501 109.206 51.2368C93.8275 9.92345 2.64301 -36.1161 2.71204 54.3921C2.73783 88.394 -0.691774 137.369 9.64573 151.697C26.8222 175.508 62.48 149.839 71.6674 140.24Z"
                    fill="#ECEDFF"
                    fillOpacity="0.3"
                  />
                </g>
                <defs>
                  <filter
                    id="filter0_f_1766_5585"
                    x="0.186523"
                    y="0.140991"
                    width="114.603"
                    height="163.396"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                  >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="BackgroundImageFix"
                      result="shape"
                    />
                    <feGaussianBlur
                      stdDeviation="1"
                      result="effect1_foregroundBlur_1766_5585"
                    />
                  </filter>
                </defs>
              </svg>
            </div>

            <div className="absolute bottom-8 right-0">
              <svg
                width="50"
                height="165"
                viewBox="0 0 50 165"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g filter="url(#filter0_f_1766_5589)">
                  <path
                    d="M38.5882 26.9185C24.8818 43.4457 -11.2997 78.7583 7.60116 118.69C26.502 158.621 122.697 197.101 115.404 106.887C112.665 72.9957 112.237 23.8976 100.605 10.4565C81.2764 -11.8822 47.1443 16.6034 38.5882 26.9185Z"
                    fill="#ECEDFF"
                    fillOpacity="0.3"
                  />
                </g>
                <defs>
                  <filter
                    id="filter0_f_1766_5589"
                    x="0.25"
                    y="0.119263"
                    width="117.548"
                    height="163.97"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                  >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="BackgroundImageFix"
                      result="shape"
                    />
                    <feGaussianBlur
                      stdDeviation="1"
                      result="effect1_foregroundBlur_1766_5589"
                    />
                  </filter>
                </defs>
              </svg>
            </div>
          </div>
        </>
      )}

      <div style={{"background" : "linear-gradient(90deg, #7f2e6f, #5c2150)"}} className=" w-full md:w-1/2 lg:h-screen relative bg-primary-dark">
        {/* <Slider
          //  style={{
          //  height : "100%",
          //  display: "flex",
          //  justifyContent : "center",
          //  alignItems: "center",
          // }}
       
          autoplay={true}
          autoplaySpeed={2500}
          // dots={true}
          infinite={true}
          slidesToShow={1}
          slidesToScroll={1}
          speed={1000}
          // prevArrow={<ArrowBackIos/>}
          // nextArrow={<ArrowForwardIos />}
        >
          <div className="flex justify-center items-center relative mt-auto mb-auto ">
            <div className="bg-primary-dark flex flex-col items-center w-11/12 ">
              <div className="md:w-8/12 lg:w-4/6 xl:w-9/12">
                <img
                  className=" px-3 lg:px-0 h-full block"
                  src={login1}
                  alt="login slideshow 1"
                />
              </div>
              <p className="text-secondary-light mt-10 text-h4 font-semibold text-center">
              Sample USP 1 goes here
              </p>
              {getStarted ? (
                <div className="lg:hidden flex justify-center items-center flex-col mt-10">
                  <button
                    onClick={() => navigate(ROUTES.SIGN_UP)}
                    className="mb-5 w-[300px] h-[51px] bg-primary-white text-tertiary-dark text-p1 font-semibold"
                  >
                    Sign up
                  </button>
                  <button
                    onClick={() => setLogin(true)}
                    className="mb-5 w-[300px] h-[51px] bg-primary-dark border-1 border-solid border-primary-white text-primary-white text-p1 font-semibold"
                  >
                    Log in
                  </button>
                </div>
              ) : (
                <button
                  onClick={() => setGetStarted(true)}
                  className="mt-10 w-[200px] h-14 bg-primary-white text-heading text-p1 font-semibold md:hidden"
                >
                  Get Started
                </button>
              )}
              
            </div>
          </div>
          <div className="flex justify-center items-center relative mt-auto mb-auto ">
            <div className="bg-primary-dark flex flex-col items-center w-11/12 ">
              <div className="md:w-8/12 lg:w-4/6 xl:w-9/12">
                <img
                  className=" px-3 lg:px-0 h-full block"
                  src={login1}
                  alt="login slideshow 1"
                />
              </div>
              <p className="text-secondary-light mt-10 text-h4 font-semibold text-center">
                Sample USP 2 goes here
              </p>
              {getStarted ? (
                <div className="lg:hidden flex justify-center items-center flex-col mt-10">
                  <button
                    onClick={() => navigate(ROUTES.SIGN_UP)}
                    className="mb-5 w-[300px] h-[51px] bg-primary-white text-tertiary-dark text-p1 font-semibold"
                  >
                    Sign up
                  </button>
                  <button
                    onClick={() => setLogin(true)}
                    className="mb-5 w-[300px] h-[51px] bg-primary-dark border-1 border-solid border-primary-white text-primary-white text-p1 font-semibold"
                  >
                    Log in
                  </button>
                </div>
              ) : (
                <button
                  onClick={() => setGetStarted(true)}
                  className="mt-10 w-[200px] h-14 bg-primary-white text-heading text-p1 font-semibold md:hidden"
                >
                  Get Started
                </button>
              )}
              <div className="w-24 h-4 mt-8 lg:mt-10 flex justify-evenly mx-auto items-center">
                
              </div>
            </div>
          </div>
          <div className="flex justify-center items-center relative mt-auto mb-auto ">
            <div className="bg-primary-dark flex flex-col items-center w-11/12 ">
              <div className="md:w-8/12 lg:w-4/6 xl:w-9/12">
                <img
                  className=" px-3 lg:px-0 h-full block"
                  src={login1}
                  alt="login slideshow 1"
                />
              </div>
              <p className="text-secondary-light mt-10 text-h4 font-semibold text-center">
              Sample USP 3 goes here
              </p>
              {getStarted ? (
                <div className="lg:hidden flex justify-center items-center flex-col mt-10">
                  <button
                    onClick={() => navigate(ROUTES.SIGN_UP)}
                    className="mb-5 w-[300px] h-[51px] bg-primary-white text-tertiary-dark text-p1 font-semibold"
                  >
                    Sign up
                  </button>
                  <button
                    onClick={() => setLogin(true)}
                    className="mb-5 w-[300px] h-[51px] bg-primary-dark border-1 border-solid border-primary-white text-primary-white text-p1 font-semibold"
                  >
                    Log in
                  </button>
                </div>
              ) : (
                <button
                  onClick={() => setGetStarted(true)}
                  className="mt-10 w-[200px] h-14 bg-primary-white text-heading text-p1 font-semibold md:hidden"
                >
                  Get Started
                </button>
              )}
              <div className="w-24 h-4 mt-8 lg:mt-10 flex justify-evenly mx-auto items-center">
                
              </div>
            </div>
          </div>
        </Slider> */}
        {/* <div className="absolute top-8 -left-12">
          <svg
            width="115"
            height="164"
            viewBox="0 0 115 164"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g filter="url(#filter0_f_1766_5585)">
              <path
                d="M71.6674 140.24C86.3853 124.86 124.585 92.5501 109.206 51.2368C93.8275 9.92345 2.64301 -36.1161 2.71204 54.3921C2.73783 88.394 -0.691774 137.369 9.64573 151.697C26.8222 175.508 62.48 149.839 71.6674 140.24Z"
                fill="#ECEDFF"
                fillOpacity="0.3"
              />
            </g>
            <defs>
              <filter
                id="filter0_f_1766_5585"
                x="0.186523"
                y="0.140991"
                width="114.603"
                height="163.396"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                />
                <feGaussianBlur
                  stdDeviation="1"
                  result="effect1_foregroundBlur_1766_5585"
                />
              </filter>
            </defs>
          </svg>
        </div>

        <div className="absolute top-0 right-0">
          <svg
            width="77"
            height="148"
            viewBox="0 0 77 148"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g filter="url(#filter0_f_1766_5588)">
              <path
                d="M38.5882 9.9185C24.8818 26.4457 -11.2997 61.7583 7.60116 101.69C26.502 141.621 122.697 180.101 115.404 89.8871C112.665 55.9957 112.237 6.89761 100.605 -6.54347C81.2764 -28.8822 47.1443 -0.396586 38.5882 9.9185Z"
                fill="#ECEDFF"
                fillOpacity="0.3"
              />
            </g>
            <defs>
              <filter
                id="filter0_f_1766_5588"
                x="0.25"
                y="-16.8807"
                width="117.548"
                height="163.97"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                />
                <feGaussianBlur
                  stdDeviation="1"
                  result="effect1_foregroundBlur_1766_5588"
                />
              </filter>
            </defs>
          </svg>
        </div>

        <div className="absolute bottom-8 left-0">
          <svg
            width="115"
            height="164"
            viewBox="0 0 115 164"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g filter="url(#filter0_f_1766_5585)">
              <path
                d="M71.6674 140.24C86.3853 124.86 124.585 92.5501 109.206 51.2368C93.8275 9.92345 2.64301 -36.1161 2.71204 54.3921C2.73783 88.394 -0.691774 137.369 9.64573 151.697C26.8222 175.508 62.48 149.839 71.6674 140.24Z"
                fill="#ECEDFF"
                fillOpacity="0.3"
              />
            </g>
            <defs>
              <filter
                id="filter0_f_1766_5585"
                x="0.186523"
                y="0.140991"
                width="114.603"
                height="163.396"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                />
                <feGaussianBlur
                  stdDeviation="1"
                  result="effect1_foregroundBlur_1766_5585"
                />
              </filter>
            </defs>
          </svg>
        </div>

        <div className="absolute bottom-8 right-0">
          <svg
            width="50"
            height="165"
            viewBox="0 0 50 165"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g filter="url(#filter0_f_1766_5589)">
              <path
                d="M38.5882 26.9185C24.8818 43.4457 -11.2997 78.7583 7.60116 118.69C26.502 158.621 122.697 197.101 115.404 106.887C112.665 72.9957 112.237 23.8976 100.605 10.4565C81.2764 -11.8822 47.1443 16.6034 38.5882 26.9185Z"
                fill="#ECEDFF"
                fillOpacity="0.3"
              />
            </g>
            <defs>
              <filter
                id="filter0_f_1766_5589"
                x="0.25"
                y="0.119263"
                width="117.548"
                height="163.97"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                />
                <feGaussianBlur
                  stdDeviation="1"
                  result="effect1_foregroundBlur_1766_5589"
                />
              </filter>
            </defs>
          </svg>
        </div> */}

      {/* <div className="flex justify-center items-center w-full h-screen " > */}
        {/* <video controlsList="nodownload" src="https://eprakashak.s3.ap-south-1.amazonaws.com/media/forpublisher.webm" loop autoPlay muted  controls  /> */}
        
        
      {/* </div> */}
      <HtmlAni></HtmlAni>
      </div>

      {/* Comment */}
      <div className="hidden h-full justify-center items-center  bg-primary-dark">
        <div className="bg-primary-dark flex flex-col items-center w-11/12 ">
          <div className="md:w-8/12 lg:w-4/6 xl:w-9/12">
            <img
              className=" px-3 lg:px-0 h-full block"
              src={login1}
              alt="login slideshow 1"
            />
          </div>
          <p className="text-secondary-light mt-10 text-h4 font-semibold text-center">
            Lorem ipsum dolor sit amet,
            <br /> consectetur adi
          </p>
          {getStarted ? (
            <div className="lg:hidden flex justify-center items-center flex-col mt-10">
              <button
                onClick={() => navigate(ROUTES.SIGN_UP)}
                className="mb-5 w-[300px] h-[51px] bg-primary-white text-tertiary-dark text-p1 font-semibold"
              >
                Sign up
              </button>
              <button
                onClick={() => setLogin(true)}
                className="mb-5 w-[300px] h-[51px] bg-primary-dark border-1 border-solid border-primary-white text-primary-white text-p1 font-semibold"
              >
                Log in
              </button>
            </div>
          ) : (
            <button
              onClick={() => setGetStarted(true)}
              className="mt-10 w-[200px] h-14 bg-primary-white text-heading text-p1 font-semibold md:hidden"
            >
              Get Started
            </button>
          )}
          <div className="w-24 h-4 mt-8 lg:mt-10 flex justify-evenly mx-auto items-center">
            {slideShowButtons.map((item) =>
              item.active ? (
                <button
                  key={item.id}
                  className="w-8 h-2 bg-primary-white rounded-l-xl rounded-r-xl"
                ></button>
              ) : (
                <button
                  key={item.id}
                  className="w-2 h-2 bg-primary-white rounded-full "
                ></button>
              )
            )}
          </div>
        </div>

        {/* relative elements */}
      </div>
{/* comment */}
      <Loader show={isLoaderActive} />
      <ToastContainer position="top-center" />
      <ReactTooltip
        place="right"
        backgroundColor="#737272"
        type="success"
        effect="solid"
        offset={{ bottom: 0 }}
        delayShow={200}
      />
    </div>
  );
}
