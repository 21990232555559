import React, { useState } from "react";
import { Loader, Navbar, Page } from "../../components";
import ValidateQrSearch from "./components/ValidateQrSearch";
import { getQrCodeDetails } from "../../assets/Js/script";
import BookExpirationInfo from "./components/BookExpirationInfo";
import { ToastContainer, toast } from "react-toastify";
import QrCodeComp from "./components/QrCodeComp";
import { useAuth } from "../../hooks";

const ValidateQr = () => {
  const { user } = useAuth();

  const [qrDetails, setQrDetails] = useState();
  const [bookDetails, setBookDetails] = useState();
  const [loading, setLoading] = useState(false);
  const [selectedCover, setSelectedCover] = useState();
  const [seeMore, setSeeMore] = useState(false);
  const [isSet, setIsSet] = useState(false);

  const [status, setStatus] = useState({});
  const searchHandler = async (searchKey) => {
    setStatus({});
    setLoading(true);
    const fullQr = `${user.pId.split("-")[0]}-QR-${searchKey}`;
    const { response, error } = await getQrCodeDetails(fullQr);
    if (response?.status === "success") {
      console.log(response, "rrrr");
      setBookDetails(response?.data?.data);
      setQrDetails(response?.data?.qrDetails);
      setIsSet(response.data.isSet);
    } else if (response?.message === "Invalid Code") {
      setStatus({ invalid: true });
    } else {
      toast(response?.message ?? "Something went wrong");
    }
    if (error) {
      toast("Something went wrong");
    }
    console.log(searchKey);
    setLoading(false);
  };

  return (
    <Page active={"grievance"}>
      <Navbar />
      <div className="relative px-2 ">
        <h1 className="mb-3 mt-[15px] flex items-center text-heading text-left text-[20px] font-[600] ">
          QR Code Validity
        </h1>
        <ValidateQrSearch
    bookDetails={bookDetails}
          onclearSearch={() => {
            setBookDetails();
            setQrDetails();
          }}
          onSearch={searchHandler}
        />
        {loading ? (
          <Loader show={loading} />
        ) : status.invalid ? (
          <div className="flex items-center  h-[300px] ">
            <div className="flex w-3/4 h-3/4 bg-secondary-light items-center justify-center">
              <p>
                The entered QR id is invalid please try again with the correct
                QR id.
              </p>
            </div>
          </div>
        ) : (
          bookDetails && (
            <div>
              <div className="p-5 w-full flex  flex-col md:flex-row">
                <div className="flex-1">
                  <div className="hidden   md:flex  flex-col md:flex-row">
                    <div className="md:w-1/4 w-full flex flex-col">
                      <div>
                        <div
                          className="p-3"
                          style={{
                            backgroundColor: bookDetails?.backgroundColor
                          }}
                        >
                          <img
                            className="object-cover w-[100%]"
                            src={`https://${
                              process.env.REACT_APP_AWS_BUCKET_NAME
                            }.s3.amazonaws.com/${
                              isSet ? "setCovers" : "books"
                            }/${
                              selectedCover ?? isSet
                                ? bookDetails.cover
                                : bookDetails?.imageCoverFront
                            }`}
                            alt="bookDetails front cover"
                          />
                        </div>
                        <div className="flex  flex-col lg:flex-row ml-3 lg:ml-0 lg:mt-4">
                          <div
                            className="overflow-hidden lg:ml-1 lg:mr-4 w-[58px] cursor-pointer"
                            onClick={() =>
                              setSelectedCover(
                                isSet
                                  ? bookDetails?.cover
                                  : bookDetails?.imageCoverFront
                              )
                            }
                          >
                            <img
                              className="h-full object-cover"
                              src={`https://${
                                process.env.REACT_APP_AWS_BUCKET_NAME
                              }.s3.amazonaws.com/${
                                isSet ? "setCovers" : "books"
                              }/${
                                selectedCover ?? isSet
                                  ? bookDetails.cover
                                  : bookDetails?.imageCoverFront
                              }`}
                              alt="front cover"
                            />
                          </div>
                          {bookDetails?.imageCoverBack && (
                            <div
                              className="overflow-hidden lg:mr-4 w-[58px] cursor-pointer"
                              onClick={() =>
                                setSelectedCover(bookDetails?.imageCoverBack)
                              }
                            >
                              <img
                                className="h-full object-cover "
                                src={`https://${process.env.REACT_APP_AWS_BUCKET_NAME}.s3.amazonaws.com/books/${bookDetails?.imageCoverBack}`}
                                alt="back cover"
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className=" w-full md:w-3/4 ml-2 mt-5 lg:mt-0 flex flex-col mx-2">
                      <h1 className="flex items-center text-mobile-h1  lg:text-h1 text-heading text-center md:text-left font-bold lg:font-semibold">
                        {bookDetails?.title}
                      </h1>
                      {!(
                        bookDetails?.shortDescription == null ||
                        bookDetails?.longDescription == null
                      ) && (
                        <>
                          <p className="text-tertiary-dark capitalize lg:uppercase tracking-wide font-bold lg:font-semibold text-h5 mt-4">
                            description
                          </p>
                          <p className="text-secondary-dark text-p1 my-2 lg:my-1 lg:text-h5 tracking-[0.01em] leading-6 text-left">
                            {bookDetails?.shortDescription}
                            {seeMore && bookDetails.longDescription}
                            {bookDetails.longDescription && (
                              <span
                                onClick={() => setSeeMore((prev) => !prev)}
                                className="cursor-pointer text-tertiary-dark underline"
                              >
                                {" "}
                                {!seeMore ? "Read more" : "Read less"}
                              </span>
                            )}
                          </p>
                        </>
                      )}
                      {isSet && bookDetails.description && (
                        <>
                          <p className="text-tertiary-dark capitalize lg:uppercase tracking-wide font-bold lg:font-semibold text-h5 mt-4">
                            description
                          </p>
                          <p className="text-secondary-dark text-p1 my-2 lg:my-1 lg:text-h5 tracking-[0.01em] leading-6 text-left">
                            {bookDetails?.description}
                          </p>
                        </>
                      )}
                    </div>
                  </div>
                  <QrCodeComp
                    qrDetails={qrDetails}
                    data={bookDetails}
                    isSet={isSet}
                  />
                </div>

                <div className=" w-full md:w-[300px]">
                  <BookExpirationInfo book={bookDetails} />
                </div>
              </div>
            </div>
          )
        )}
      </div>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        closeOnClick
        pauseOnFocusLoss
        pauseOnHover
      />
    </Page>
  );
};

export default ValidateQr;
