export default function GeneralLoader({ dark, radius, width }) {
  return (
    <div className="absolute h-full w-full top-0 left-0 flex justify-center items-center" style={{backgroundColor: dark ? "rgba(255, 255, 255, 0.8)" : "rgba(112, 41, 99, 0.8)"}} >
      <span
        className={`border-solid animate-spin rounded-full`}
        style={{width: radius+"px", height: radius+"px", borderLeftWidth: width+"px", borderColor: dark ? "#702963" : "#fff"}}
      ></span>
    </div>
  );
}
