import { useState, useEffect } from "react";
import { Page, Navbar, BtnBlock, DialogBox, BtnSmall } from "../components";
import { Link as RouterLink } from "react-router-dom";
import * as ROUTES from "../constant/routes";

export default function Home() {
  const [modals, setModals] = useState({
    expandedMenu: true,
  });

  return (
    <Page active="wallet">
      <Navbar />
      <div>
      <h1 className="text-heading text-h1 font-semibold text-left my-8">
        Home
      </h1>
      
      </div>

    </Page>
  );
}
