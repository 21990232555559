// const url = "https://stage-api.eprakashak.in/api/v1/";

const url = process.env.REACT_APP_API_URL;

export const LOG_IN = `${url}all/users/login`; // done
export const LOG_OUT = `${url}all/users/logout/`;
export const GET_ALL_BOOKS_BY_PUBLISHER = `${url}publisher/books`; // done

export const FORGOT_PASSWORD = `${url}all/users/forgotPassword`; // done
export const RESET_PASSWORD = `${url}all/users/resetPassword`; // done
export const GET_PROFILE = `${url}all/users/myProfile`; // partially
export const WALLET = `${url}publisher/wallet`; // done
export const GRAPH_DATA = `${url}publisher/transactionHistory/totalCharges`;
export const SIGN_UP = `${url}all/users/signup`; // done
export const GET_MY_PUBLICATION = `${url}publisher/publications/my`; // done
export const GET_ALL_PUBLISHERS = `${url}publisher/publishers`; // done
export const UPDATE_PERSONAL_PROFILE = `${url}all/users/updateMe`; // done
export const INDIVIDUAL_USERS = `${url}publisher/user/customers`; // done
export const ORGANISATIONS = `${url}publisher/user/organisations`; // done
export const THIRD_PARTIES = `${url}publisher/user/thirdParties`; // done
export const CREATE_INDIVIUAL_USER = `${url}desktop/customers/`;
export const CREATE_ORGANIZATION = `${url}organisation/organisations`; // done
export const CREATE_THIRD_PARTY_SELLER = `${url}thirdParty/thirdParties`; // done
export const ADD_NEW_PUBLISHER = `${url}publisher/publishers`; // done
export const DELETE_A_PUBLISHER = `${url}publisher/publishers/`; // done
export const MAKE_PRIMARY = `${url}publisher/publishers/transferPcp/`; // done
// catalogue
export const GET_ALL_EXPIRED_BOOKS = `${url}publisher/books/expiry`; // partially
export const GET_BOOK_DETAILS_BY_ID = `${url}publisher/books/`; // done
export const GENERATE_UPDATE_ACCESS_CODES = `${url}api/v1/publisher/accessCode`;
// export const EXISTING_ACCESS_CODES_BY_BOOK_IDS = `${url}api/v1/publisher/accessCode/getAccessCodes`;
export const EXISTING_ACCESS_CODES_BY_BOOK_ID = `${url}publisher/books/`;
export const GET_AVAILABLE_ACCESS_CODE_BY_BOOK_ID = `${url}api/v1/publisher/books/`;
// cart accessIds // orderIds

export const GET_ACCESS_CODE_CHARGES = `${url}publisher/cart/charges`; // done
export const ADD_BOOKS_IN_CART = `${url}publisher/cart/`; // done
export const GET_ALL_BOOKS_IN_CART = `${url}publisher/cart/`; // done
export const REMOVE_ALL_BOOKS_IN_CART = `${url}publisher/cart/`; // done
export const REMOVE_A_BOOK_IN_CART = `${url}publisher/cart/`; // done
export const REMOVE_VALIDITY_IN_CART = `${url}publisher/cart/`; // done
export const UPDATE_QUANTITY = `${url}publisher/cart/`; // done
export const UPDATE_VALIDITY = `${url}publisher/cart/`
export const CONVERT_TO_QR = `${url}publisher/cart/convert-to-qr`
export const PAY_FOR_ACCESS_IDS = `${url}publisher/cart/accessId/confirmToPay`; // done

export const PAY_FOR_ORDER_IDS = `${url}publisher/cart/orderId/confirmToPay`;
export const CONFIRM_OTP = `${url}publisher/cart/`;

/// cart for sets    accessid and orderides

export const GET_ALL_BOOKS_IN_CART_FOR_SETS = `${url}publisher/setCart/`; // done
export const ADD_BOOKS_IN_CART_FOR_SETS = `${url}publisher/setCart/`; // done
export const REMOVE_ALL_BOOKS_IN_CART_FOR_SETS = `${url}publisher/setCart/`; // done
export const REMOVE_A_BOOK_IN_CART_FOR_SETS = `${url}publisher/setCart/`; // done
export const UPDATE_QUANTITY_FOR_SETS = `${url}publisher/setCart/`; // done
export const CONFIRM_OTP_FOR_SETS = `${url}publisher/setCart/`;
export const UPDATE_VALIDITY__FOR_SET = `${url}publisher/setCart/`
export const REMOVE_VALIDITY_IN_SET_CART = `${url}publisher/setCart/`; // done
export const SET_CONVERT_TO_QR = `${url}publisher/setCart/convert-to-qr`
// accessids

export const PAY_FOR_ACCESS_IDS_IN_SETS = `${url}publisher/setCart/accessId/confirmToPay`; // done
// export const CONFIRM_OTP_FOR_ACCESSIDS = `${url}publisher/setCart/accessId/verify`;

// ORDERID
export const PAY_FOR_ORDER_IDS_IN_SETS = `${url}publisher/setCart/orderId/confirmToPay`;

// get order history

export const ORDER_HISTORY = `${url}publisher/user/organisations/orderHistory?`; // partially
export const SET_ORDER_HISTORY = `${url}publisher/user/organisations/orderHistory/set?`; // partially

// add a book stepper

export const STEP_0_ADD_BOOK = `${url}publisher/books/getS3Url`; // done
export const STEP_1_ADD_BOOK = `${url}publisher/books/upload`; // done
export const STEP_2_ADD_BOOK = `${url}publisher/books/charges`; // done
export const STEP_3_ADD_BOOK = `${url}publisher/books/verify`; // done
export const STEP_4_ADD_BOOK = `${url}publisher/books/`; // done
export const STEP_5_ADD_BOOK = `${url}publisher/books/null`;
export const GET_ADENDUM_PAGES = `${url}publisher/books/getAdendumPages/`;

export const DELETE_DRAFT_BOOK = `${url}publisher/books/`;
// add a addendums

export const STEP_4_ADD_ADDENDUMS = `${url}publisher/books/`;

// other stepper apis
export const ADD_CATEGORY_ADD_BOOK = `${url}publisher/books/category`; // done
export const ADD_AUTHOR_ADD_BOOK = `${url}publisher/books/author`; // done
export const ADD_PUBLICATION_ADD_BOOK = `${url}publisher/books/publication`; // done
export const SEARCH_CATEGORY_ADD_BOOK = `${url}publisher/books/category?category=`; // done
export const SEARCH_PUBLICATION_ADD_BOOK = `${url}publisher/books/publication?publication=`; // done
export const SEARCH_AUTHOR_ADD_BOOK = `${url}publisher/books/author?author=`; // done
export const UPDATE_PUBLICATION_PICTURE = `${url}all/portals`; // done



export const GET_ALL_ONGOING_BOOK_CONVERSION = `${url}publisher/books/upload`;
// delete Profile picture
export const DELETE_PROFILE_PICTURE = `${url}all/users/removePhoto`; //done
export const DELETE_BUSINESS_PROFILE = `${url}all/portals/remove`; // done

export const TRANSACTION_HISTORY = `${url}publisher/transactionHistory`;
export const TRANSACTION_HISTORY_filter = `${url}publisher/transactionHistory`; // partially
export const ADD_CREDITS = `${url}publisher/wallet/orders`; // done
export const SEARCH_ORDERIDBY_NAME = `${url}publisher/cart/search`;

// settings

export const UPDATE_PASSWORD = `${url}all/users/updateMyPassword`;

export const GET_ALL_TICKET = `${url}all/tickets/`;
export const SEARCH_FOR_TICKET = `${url}all/tickets/search?portal=`;
export const CREATE_NEW_TICKET = `${url}all/tickets/`;
export const Add_COMMENTS = `${url}all/tickets/`;
export const GET_ALL_COMMENTS = `${url}all/tickets/`;
export const UPDATE_STATUS = `${url}all/tickets/`;

// notification

export const GET_ALL_NOTIFICATION = `${url}publisher/notifications`;
export const UPDATE_NOTIFICATION = `${url}publisher/notifications/`;

// sets

export const GET_ALL_SETS = `${url}publisher/sets/`; //
export const ADD_BOOKS_IN_SET = `${url}publisher/sets/books`;
export const GET_SET_DETAILS = `${url}publisher/sets/`;
export const GET_ALL_BOOKS_IN_SET = `${url}publisher/sets/status`;
export const GET_BOOK_FOR_SELECT = `${url}publisher/sets/books`;
export const SEND_OTP = `${url}publisher/sets/sendOtp`;
export const VERIFY_OTP_SETS = `${url}publisher/sets/verifyOtp`;
export const GET_SET_METADATA = `${url}publisher/sets/metaData`;
export const ACTIVATE_SET = `${url}publisher/sets/`;
export const UPLOAD_SET_COVER = `${url}publisher/sets/cover`;

// search users

export const SEARCH_INDIVIDUAL_USER = `${url}publisher/user/customers/search?text=`;
export const SEARCH_ORGANIZATION_USER = `${url}publisher/user/organisations/search?text=`;
export const SEARCH_THIRDPARTY_USER = `${url}publisher/user/thirdParties/search?text=`;


// add users

export const ADD_INDIVIDUAL_USER = `${url}publisher/user/customers`;
export const ADD_ORGANIZATION_USER = `${url}publisher/user/organisations`;
export const ADD_THIRDPARTY_USER = `${url}publisher/user/thirdParties`;

// Search book by title and book id 

export const SEARCH_BOOK_BY_TITLE = `${url}publisher/books/search/?keyword=`;
export const SEARCH_BOOK_BY_BOOKID = `${url}publisher/books/searchByOrderId/?orderId=`;
export const VALIDATE_QR = `${url}publisher/books/validateQr/`;
export const QR_BY_BATCH_ID = `${url}publisher/books/getQrCodes/`;
export const PRINT_QR_BY_BATCH_ID = `${url}publisher/books/qrCode/print`;

export const SET_QR_BY_BATCH_ID = `${url}publisher/sets/getQrCodes/`;
export const SET_PRINT_QR_BY_BATCH_ID = `${url}publisher/sets/qrCode/print`;

export const PRINT_SINGLE_QR = `${url}publisher/books/singleQr/print`;


export const GET_S3_SESSION_TOKEN = `${url}all/configs/getS3SessionToken`;