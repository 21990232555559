
export const HOME = "/HK";
export const LOG_IN = "/login";
export const SIGN_UP = "/signup";
export const FORGOT_PASSWORD = "/forgot-password";
export const RESET_PASSWORD = "/reset-password/:id";
export const PROFILE = "/profile";
export const BUSINESS_PROFILE = "/business-profile";
export const SEARCH_BOOK = "/searchBook";
export const CART = "/cart";
export const NOTIFICATION = "/notification";
export const WALLET = "/wallet";
export const CATALOGUE = "/";
export const GENERATE_ACCESS_IDS = "/cart/acessIds";
export const GENERATE_ORDER_IDS = "/cart/orderIds";
export const GENERATE_ACCESS_IDS_FOR_SETS = "/cart/acessIdsforSets";
export const GENERATE_ORDER_IDS_FOR_SETS = "/cart/orderIdsforSets";
export const BOOK_RENEWAL = "/cart/bookRenew";
export const EXPIRING_SOON = "/books/expiring-soon";
export const BOOK_DETAILS = "/books/:bookId";
export const ADD_NEW_BOOK = "/add-new-book";
export const SETTINGS = "/settings";
export const USERS = "/users";
export const INDIVIDUAL_USERS = "/users/individual-users";
export const ORGANISATIONS = "/users/organisations";
export const THIRD_PARTY_SELLERS = "/users/third-party-sellers";
export const ORDER_HISTORY = "/users/individual-users/:order_id";
export const ORDER_DETAILS = "/users/individual-users/:order_id/:order_details";
export const ORG_ORDER_HISTORY = "/users/organisations/:order_id";
export const ORG_ORDER_DETAILS =
  "/users/organisations/:order_id/:order_details";
export const ORGANIZATION_DETAILS =
  "/users/organisations/:order_id/organization-details";
export const TPS_ORDER_HISTORY = "/users/third-party-sellers/:order_id";
export const TPS_ORDER_DETAILS =
  "/users/third-party-sellers/:order_id/:order_details";
export const GRIEVANCES = "/grievances";
export const SETS = "/sets";
export const SET_DETAILS = "/sets/:bookId";
export const VALIDATE_QR = "/validate_qr";