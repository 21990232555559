import { useState, useEffect } from "react";
import {
  BtnSmall,
  BtnSmallDisabled,
  BtnSmallWhite,
  DialogBox,
  Loader,
  PopUp,
} from "../../components";
import { useAuth } from "../../hooks";
import { useForm } from "react-hook-form";
import { handleUpdatePassword } from "../../assets/Js/script";
function ResetPassword() {

  const [isLoaderActive, setIsLoaderActive] = useState(false);
  const [modals, setModals] = useState({
    passwordChange: false,
  });
  const {
    register,
    watch,
    handleSubmit,
    getValues,
    reset,
    formState: { errors, isValid },
  } = useForm({ mode: "all" });

  const { signout } = useAuth();
  function handleSignout() {
    signout();
    reset();
  }

  async function handleResetPassword(data) {
    setIsLoaderActive(true);
    const { response, errors } = await handleUpdatePassword(data);
    if (response.status === "success") {
      setIsLoaderActive(false);
      setModals((prev) => ({
        ...prev,
        passwordChange: true,
      }));
     
      reset();

    }
  }
  return (
    <div className="py-5 pt-7">
      <form
        onSubmit={handleSubmit(handleResetPassword)}
        className="flex flex-col"
      >
        <label
          htmlFor="curr_password"
          className="text-[18px]  font-[600] text-[#585858] "
        >
          Current Password
        </label>
        <input
          type={"password"}
          id="curr_password"
          name="current_password"
          className="w-[412px] text-[18px] h-[64px] p-3 mb-5  border-[1px] border-solid border-[#eee] focus:border-[#eee] focus:outline-none "
          {...register("current_password", {
            required: true,
            minLength: 8,
            maxLength: 16,
          })}
        />
        <p className="mb-4 md:mb-6 mt-[-16px] md:mt-[-24px] text-[13px] text-[red]">
          {errors.current_password && "Please enter Valid Mobile Number !"}
        </p>
        <label
          htmlFor="new_password"
          className="text-[18px]  font-[600] text-[#585858] "
        >
          New Password
        </label>
        <input
          type={"password"}
          id="new_password"
          name="new_password"
          className="w-[412px] text-[18px] h-[64px] p-3 mb-5  border-[1px] border-solid border-[#eee] focus:border-[#eee] focus:outline-none "
          {...register("new_password", {
            required: true,
            minLength: 8,
            maxLength: 16,
          })}
        />
        <p className="mb-4 md:mb-6 mt-[-16px] md:mt-[-24px] text-[13px] text-[red]">
          {errors.new_password && "Password should have more than 8 character"}
        </p>
        <label
          htmlFor="confirm_password"
          className="text-[18px]  font-[600] text-[#585858] "
        >
          Confirm Password
        </label>
        <input
          type={"password"}
          id="confirm_password"
          name="confirm_password"
          className="w-[412px] text-[18px] h-[64px] p-3 mb-5  border-[1px] border-solid border-[#eee] focus:border-[#eee] focus:outline-none "
          {...register("confirm_password", {
            required: true,
            minLength: 8,
            maxLength: 16,
            validate: (value) => value === getValues("new_password"),
          })}
        />
        <p className="mb-4 md:mb-6 mt-[-16px] md:mt-[-24px] text-[13px] text-[red]">
          {errors.confirm_password &&
            "Confirm Password is not equal to passsword !"}
        </p>

        <p className="font-[600] tracking-wide text-[18px] text-[#b6b6b6] mb-10">
          Click on Change button to complete the process.
        </p>
        <div className="flex gap-2">
          {isValid ? (
            <BtnSmall>Change</BtnSmall>
          ) : (
            <BtnSmallDisabled>Change</BtnSmallDisabled>
          )}
          <BtnSmallWhite>Cancel</BtnSmallWhite>
        </div>
      </form>
      <Loader show={isLoaderActive} />
      <DialogBox show={modals.passwordChange}>
        <PopUp
          onClick={() => {
            setModals((prevState) => ({
              ...prevState,
              passwordChange: false,
            }));
            // handleSignout();
          }}
          onClose={() => {
            setModals((prevState) => ({
              ...prevState,
              passwordChange: false,
            }));
          }}
          button="Close"
          title="Password Reset Successfull!"
          body="Your password was reset successfully. Please login using your new password"
        />
      </DialogBox>
    </div>
  );
}

export default ResetPassword;
