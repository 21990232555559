import { collection, onSnapshot, query, where } from "firebase/firestore";
import { useState, useEffect, useRef } from "react";
import { AuthContext } from "../context/auth";
import { db } from "../utils/Firebase";
import audio from "../assets/audio/ding.mp3";

export default function AuthProvider({ children }) {
  const [auth, setAuth] = useState(JSON.parse(localStorage.getItem("auth")));
  const [user, setUser] = useState(null);
  const [search, setSearch] = useState('');
  const [notifySound, setNotifySound] = useState(false);
  const audioRef = useRef();
  useEffect(() => {

    if (auth) {
      setUser(JSON.parse(localStorage.getItem("user")));
    } else {
      setUser(null);
      localStorage.removeItem('user');
    }
  }, [auth]);

  useEffect(() => {

    if (user) {
      const q = query(collection(db,process.env.REACT_APP_NOTIF_COLLECTION), where("userId", "==", user?.id));
      const unsubscribe = onSnapshot(q, (querySnapshot) => {

        querySnapshot.docChanges().forEach((change) => {
          if (change.type === "added") {

           try {
            // audioRef?.current?.play();
           } catch (error) {
            
           }
          }

        });






        return unsubscribe;
      });
    }
  }, [user])


  const value = { user, auth, setAuth, setUser, search, setSearch, notifySound, setNotifySound };

  return <AuthContext.Provider value={value}>
    {children}
    <audio className="w-0" src={audio} ref={audioRef} ></audio>
  </AuthContext.Provider>;
}
