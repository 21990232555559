import React from 'react'
import * as ROUTES from "../constant/routes";
import { useNavigate, } from "react-router-dom";
import { useState } from 'react';
const OrderHistoryHeader = ({ onClick, right }) => {
    const navigate = useNavigate()
    const [tabSelected, setTabSelected] = useState('Individual User')
    // const onTabClick=(tab)=>{

    // }
    return (

        <div className="hidden lg:flex mb-12 items-center justify-between">
            <div className="w-[390px] mt-6 flex pb-4 border-b-1 border-solid border-[#c5c1c4]">
                <button
                    onClick={() => { onClick ? onClick('Individual User') : navigate(ROUTES.USERS); setTabSelected('Individual User') }}
                    className={`text-secondary-dark transition-[border] duration-100 tracking-wide mr-[60px] outline-0 border-0 text-montserrat font-semibold text-[18px] px-2 ${tabSelected === 'Individual User' &&
                        "text-tertiary-dark border-b-[1.1px] border-b-solid border-b-primary-dark"
                        }`}
                >
                    Individual User
                </button>
                <button
                    onClick={() => { onClick ? onClick('Organisations') : navigate(ROUTES.USERS); setTabSelected('Organisations') }}
                    className={`text-secondary-dark transition-[border] duration-100 tracking-wide mr-[60px] outline-0 border-0 text-montserrat font-semibold text-[18px] px-2 ${tabSelected === 'Organisations' &&
                        "text-tertiary-dark border-b-[1.1px] border-b-solid border-b-primary-dark"
                        }`}
                >
                    Organisations
                </button>

                {/* <button
            onClick={() => navigate(ROUTES.USERS)}
            className={`text-secondary-dark transition-[border] duration-100 tracking-wide outline-0 border-0 text-montserrat font-semibold text-[18px] px-2
         `}
          >
            Third Party Seller
          </button> */}
            </div>
            {right}
            {/* <button
        onClick={() =>
          setModals((prevState) => ({ ...prevState, addNew: true }))
        }
        className="font-semibold text-h5 text-tertiary-dark underline"
      >
        Add a new user
      </button> */}
        </div>
    )
}

export default OrderHistoryHeader
