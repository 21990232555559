import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Loader,
  Navbar,
  Page,
  BtnSmall,
  DialogBox,
  PopUp
} from "../components";
import { ToastContainer, toast } from "react-toastify";
import {
  addBooksInCart,
  addBooksInCartFromsets,
  getAdendumTotalPages,
  getBookDetailsById,
  getSetDetailsById
} from "../assets/Js/script";
import TopBar from "../components/TopBar";
import Book from "../assets/images/book.png";
import * as ROUTES from "../constant/routes";
import CovertIco from "../assets/images/convert.png";
import CovertIcoGrey from "../assets/images/convert_grey.png";

import PrintIco from "../assets/images/print.png";
import ConvertToQrModal from "./components/ConvertToQrModal";
import { ddmmyyyyhhmm } from "../helpers/ddmmyyyyhhmm";
import PrintQrConfigModal from "./components/PrintQrConfigModal";
import ReactTooltip from "react-tooltip";

export default function Catalogue() {
  const navigate = useNavigate();
  const params = useParams();

  const [loading, setLoading] = useState(false);
  const [set, setSet] = useState({});
  const [selectedTab, setSelectedTab] = useState("Set Details");
  const [modals, setModals] = useState({});
  const [accessCodes, setAccessCodes] = useState();
  const [totalpage, setTotalPage] = useState("");
  const [selectedData, setSelectedData] = useState();
  const [qrCodeDetails, setQrCodeDetails] = useState([]);
  const getSetDetails = async () => {
    setLoading(true);
    const { response, error } = await getSetDetailsById(params.bookId);
    if (response.status === "success") {
      setTotalPage(response.data.totalPage);
      setSet(response.data.set);
      setAccessCodes(response.data.accessCodes);
      setQrCodeDetails(response.data.qrCodeDetails);
      setLoading(false);
    } else {
      setLoading(false);
      toast(response?.message ?? "Something went wrong");
    }
  };
  function calculateExpiry(expireDate, createdDate) {
    const date1 = Date.parse(expireDate);

    const date2 = new Date();

    const difference = date1 - date2;

    const day = Math.round(difference / (3600000 * 24));

    const percent = day / (set.validity * 365);
    console.log(day,set.validity, percent, "ppp");
    return { day, percent: percent * 100 };
  }
  async function handleGenerate() {
    const { response, error } = await addBooksInCartFromsets(
      [{ id: params.bookId, validity: set.validity }],
      true
    );
    if (response.status === "success") {
      navigate(ROUTES.GENERATE_ACCESS_IDS_FOR_SETS);
    } else {
      toast(response?.message ? response?.message : "Something went wrong");
    }
  }
  const onCompletePrint = () => {
    let tmpQrCodeDetails = [...qrCodeDetails];
    tmpQrCodeDetails.forEach((a, id) => {
      if (a.BatchId == selectedData.BatchId) {
        tmpQrCodeDetails[id].IsPrint = true;
      }
    });
    setQrCodeDetails(tmpQrCodeDetails);
  };

  const convertToQr = (validity) => {};
  const SetDetails = () => {
    return (
      <div className="mt-5 flex  ">
        <div className="flex lg:block">
          <div className="overflow-hidden h-[309px]">
            <img
              className="h-full object-cover w-full"
              src={`https://${process.env.REACT_APP_AWS_BUCKET_NAME}.s3.amazonaws.com/setCovers/${set?.cover}`}
              alt="book front cover"
            />
          </div>
          
        </div>
        <div className="w-3/4 ml-2">
          <h1 className="flex items-center text-mobile-h1  lg:text-h1 text-tertiary-dark lg:text-heading text-left font-bold lg:font-semibold">
            {set?.title}
          </h1>
          {!(set?.description == null) && (
            <>
              <p className="text-tertiary-dark capitalize lg:uppercase tracking-wide font-bold lg:font-semibold text-h5 mt-4">
                description
              </p>
              <p className="text-secondary-dark text-p1 my-2 lg:my-1 lg:text-h5 tracking-[0.01em] leading-6 text-left">
                {set?.description}
              </p>
            </>
          )}
        </div>
      </div>
    );
  };

  const Books = () => {
    return (
      <div>
        <h2 className="hidden lg:block uppercase lg:text-laptop-h3 xl:text-h4 text-tertiary-dark font-semibold text-left pt-4 mb-4">
          LIST OF BOOKS IN THE SET
        </h2>
        {set?.Books?.length > 0 && (
          <div className="flex flex-wrap gap-3">
            {set.Books.map((data, index) => (
              <div
                key={data.parentId}
                className="flex w-[266px] h-[134px] justify-center items-center bg-primary-extra-light mr-6 p-2"
              >
                <div
                  style={{ background: data.backgroundColor }}
                  className="w-[106px] h-full mr-[11px] bg-[#A93030] flex justify-center items-center py-4"
                >
                  <img
                    className="shadow-[5px_5px_13px_3px_rgba(0,0,0,0.5)] h-[95px] w-[75%] object-cover"
                    src={`https://${process.env.REACT_APP_AWS_BUCKET_NAME}.s3.amazonaws.com/books/${data.imageCoverFront}`}
                    alt="set"
                  />
                </div>
                <div className="w-[160px] font-semibold">
                  <h2 className="text-[303030] text-mobile-h5">{data.title}</h2>
                  <h3 className="text-[#5F5D5D8F] text-mobile-p1">
                    {data.Bauthors?.map((auther, index, arr) => (
                      <span>
                        {arr.indexOf(arr[index]) === arr.length - 1
                          ? auther.bookAuthorName
                          : `${auther.bookAuthorName}, `}
                      </span>
                    ))}{" "}
                  </h3>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };

  const AccessCodeDetails = () => {
    return (
      <div>
        <h3 className="capitalize lg:uppercase lg:text-laptop-h3 xl:text-h5 text-tertiary-dark text-left my-4 text-[18px] font-bold lg:font-semibold">
          Access Id
        </h3>
        {/* <p className="text-h5 text-secondary-dark tracking-wide text-left mb-4">
        A. Access Id Validity can be less than or equal to the Book Validity <br />
        B. One Access Id provides Access of this Book for the Mentioned Access Code Validity to One User
      </p> */}

        <div class="relative overflow-x-auto">
          <table class="w-full border border-[#AEAEAE] border-collapse text-s text-[#6A6A6A] text-center align-middle">
            <thead class="bg-neutral-10 text-secondary-dark ">
              <tr>
                <th
                  scope="col"
                  rowSpan="2"
                  class="px-6 py-1  border border-[#AEAEAE]"
                >
                  Quantity
                </th>
                <th
                  scope="col"
                  rowSpan="2"
                  class="px-6 py-1  border border-[#AEAEAE]"
                >
                  Validity (in years)
                </th>
                <th
                  scope="col"
                  colSpan="2"
                  class="px-6 py-1  border border-[#AEAEAE]"
                >
                  Total Access Id’s
                </th>
                <th
                  scope="col"
                  colSpan="2"
                  class="px-6 py-1  border border-[#AEAEAE]"
                >
                  Total Qr-Code Id’s
                </th>
                <th
                  scope="col"
                  rowSpan="2"
                  class="px-6 py-1  border border-[#AEAEAE]"
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              <tr class="bg-white border border-[#AEAEAE] ">
                <td
                  align="center"
                  class="px-6 py-1  border border-[#AEAEAE]"
                ></td>
                <td
                  align="center"
                  class="px-6 py-1  border border-[#AEAEAE]"
                ></td>
                <td align="center" class="px-6 py-1  border border-[#AEAEAE]">
                  Unused
                </td>
                <td align="center" class="px-6 py-1  border border-[#AEAEAE]">
                  Used
                </td>
                <td align="center" class="px-6 py-1  border border-[#AEAEAE]">
                  Unused
                </td>
                <td align="center" class="px-6 py-1  border border-[#AEAEAE]">
                  Used
                </td>
                <td
                  align="center"
                  class="px-6 py-1  border border-[#AEAEAE]"
                ></td>
              </tr>
              {console.log(accessCodes, "accessCodes")}
              {Object.keys(accessCodes ?? {}).map((validity) => {
                const total =
                  Number(accessCodes[validity].unUsedAccessIds) +
                  Number(accessCodes[validity].usedAccessIds) +
                  Number(accessCodes[validity].unUsedQrCodes) +
                  Number(accessCodes[validity].usedQrCodes);
                return (
                  <tr tr class="bg-white border border-[#AEAEAE] ">
                    <td class="px-6 py-1  border border-[#AEAEAE]">{total}</td>
                    <td class="px-6 py-1  border border-[#AEAEAE]">
                      {validity}
                    </td>
                    <td class="px-6 py-1  border border-[#AEAEAE]">
                      {accessCodes[validity].unUsedAccessIds}
                    </td>
                    <td class="px-6 py-1  border border-[#AEAEAE]">
                      {accessCodes[validity].usedAccessIds}
                    </td>
                    <td class="px-6 py-1  border border-[#AEAEAE]">
                      {accessCodes[validity].unUsedQrCodes}
                    </td>
                    <td class="px-6 py-1  border border-[#AEAEAE]">
                      {accessCodes[validity].usedQrCodes}
                    </td>
                    <td class="px-6 py-1  border border-[#AEAEAE]">
                      <div className="flex items-center justify-center">
                        <ReactTooltip
                          place="right"
                          backgroundColor="#737272"
                          type="success"
                          effect="solid"
                          offset={{ bottom: 5 }}
                          delayShow="200"
                        />
                        <img
                          src={
                            accessCodes[validity].unUsedAccessIds > 0
                              ? CovertIco
                              : CovertIcoGrey
                          }
                          className="h-4 w-4 object-contain cursor-pointer"
                          onClick={() => {
                            if (accessCodes[validity].unUsedAccessIds > 0) {
                              setModals((prev) => ({ ...prev, convert: true }));
                              setSelectedData({
                                validity,
                                unusedAids:
                                  accessCodes[validity].unUsedAccessIds
                              });
                            }
                          }}
                          data-tip="Convert AID to QRID"
                        />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="hidden mt-3 lg:block">
          <button
            onClick={handleGenerate}
            className=" w-fit px-3  h-[54px] bg-primary-dark border-[0.5px] border-solid border-[#cacaca] text-primary-white text-h5 font-bold"
          >
            Generate New Access Ids
          </button>
        </div>
        <div className="flex mt-3 mb-16 lg:hidden items-center">
          <button
            onClick={handleGenerate}
            className=" w-fit h-[54px] py-3 bg-primary-dark border-[0.5px] border-solid border-[#cacaca] text-primary-white text-h5 font-bold"
          >
            Generate New Access Id’s
          </button>
        </div>
      </div>
    );
  };
  const QrCodeBatches = () => {
    return (
      <div>
        <h3 className="capitalize lg:uppercase lg:text-laptop-h3 xl:text-h5 text-tertiary-dark text-left my-4 text-[18px] font-bold lg:font-semibold">
          Qr Code Batches
        </h3>
        <p className="text-h5 text-secondary-dark tracking-wide text-left mb-4">
          A. Access Id Validity can be less than or equal to the Book Validity{" "}
          <br />
          B. One Access Id provides Access of this Book for the Mentioned Access
          Code Validity to One User
        </p>

        <div class="relative overflow-x-auto">
          <table class="w-full border border-[#AEAEAE] border-collapse text-s text-[#6A6A6A] text-center align-middle">
            <thead class="bg-neutral-10 text-secondary-dark ">
              <tr>
                <th scope="col" class="px-6 py-1  border border-[#AEAEAE]">
                  Batch Id
                </th>
                <th scope="col" class="px-6 py-1  border border-[#AEAEAE]">
                  Created At
                </th>
                <th scope="col" class="px-6 py-1  border border-[#AEAEAE]">
                  Validity
                </th>
                <th scope="col" class="px-6 py-1  border border-[#AEAEAE]">
                  Total Qty
                </th>
                <th scope="col" class="px-6 py-1  border border-[#AEAEAE]">
                  Unused Qty
                </th>
                <th scope="col" class="px-6 py-1  border border-[#AEAEAE]">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {qrCodeDetails.map((qrCode, idx) => {
                return (
                  <tr tr class="bg-white border border-[#AEAEAE] ">
                    <td class="px-6 py-1  border border-[#AEAEAE]">
                      {qrCode.BatchId}
                    </td>
                    <td class="px-6 py-1  border border-[#AEAEAE]">
                      {ddmmyyyyhhmm(qrCode.createdAt)}
                    </td>

                    <td class="px-6 py-1  border border-[#AEAEAE]">
                      {qrCode.validity}
                    </td>
                    <td class="px-6 py-1  border border-[#AEAEAE]">
                      {qrCode.totalCount}
                    </td>
                    <td class="px-6 py-1  border border-[#AEAEAE]">
                      {qrCode.unUsedCount}
                    </td>
                    <td class="px-6 py-1  border border-[#AEAEAE]">
                      <div className="flex items-center justify-center">
                        <ReactTooltip
                          place="right"
                          backgroundColor="#737272"
                          type="success"
                          effect="solid"
                          offset={{ bottom: 5 }}
                          delayShow="200"
                        />
                        <img
                          src={PrintIco}
                          className="h-6 w-6 object-contain cursor-pointer"
                          data-tip="Print QRID"
                          alt="print-ico"
                          onClick={() => {
                            setSelectedData(qrCode);
                            setModals((prev) => ({ ...prev, printQr: true }));
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          {qrCodeDetails.length == 0 && (
            <p className="mt-2 text-center text-secondary-dark">
              No QR Codes/ Batches available.
            </p>
          )}
        </div>
      </div>
    );
  };
  const ExpirationInfo = () => {
    return (
      <div className=" w-[30%] min-w-[305px]">
        <div className="hidden lg:block p-2 border-1 border-solid border-primary-dark-20 w-full h-fit -48 mb-8">
          <div className=" px-4">
            <h2 className="text-heading font-bold text-h4 text-left mb-2">
              Expiration Date
            </h2>
            <div className="flex justify-between items-center mb-2">
              <p className="text-secondary-light text-semibold text-h5 mb-2">
                {set?.title}
              </p>
              <p className="text-secondary-dark text-semibold text-h5">
                {
                  calculateExpiry(set.expiresOn, set.createdAt, set.validity)
                    .day
                }{" "}
                days
              </p>
            </div>
            <div className=" h-[18px] bg-primary-light mb-6 rounded-full">
              <div
                className="h-full bg-primary-dark rounded"
                style={{
                  rounded: "50px",
                  width:
                    calculateExpiry(set.expiresOn, set.createdAt, set.validity)
                      .percent + "%"
                }}
              ></div>
            </div>
          </div>
          {/* {expired ? (""
            // <button className="text-heading font-semibold text-p1 h-[41px] w-full border-1 border-solid border-primary-dark-20">
            //   Reactivate
            // </button>
          ) : (
            <button className="text-heading font-semibold text-p1 h-[41px] w-full border-1 border-solid border-primary-dark-20">
              Renew
            </button>
          )} */}
        </div>

        <div className="block my-10  p-4 border-1 border-solid border-primary-dark-20 lg:w-full ">
          <h2 classNaame="text-heading font-bold text-h4 text-left mb-2">
            Details
          </h2>
          <div className="my-4 mb-0 flex justify-between">
            <div className="w-1/2">
              <div className="mb-4">
                <h3 className="text-h5 text-secondary-dark font-semibold">
                  No. of Books
                </h3>
                <p className="text-h4 text-tertiary-dark font-semibold">
                  {set?.Books?.length}
                </p>
              </div>
              <div className="mb-4">
                <h3 className="text-h5 text-secondary-dark font-semibold">
                  Page Count
                </h3>
                <p className="text-h4 text-tertiary-dark font-semibold">
                  {totalpage} Pages
                </p>
              </div>
            </div>

            <div className="w-1/2">
              <div className="mb-4">
                <h3 className="text-h5 text-secondary-dark font-semibold">
                  Author
                </h3>
                {set?.Bauthors?.map((elm, index) => (
                  <p
                    key={index}
                    className="text-h4 text-tertiary-dark font-semibold"
                  >
                    {elm?.bookAuthorName}
                  </p>
                ))}
              </div>

              <div className="mb-4">
                <h3 className="text-h5 text-secondary-dark font-semibold">
                  Publishers
                </h3>
                {set?.Bpublications?.map((elm, index) => (
                  <p className="text-h4 text-tertiary-dark font-semibold">
                    {elm?.bookPublicationName}
                  </p>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  useEffect(() => {
    getSetDetails();
  }, []);
  return (
    <Page active="wallet">
      <Navbar />
      {loading ? (
        <Loader show={true} />
      ) : (
        <div>
          <h1 className="flex items-center text-mobile-h1  lg:text-h1 text-tertiary-dark lg:text-heading text-left font-bold lg:font-semibold">
            <span onClick={() => navigate(-1)} className=" mr-3">
              <svg
                width="12"
                height="20"
                viewBox="0 0 12 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.0491 2.5473L9.4174 0.924805L0.351562 9.9998L9.42656 19.0748L11.0491 17.4523L3.59656 9.9998L11.0491 2.5473Z"
                  fill="#702963"
                />
              </svg>
            </span>
            {set?.title}
          </h1>
          <div className="m-3 mt-7">
            <TopBar
              tabs={["Set Details", "Books", "Access IDs", "QR Batches"]}
              selectedTab={selectedTab}
              onChange={(t) => setSelectedTab(t)}
            />
            <div className="flex items-start">
              <div className="flex-1 mr-2 ">
                {selectedTab === "Set Details" && <SetDetails />}
                {selectedTab === "Books" && <Books />}
                {selectedTab === "Access IDs" && <AccessCodeDetails />}
                {selectedTab === "QR Batches" && <QrCodeBatches />}
              </div>

              {selectedTab !== "Access IDs" && selectedTab !== "QR Batches" && (
                <ExpirationInfo />
              )}
            </div>
          </div>
          <ConvertToQrModal
            modals={modals}
            setModals={setModals}
            selectedData={selectedData}
            data={set}
          />
          <PrintQrConfigModal
            show={modals?.printQr}
            selectedData={selectedData}
            onClose={() => setModals((prev) => ({ ...prev, printQr: false }))}
            parentData={set}
            isSet
            onCompletePrint={onCompletePrint}
          />
          <DialogBox show={modals.convertSuccess ?? false}>
            <PopUp
              height={true}
              style={{ bottom: "0px" }}
              onClick={() => {
                setModals((prev) => ({ ...prev, convertSuccess: false }));
                getSetDetails();
                setSelectedData();
              }}
              onClose={() => {
                setModals((prev) => ({ ...prev, convertSuccess: false }));
                getSetDetails();
                setSelectedData();
              }}
              button="Close"
              title="Converted Successfully"
              main=""
              body=""
            />
          </DialogBox>
          <ToastContainer
            position="top-right"
            autoClose={3000}
            closeOnClick
            pauseOnFocusLoss
            pauseOnHover
          />
        </div>
      )}
    </Page>
  );
}
