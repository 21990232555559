import { useEffect } from "react";
import { useState } from "react";
import {
  removeABookInCart,
  updateQuantityforSets,
  updateValidityforSets,
  addBooksInCartFromsets,
  removeValiditySetCart,
  removeABookInCartforsets
} from "../../assets/Js/script";
import CustomDropDown from "../../components/CustomDropDown";
import CrossIco from "../../assets/images/cross.png";
const Input = ({ onChange, defaultValue, disabled, ...props }) => (
  <input
    className={`h-[52px] mt-0 border-b border-b-[#5F5F5F] w-full bg-[#F5F6FA] font-normal text-center  focus:outline-none ${
      disabled ? "bg-[#EDEDED] text-[#70296370]" : "text-secondary-light"
    }`}
    onChange={onChange}
    defaultValue={defaultValue}
    disabled={disabled}
    type="number"
    min={1}
    // oninput="if (this.value < 0) this.value = '';"
    {...props}
  />
);

const OrderIdSetCard = ({
  set,
  wholeAccessIds,
  setWholeAccessIds,
  toast,
  setSetDetails,
  currentAccessCodes,
  userType
}) => {
  console.log(set, "ddddd");

  const [dropDownOptions, setDropDownOptions] = useState([]);
  const [accessIds, setAccessIds] = useState(
    wholeAccessIds
      .filter((b) => b.setId == set.id)
      .map((a) => ({ ...a, aidCount: a.numberOfCopies - a.qrCodeCount }))
  );

  const quantityChangeHandler = async (type, value, validity, cartId) => {
    console.log(type, value, "type, value, ");
    if (value != "" && Number(value) < 0) {
      value = 0;
    }
    let tmpAccessIds = accessIds;
    let selAid;

    // if (value == '') return
    accessIds.forEach((aid, idx) => {
      if (aid.validity == validity) {
        selAid = aid;
        selAid.aidCount = selAid.numberOfCopies - selAid.qrCodeCount;
        selAid[type] = Number(value ?? 0);

        selAid.numberOfCopies = selAid.aidCount + selAid.qrCodeCount;

        // console.log(selAid)
        tmpAccessIds[idx] = selAid;
      }
    });

    setAccessIds(tmpAccessIds);

    //updateAccessIdDetails
    let tmpAccessIdDetails = [...wholeAccessIds];
    wholeAccessIds.forEach((aid, idx) => {
      if (aid.id == selAid.id) {
        tmpAccessIdDetails[idx] = selAid;
      }
    });
    setWholeAccessIds([...tmpAccessIdDetails]);

    const { response, error } = await updateQuantityforSets(
      selAid.id,
      selAid.numberOfCopies,
      true,
      selAid.qrCodeCount
    );

    if (!response?.status == "success") {
      toast(response.message ?? "Something went wrong");
    }
  };

  const validityChangeHandler = async (cartId, validity) => {
    let selAid;
    let selAidIdx;

    accessIds.forEach((a, idx) => {
      if (a.id == cartId) {
        selAid = a;
        selAidIdx = idx;
      }
    });
    if (!selAid) return;
    selAid.validity = Number(validity);

    let tmpAccessIds = [...accessIds];
    tmpAccessIds[selAidIdx] = selAid;
    setAccessIds([...tmpAccessIds]);

    const { response, error } = await updateValidityforSets(
      cartId,
      validity,
      true
    );

    if (!response?.status == "success") {
      toast(response.message ?? "Something went wrong");
    }
  };

  async function addRow() {
    let validity;
    const alreadyAccessIds = accessIds.map((a) => a.validity);
    const numbers = [1, 2, 3, 4, 5];
    console.log(set, "set");
    numbers.forEach((n) => {
      if (!alreadyAccessIds.includes(n) && !validity) {
        validity = n;
      }
    });

    const { response } = await addBooksInCartFromsets(
      [
        {
          id: set.id,
          validity,
          maxValidity: accessIds[0]?.maxValidity
        }
      ],
      false
    );
    if (response.status === "success") {
      console.log(response, "rrrr");

      setAccessIds((prev) => [...prev, response.addedSets[0]]);
      setWholeAccessIds((prev) => [
        ...prev.filter((a) => a.setId != set.id),
        ...accessIds,
        response.addedSets[0]
      ]);
    } else {
      toast(response?.message ? response?.message : "Something went wrong");
    }
  }

  async function removeRow(cartId) {
    console.log(cartId, "ccc");

    const { response, error } = await removeValiditySetCart(cartId, false);
    if (response.status === "success") {
      setAccessIds((prev) => [...prev.filter((a) => a.id != cartId)]);
      setWholeAccessIds((prev) => [...prev.filter((a) => a.id != cartId)]);
    } else {
      toast(response?.message ? response?.message : "Something went wrong");
    }
  }
  async function handleRemoveBook() {
    const { response, error } = await removeABookInCartforsets(set.id, false);
    console.log(response, "resp");
    if ((response.status = "success")) {
      setSetDetails((prevState) => [
        ...prevState.filter((b, index) => b.id != set.id)
      ]);
    } else {
      toast(response?.message ? response?.message : "Something went wrong");
    }
    if (error) {
      console.log(error, "eeee");
      toast("Something went wrong");
    }
  }

  const dropDownOptionsHandler = () => {
    let nums = [1, 2, 3, 4, 5];
    const alreadyAccessIds = accessIds.map((a) => a.validity);
    console.log(alreadyAccessIds, "alreadye");
    const final = nums
      .filter(
        (a) => a <= accessIds[0]?.maxValidity && !alreadyAccessIds.includes(a)
      )
      .map((a) => ({ name: a.toString(), value: a.toString() }));
    setDropDownOptions(final);
  };

  useEffect(() => {
    dropDownOptionsHandler();
  }, [accessIds]);
  return (
    <div
      key={set.id}
      className=" flex bg-neutral-0 mb-4 min-h-[226px] border-1 border-solid border-primary-gray"
    >
      <div
        className="p-2 flex justify-center items-center w-40 bg-[red] "
        style={{ backgroundColor: set.backgroundColor }}
      >
        <img
          src={`https://${process.env.REACT_APP_AWS_BUCKET_NAME}.s3.amazonaws.com/setCovers/${set.cover}`}
          alt="front cover"
          className=" object-cover shadow-xl"
        />
      </div>
      <div className="p-4 w-id-card">
        <div className="flex justify-between items-center mb-6 ">
          <h3 className="text-heading font-semibold text-p1 text-left capitalize">
            {set.title}
          </h3>
          <div
            title="Delete set"
            onClick={handleRemoveBook}
            className="ml-[2px] mr-[15px] cursor-pointer"
          >
            <svg
              width="14"
              height="17"
              viewBox="0 0 10 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.999674 10.0833C0.999674 10.725 1.59967 11.25 2.33301 11.25H7.66634C8.39967 11.25 8.99967 10.725 8.99967 10.0833V3.08333H0.999674V10.0833ZM9.66634 1.33333H7.33301L6.66634 0.75H3.33301L2.66634 1.33333H0.333008V2.5H9.66634V1.33333Z"
                fill="#585858"
              />
            </svg>
          </div>
        </div>
        <table class="table-auto text-center align-middle">
          <tr className="text-secondary-dark font-[500] text-h5 ">
            <th className="px-3">Validity</th>

            <th className="px-3">Quantity</th>
            <th className="px-3"></th>
          </tr>

          {accessIds.map((aid) => (
            <>
              <tr className="text-secondary-light text-[10px]">
                <th className=""></th>
                <th className="pt-2">
                  <p className="">
                  Unused AIDs:{" "}
                    {currentAccessCodes.find(
                      (a) =>
                        a.validity == aid.validity &&
                        a.setId == set.id &&
                        a.isQr == false
                    )?.count ?? 0}
                  </p>
                </th>

                <th className=""></th>
              </tr>
              <tr>
                <td className="px-3">
                  <CustomDropDown
                    values={[{ name: aid.validity, value: aid.validity }]}
                    onChange={(v) => validityChangeHandler(aid.id, v[0].name)}
                    options={dropDownOptions}
                    labelField={"name"}
                    style={{
                      // marginTop: 12,
                      border: "none",
                      borderBottom: "1px solid #5F5F5F",
                      backgroundColor: "#F5F6FA",
                      color: "#8F959E",
                      height: 50,
                      width: 150,
                      paddingLeft: 10
                    }}
                  />
                </td>
                <td className="px-3">
                  <Input
                    value={
                      aid.aidCount ??
                      Number(aid.numberOfCopies) - Number(aid.qrCodeCount)
                    }
                    onChange={(e) =>
                      quantityChangeHandler(
                        "aidCount",
                        Number(e.target.value),
                        aid.validity
                      )
                    }
                  />
                </td>

                <td>
                  {userType !== "Individual Buyer" && accessIds.length > 1 && (
                    <img
                      title="Remove Row"
                      onClick={() => removeRow(aid.id)}
                      src={CrossIco}
                      className="w-3 h-3 object-contain cursor-pointer"
                    />
                  )}
                </td>
              </tr>
            </>
          ))}
        </table>

        {userType !== "Individual Buyer" &&
          accessIds[0]?.maxValidity > accessIds.length && (
            <div onClick={addRow} className="w-[100px]">
              <p className="text-thumb mt-3 mx-3 cursor-pointer">Add Row</p>
            </div>
          )}
      </div>
    </div>
  );
};

export default OrderIdSetCard;
