import React, { useState } from "react";
import { BtnSmall, IconBtn } from "../../../components";
import { useAuth } from "../../../hooks";
import CrossIco from "../../../assets/images/cross.png";
const ValidateQrSearch = ({ onSearch, onclearSearch, bookDetails }) => {
  const [search, setSearch] = useState("");

  const { user } = useAuth();

  return (
    <div className="flex">
      <div
        className={`flex relative  mr-3 items-center sm:w-[231px] ${
          search?.length > 0 && "shadow-lg"
        }  min-w-[231px] lg:w-7/12 max-w-search border-1 border-solid border-primary-dark-20 `}
      >
        {/* <IconBtn> */}
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="mx-3"
        >
          <path
            d="M17 17L13.1333 13.1333M15.2222 8.11111C15.2222 12.0385 12.0385 15.2222 8.11111 15.2222C4.18375 15.2222 1 12.0385 1 8.11111C1 4.18375 4.18375 1 8.11111 1C12.0385 1 15.2222 4.18375 15.2222 8.11111Z"
            stroke="#702963"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        {/* </IconBtn> */}
        <div
          type="text"
          disabled
          className=" outline-0 max-w-fit text-secondary-dark   border-0 border-solid border-primary-light"
        >
          <p>{user?.pId?.split("-")[0]}-QR-</p>
        </div>
        <input
          type="text"
          placeholder="XXXXXX-XX"
          value={search}
          onChange={(e) => setSearch(e.target.value.replace(/[^0-9-]/g, ""))}
          className=" outline-0  flex-1 min-w-32 max-w-search-bar  border-0 border-solid border-primary-light"
        />
        <img
          src={CrossIco}
          alt="cross"
          className="mr-2 w-3 h-3 object-contain cursor-pointer"
          title="Clear input"
          onClick={() => setSearch("")}
        />
      </div>
      <div
        onClick={() => onSearch(search)}
        className="px-10 bg-primary-dark text-primary-white p-3 cursor-pointer"
      >
        <p>Search</p>
      </div>
      {bookDetails && (
        <p
          onClick={() => {
            setSearch("");
            onclearSearch();
          }}
          className="ml-2  text-thumb underline self-end cursor-pointer"
        >
          Clear Search
        </p>
      )}
    </div>
  );
};

export default ValidateQrSearch;
