import React, { useContext, useEffect, useState } from "react";
import { Navbar } from "../../components";
import Page from "../../components/page";
import { getallNotification, updateNotification } from "../../assets/Js/script";
import moment from "moment";

import { collection, query, where, onSnapshot, doc, updateDoc } from "firebase/firestore";
import { db } from "../../utils/Firebase";
import { AuthContext } from "../../context/auth";

function Notification() {
  const [notification, setNotification] = useState(null);
  const [seen, setSeen] = useState(false);

  let { user, } = useContext(AuthContext);

  useEffect(() => {
    const q = query(collection(db, process.env.REACT_APP_NOTIF_COLLECTION), where("userId", "==", user?.id));

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const notifs = [];
      querySnapshot.forEach((doc) => {
        notifs.push({ id: doc.id, ...doc.data() });
      });
      console.log(notifs, 'notttt')
      setNotification(notifs)
    });
    return unsubscribe
  }, []);

  async function updatenotification(id) {
    setSeen(false);
    try {
      const docRef = doc(db, "Notifications", id);

      // Set the "capital" field of the city 'DC'
      await updateDoc(docRef, {
        seen: true
      });
      setSeen(true);
    } catch (error) {
      console.log(error)
    }
  }

  function getTime(time) {
    return moment(time).fromNow(true);
  }

  return (
    <Page active="wallet">
      <Navbar />
      <div>
        <h1 className="font-[600] font-[30px] my-6 text-h1">Notifications</h1>
        <div className=" flex flex-col p-4 py-8 bg-[#fafafb] min h-[580px] overflow-y-scroll rounded-[10px] scrollbar">
          {notification?.length > 0 || notification === null ? (
            notification?.map((data, index) => (
              <div
                key={data.id}
                onClick={() => updatenotification(data.id)}
                className={`${!data.seen ? "bg-[#fff]" : "bg-[#ffffff41]"
                  } cursor-pointer flex mb-8 flex-row items-center h-[74px]`}
              >
                <span className="mt-[-15px] w-[80px] flex justify-start pl-4 items-end ">
                  <svg
                    width="27"
                    height="29"
                    viewBox="0 0 27 29"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M25.6837 20.6938C23.8614 18.6538 22.5748 17.6154 22.5748 11.9915C22.5748 6.84135 19.6994 5.00651 17.3328 4.11538C17.0185 3.99726 16.7225 3.72596 16.6267 3.43065C16.2116 2.13841 15.0478 1 13.5008 1C11.9539 1 10.7894 2.13906 10.3785 3.43195C10.2827 3.7305 9.98678 3.99726 9.67242 4.11538C7.30298 5.00781 4.43041 6.83615 4.43041 11.9915C4.42687 17.6154 3.14032 18.6538 1.318 20.6938C0.562955 21.5388 1.22433 22.8077 2.54494 22.8077H24.4639C25.7774 22.8077 26.4345 21.5349 25.6837 20.6938ZM18.0424 22.8077V23.8462C18.0424 24.9478 17.564 26.0044 16.7122 26.7834C15.8605 27.5624 14.7054 28 13.5008 28C12.2963 28 11.1412 27.5624 10.2894 26.7834C9.43773 26.0044 8.95924 24.9478 8.95924 23.8462V22.8077"
                      fill="#C5ACC0"
                    />
                    <path
                      d="M18.0425 22.8077V23.8462C18.0425 24.9478 17.564 26.0044 16.7122 26.7834C15.8605 27.5624 14.7054 28 13.5008 28C12.2963 28 11.1412 27.5624 10.2894 26.7834C9.43773 26.0044 8.95924 24.9478 8.95924 23.8462V22.8077M25.6837 20.6938C23.8614 18.6538 22.5748 17.6154 22.5748 11.9915C22.5748 6.84135 19.6994 5.00651 17.3328 4.11538C17.0185 3.99726 16.7225 3.72596 16.6267 3.43065C16.2116 2.13841 15.0478 1 13.5008 1C11.9539 1 10.7894 2.13906 10.3785 3.43195C10.2827 3.7305 9.98678 3.99726 9.67242 4.11538C7.30298 5.00781 4.43041 6.83615 4.43041 11.9915C4.42687 17.6154 3.14032 18.6538 1.318 20.6938C0.562955 21.5388 1.22433 22.8077 2.54494 22.8077H24.4639C25.7774 22.8077 26.4345 21.5349 25.6837 20.6938Z"
                      stroke="#8E7089"
                      stroke-opacity="0.27"
                      stroke-width="0.6"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
                <div>
                  <p className="text-[#702963] text-[18.27px] font-[600] ">
                    {data.title}
                  </p>
                  <p className="font-[400] text-[15.65px] w-full pr-28 text-[#6C6A6A] ">
                    {data.description}
                  </p>
                </div>
                <span className="ml-auto font-[500] text-[#6c6a6a6b] whitespace-nowrap px-3 ">
                  {getTime(data.createdAt.toDate())}
                </span>
              </div>
            ))
          ) : (
            <div className="w-full h-full flex items-center justify-center mt-36 ">
              <h2 className="text-secondary-light text-montserrat font-semibold leading-[29px] text-h1">
                Notification is empty{" "}
              </h2>
            </div>
          )}
        </div>
      </div>
    </Page>
  );
}

export default Notification;
