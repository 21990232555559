 export function getOrdinalSuffix(number) {
    if (number === 0) {
      return '0th'; // Handle special case for 0
    }
  
    // Handle special cases for 11, 12, and 13
    if (number % 100 >= 11 && number % 100 <= 13) {
      return number + 'th';
    }
  
    // Handle the general case
    switch (number % 10) {
      case 1:
        return number + 'st';
      case 2:
        return number + 'nd';
      case 3:
        return number + 'rd';
      default:
        return number + 'th';
    }
  }
