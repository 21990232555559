import { useState, useEffect, useMemo } from "react";
import {
  Page,
  Navbar,
  BtnBlock,
  DialogBox,
  BtnSmall,
  BtnSmallWhite,
  Loader,
  IconBtn,
  BtnSmallDisabled,
  GeneralLoader,
  PopUp
} from "../components";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import * as ROUTES from "../constant/routes";
import {
  addBooksInCartFromsets,
  getAllSets,
  getAllBooks,
  addBooksInSet,
  getBooKforSets,
  getAllBooksInSet,
  sendOtp,
  wallet,
  verifyOtpSets,
  getSetMetadata,
  uploadSetCover,
  activateSet
} from "../assets/Js/script";
import "./catalogue.css";
import moment from "moment";
import AuthProvider from "../helpers/authProvider";
import Timeout from "../helpers/Timeout";
import { ToastContainer, toast } from "react-toastify";
import FastAverageColor from "fast-average-color";

export default function Sets() {
  const [modals, setModals] = useState({
    expandedMenu: false,
    generateIds: false,
    sort: false,
    renew: false,
    hostingCharges: false,
    insufficient: false,
    optConfirmed: true,
    uploadByBook: false,
    getSelectedBooks: false,
    step2: false,
    step3: false,
    step4: false,
    step5: false,
    selectValidity: false,
    status: false
  });
  const notify = (message) => toast(message);
  const [loader, setLoader] = useState(true);
  const [sort, setSort] = useState(0);
  const [applySort, setApplySort] = useState(false);
  const [filterData, setFilterData] = useState("New Arrival");
  const [flterSort, setFilterSort] = useState(false);
  const navigation = useNavigate();
  const [showBooks, setShowBooks] = useState(true);
  const [expiringSoon, setExpiringSoon] = useState([]);
  const [books, setBooks] = useState([]);
  const [backgroundColors, setBackgroundColors] = useState("");
  const [walletDetails, setWalletDetails] = useState(null);
  const [sets, setSets] = useState([]);
  const [searchString, setSearchString] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [metaData, setMetaData] = useState({
    description: "",
    setName: "",
    frontCover: null,
    backCover: null,
    bookCover: [],
    category: [],
    publications: [],
    authors: [],
    validity: 1,
    numOfPages: 200
  });

  const [didUserPay, setDidUserPay] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [totalSetSize, setTotalSetSize] = useState(0);
  const [page, setPage] = useState(1);
  // access ids

  const [renderedStep, setRenderedStep] = useState(null);
  const [currentStep, setCurrentStep] = useState(1);
  const [accessIdSelected, setAccessIdSelected] = useState(true);
  const [generateAccessIds, setGenerateAccessIds] = useState(false);
  const [selected, setSelected] = useState([]);
  const [selectedSet, setSelectedSet] = useState([]);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [selectedValidity, setSelectedValidity] = useState(1);
  const [otps, setOtps] = useState(new Array(6).fill(""));
  const [bookCount, setBookCount] = useState(2);
  const [totalBooks, setTotalBooks] = useState(0);

  //upload image
  const [uploadImage, setUploadImage] = useState(null);

  // date
  const [percentage, setPercentage] = useState();
  const [daysToExpire, setDayToExpire] = useState();

  const navigate = useNavigate();

  const handleUpload = (e) => {
    setUploadImage(URL.createObjectURL(e.target.files[0]));
  };

  function changeData(e) {
    const data = e.target.textContent;
    setFilterData(data);
  }

  function handleChange(e) {
    let { name, value } = e.target;
    if (name === "description" && value.length > 254) {
      value = metaData.description;
    }
    setMetaData((prevState) => ({ ...prevState, [name]: value }));
  }

  function handleSelection(book) {
    setSelected((prevState) => {
      let duplicate = false;

      prevState.forEach((item) => {
        if (item.parentId === book.parentId) {
          duplicate = true;
        }
      });

      if (duplicate) {
        return prevState.filter((item) => item.parentId !== book?.parentId);
      } else {
        return [...prevState, book];
      }
    });
  }

  function handleSelectionSet(book) {
    setSelectedSet((prevState) => {
      let duplicate = false;

      prevState.forEach((item) => {
        if (item.id === book.id) {
          duplicate = true;
        }
      });

      if (duplicate) {
        return prevState.filter((item) => item.id !== book?.id);
      } else {
        return [...prevState, book];
      }
    });
  }

  // extracting colors

  async function handleGenerate() {
    const { response, error } = await addBooksInCartFromsets(
      selectedSet,
      accessIdSelected
    );

    if (response.status === "success") {
      navigate(
        accessIdSelected
          ? ROUTES.GENERATE_ACCESS_IDS_FOR_SETS
          : ROUTES.GENERATE_ORDER_IDS_FOR_SETS
      );
    } else {
      notify(response?.message ? response?.message : "Something went wrong");
    }
  }

  async function getAllBooksFromServer(nextPage, searchString) {
    let booksCount = 20;
    setPage(nextPage);
    // const {
    //   response: { status, data, message },
    //   error,
    // } = await getAllBooks(bookCount, page);
    // if (status === "success") {
    //   setBooks([...data.books]);
    // } else {
    //   notify(message ? message : "Something went wrong");
    // }
    const {
      response: { status, data, message, length },
      error
    } = await getBooKforSets(booksCount, nextPage, searchString);
    if (status === "success") {
      let tempBooks = [];
      console.log(nextPage, "ooooooo");
      if (nextPage != 1) {
        tempBooks = [...books];
      }
      setBooks([...tempBooks, ...data.books]);
      setTotalBooks(length);
    } else {
      notify(message ? message : "Something went wrong");
    }
  }

  async function getMetadata() {
    const { response } = await getSetMetadata();
    console.log(response, "meta dat");
    if (response.status === "success") {
      setMetaData((prevState) => ({
        ...prevState,
        validity: selectedValidity,
        authors: response.data.authors,
        publications: response.data.publications,
        category: response.data?.categories
      }));
    } else {
      notify(response?.message ? response?.message : "Something went wrong");
    }
  }

  useEffect(() => {
    const bookCount = 20;
    // const page = 1;
    async function temp() {
      const {
        response: { status, data, message, length },
        error
      } = await getAllSets(bookCount, pageNo);
      if (status === "success") {
        setSets((prev) => [...prev, ...data.sets]);
        setTotalSetSize(length);
        // setExpiringSoon([...data?.expiringSoon]);
        // if (data.expiringSoon.length > 0) {
        //   setModals((prevState) => ({ ...prevState, expandedMenu: true }));
        // }
        const { response: statusResponse } = await getAllBooksInSet();
        if (statusResponse.status === "success") {
          if (statusResponse.data.isPaid) {
            await getMetadata();
            setSelected((prev) => [...statusResponse.data.books]);
            setMetaData((prevState) => ({
              ...prevState,
              validity: statusResponse.data.set.validity,
              numOfPages: statusResponse.data.totalPage
            }));
            setCurrentStep(4);
          }
        } else {
          notify(
            statusResponse?.message
              ? statusResponse?.message
              : "Something went wrong"
          );
        }
        setLoader(false);
      } else {
        notify(message ? message : "Something went wrong");
      }
      await getAllBooksFromServer(1);
    }
    temp();
  }, [bookCount, pageNo]);

  function checkSelected(id) {
    for (let i = 0; i < selected.length; i++) {
      if (selected[i].parentId === id) {
        return true;
      }
    }

    return false;
  }

  function checkSelectedSet(id) {
    for (let i = 0; i < selectedSet.length; i++) {
      if (selectedSet[i].id === id) {
        return true;
      }
    }
    return false;
  }

  const [hostingCharge, setHostingCharge] = useState(null);
  const [inSufficientBalance, setInSufficientBalance] = useState(false);
  const [amountToPay, setAmountToPay] = useState("");

  async function getAllBooksInASet() {
    setModals((prevState) => ({ ...prevState, getSelectedBooks: true }));
    const { response } = await getAllBooksInSet();
    if (response.status === "success") {
      setHostingCharge(response.data.hostingCharge);
      setMetaData((prevState) => ({
        ...prevState,
        numOfPages: response.data?.totalPage
      }));
      setSelected(response.data.books);
    } else {
      notify(response?.message ? response?.message : "Something went wrong");
    }
    setModals((prevState) => ({ ...prevState, getSelectedBooks: false }));
  }

  async function addSelectedBooks() {
    setButtonLoader(true);
    const { response } = await addBooksInSet(
      selected.map((item) => item.parentId)
    );
    if (response.status === "success") {
      setSelected([]);
      setCurrentStep(2);
      await getAllBooksInASet();
    } else {
      notify(response?.message ? response?.message : "Something went wrong");
    }
    setButtonLoader(false);
  }

  useEffect(() => {
    function progressBar() {
      var a = moment(moment().format("YYYY, M, DD"));
      var b = moment(moment(expiringSoon[0]?.expiresOn).format("YYYY, M, DD"));
      const DateValue = b.diff(a, "days");
      const perValue = Math.ceil(DateValue / 0.3);
      setDayToExpire(DateValue);
      setPercentage(perValue);
    }

    if (expiringSoon.length > 0) {
      progressBar();
    }
  }, [daysToExpire, percentage, expiringSoon]);

  async function handlePay() {
    setModals((prevState) => ({ ...prevState, step3: true }));
    const { response } = await sendOtp(hostingCharge, selectedValidity);
    if (
      response.status === "success" ||
      response?.message == "SMS OTP Failed! Please check OTP on your email."
    ) {
      setOtpSent(true);
    } else {
      notify(response?.message ? response?.message : "Something went wrong");
    }
    setModals((prevState) => ({ ...prevState, step3: false }));
  }
  async function getStatus() {
    const { response, error } = await getAllBooksInSet();
    if (response.status === "success") {
      if (response.data.isPaid) {
        await getMetadata();
        setSelected([...response.data.books]);
        setMetaData((prevState) => ({
          ...prevState,
          validity: response.data.set.validity,
          numOfPages: response.data.totalPage
        }));
        setCurrentStep(4);
      } else {
        setSelected([...response?.data?.books]);
        setMetaData((prevState) => ({
          ...prevState,
          // validity: response.data.set.validity,
          numOfPages: response.data.totalPage
        }));
        setHostingCharge(response.data.hostingCharge);
        setCurrentStep(2);
      }
    } else {
      notify(response?.message ? response?.message : "Something went wrong");
    }
  }
  useEffect(() => {
    async function temo() {
      const { response, error } = await getAllBooksInSet();
      if (response.status === "success") {
        if (response.data.isPaid) {
          setModals((prev) => ({ ...prev, status: true }));
        }
      }
    }
    temo();
  }, []);
  async function acceptCharges() {
    setModals((prevState) => ({ ...prevState, step2: true }));

    const { response, error } = await wallet();
    if (response.status === "success") {
      setWalletDetails({ ...response.data });
      response.data.myCredits.credits <
        selectedValidity * parseFloat(hostingCharge) &&
        setInSufficientBalance(true);

      setAmountToPay((selectedValidity * parseFloat(hostingCharge)).toFixed(2));
      setCurrentStep(3);
    } else {
      notify(response?.message ? response?.message : "Something went wrong");
    }
    setModals((prevState) => ({ ...prevState, step2: false }));
  }

  function handleChangeOtp(e, index) {
    const otpInputs = document.querySelectorAll(".otpInput");
    otpInputs.forEach((item) => {
      item.style.borderWidth = "0";
    });

    const { value } = e.target;
    let input;
    if (!isNaN(value)) {
      setOtps((prevState) => {
        if (value.length < 2) {
          prevState[index] = value;
        }
        for (let i = 0; i < prevState.length; i++) {
          if (prevState[i].length < 1) {
            input = document.getElementById(`input_${i}`);
            i < otps.length && value.length >= 1 && input.focus();
            break;
          }
        }
        return [...prevState];
      });
    }
  }

  function handleBookCoverUpload(e, index) {
    e.preventDefault();
    const file = e.target.files[0];
    setMetaData((prevState) => {
      prevState.bookCover[index] = file;
      return { ...prevState };
    });
  }

  async function handleVerifyOtp() {
    setModals((prevState) => ({ ...prevState, step4: true }));
    const { response } = await verifyOtpSets(otps);
    if (response.status === "success") {
      await getMetadata();
      setCurrentStep(4);
    } else {
      const otpInputs = document.querySelectorAll(".otpInput");
      otpInputs.forEach((item) => {
        item.style.borderColor = "red";
        item.style.borderWidth = "1px";
      });
    }
    setModals((prevState) => ({ ...prevState, step4: false }));
  }
  // console.log(backgroundColors, "color");
  async function handleActivateSet(e) {
    e.preventDefault();
    setModals((prevState) => ({ ...prevState, step5: true }));
    const { response: uploadCoverResponse } = await uploadSetCover(
      metaData.bookCover[0],
      metaData.bookCover?.[1] ?? null
    );
    const { response } = await activateSet(metaData, backgroundColors);
    if (response.status === "success") {
      setModals((prevState) => ({
        ...prevState,
        uploadByBook: false
      }));
      setCurrentStep(1);
      setMetaData({
        description: "",
        setName: "",
        frontCover: null,
        backCover: null,
        bookCover: [],
        category: [],
        validity: 0,
        numOfPages: 0,
        publications: [],
        authors: []
      });
      setBackgroundColors("");
      setModals((prev) => ({ ...prev, status: false }));
      setSelected([]);
      setOtps(new Array(6).fill(""));
      setSelectedValidity(1);
      setLoader(true);
      async function temp() {
        const {
          response: { status, data },
          error
        } = await getAllSets();
        if (status === "success") {
          setSets([...data.sets]);
          // setExpiringSoon([...data?.expiringSoon]);
          // if (data.expiringSoon.length > 0) {
          //   setModals((prevState) => ({ ...prevState, expandedMenu: true }));
          // }
        }
        setLoader(false);
      }
      temp();
    } else {
      notify(response?.message ? response?.message : "Something went wrong");
    }
    setModals((prevState) => ({ ...prevState, step5: false }));
  }

  useEffect(() => {
    window.addEventListener("click", (e) => {
      setModals((prevState) => ({
        ...prevState,
        selectValidity: false
      }));
    });

    return () => {
      window.removeEventListener("click", (e) => {
        setModals((prevState) => ({
          ...prevState,
          selectValidity: false
        }));
      });
    };
  }, []);

  function handleIdType({ target }) {
    const { name } = target;
    setAccessIdSelected(name === "access_id" ? true : false);
  }

  function onImageLoad(e) {
    console.log("image loaded");
    const fav = new FastAverageColor();
    fav.getColorAsync(e.target).then((color) => {
      console.log(color.hex, "hexcolor");
      setBackgroundColors(color.hex);
    });
  }
  const dragOver = (e) => {
    e.preventDefault();
  };

  const dragEnter = (e) => {
    e.preventDefault();
  };

  const dragLeave = (e) => {
    e.preventDefault();
  };

  const fileDrop = async (e, index) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    // setMetaData((prevState) => {
    //   prevState.bookCover[0] = file;
    //   return { ...prevState };
    // });
    setMetaData((prevState) => {
      prevState.bookCover[index] = file;
      return { ...prevState };
    });
  };

  const searchHandler = (e) => {
    setSearchString(e.target.value);
    setBooks([]);
    getAllBooksFromServer(1, e.target.value);
  };

  useEffect(() => {
    setRenderedStep(() => {
      switch (currentStep) {
        case 1:
          return (
            <>
              <p className="font-bold text-[20px] text-left">
                Selected Books {`(${selected?.length})`}
              </p>

              <button
                className={`flex sm:h-[43px] lg:h-[54px] items-center w-full border-1 border-solid border-primary-dark-20 mt-3 mb-5`}
              >
                <IconBtn>
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17 17L13.1333 13.1333M15.2222 8.11111C15.2222 12.0385 12.0385 15.2222 8.11111 15.2222C4.18375 15.2222 1 12.0385 1 8.11111C1 4.18375 4.18375 1 8.11111 1C12.0385 1 15.2222 4.18375 15.2222 8.11111Z"
                      stroke="#702963"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </IconBtn>
                <input
                  type="text"
                  placeholder="Search by Book Titles"
                  value={searchString}
                  onChange={searchHandler}
                  className=" sm:h-[41px] lg:h-12 outline-0 border-0 w-full border-1 border-solid border-primary-light"
                />
              </button>

              <div className="flex flex-wrap justify-start items-center w-full h-52 overflow-auto">
                {books
                  // ?.filter((item) =>
                  //   item.title
                  //     .toLowerCase()
                  //     .includes(searchString.toLowerCase())
                  // )
                  .map((book, index) => {
                    return (
                      <div key={book.id} onClick={() => handleSelection(book)}>
                        <div className="w-[166px] h-[197px] border-1 border-solid border-primary-dark-20 scale-1 hover:scale-105 transition duration-600 hover:ease-in bg-[#F8F2F7] mr-8 mb-8">
                          <div
                            className="flex justify-center items-center w-[98px] h-full md:w-full lg:h-[70%] relative"
                            style={{ background: book.backgroundColor }}
                          >
                            <img
                              className="shadow-[5px_5px_13px_3px_rgba(0,0,0,0.5)] h-[90%] object-contain"
                              src={`https://${process.env.REACT_APP_AWS_BUCKET_NAME}.s3.amazonaws.com/books/${book.imageCoverFront}`}
                              alt="front cover"
                            />
                            {/* <div className="absolute top-2 right-2 rounded-full flex justify-center items-center bg-neutral-0 w-[54px] h-[54px]">
                              <p className="text-tablet-s text-center leading-tight text-secondary-dark">
                                <span className="text-p1 text-tertiary-dark font-semibold">
                                  {book.validity}yrs
                                </span>
                                <br />
                                Validity
                              </p>
                            </div> */}
                            {checkSelected(book.parentId) && (
                              <div className="absolute bottom-4 right-4 bg-primary-dark flex justify-center items-center rounded-full w-6 h-6">
                                <svg
                                  width="15"
                                  height="10"
                                  viewBox="0 0 15 10"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M0.313241 3.75601C0.51387 3.57208 0.785945 3.46875 1.06963 3.46875C1.35332 3.46875 1.6254 3.57208 1.82603 3.75601L5.60906 7.22533L13.173 0.287674C13.2723 0.196515 13.3903 0.124192 13.5201 0.0748331C13.6499 0.025474 13.789 4.5617e-05 13.9295 6.13195e-08C14.0701 -4.54943e-05 14.2092 0.0252926 14.3391 0.0745675C14.4689 0.123842 14.5869 0.196089 14.6863 0.287183C14.7857 0.378277 14.8646 0.486433 14.9184 0.605478C14.9722 0.724522 14.9999 0.852123 15 0.980994C15 1.10987 14.9724 1.23748 14.9187 1.35656C14.865 1.47564 14.7862 1.58385 14.6868 1.67501L5.60906 10L0.313241 5.14335C0.112673 4.95936 0 4.70984 0 4.44968C0 4.18952 0.112673 3.94 0.313241 3.75601Z"
                                    fill="white"
                                  />
                                </svg>
                              </div>
                            )}
                          </div>
                          <div className="w-full p-1">
                            <p className="mt-1 whitespace-nowrap overflow-hidden text-ellipsis text-mobile-p1 sm:text-tablet-p1 lg:text-p1 text-[16px] text-heading font-semibold text-left leading-none">
                              {book?.title}
                            </p>
                            <div className="flex justify-between items-center mt-1">
                              <p className="text-secondary-dark whitespace-nowrap overflow-hidden text-ellipsis text-mobile-p1 sm:text-tablet-p1 lg:text-[12px] font-semibold">
                                {book.Bauthors?.map((auther, index, arr) => (
                                  <span>
                                    {arr.indexOf(arr[index]) === arr.length - 1
                                      ? auther.bookAuthorName
                                      : `${auther.bookAuthorName}, `}
                                  </span>
                                ))}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                {books?.length < totalBooks && totalBooks != 0 && (
                  <div className="ml-4 w-full flex items-center justify-center">
                    <button
                      onClick={() => getAllBooksFromServer(page + 1)}
                      className="text-p text-thumb underline font-semibold "
                    >
                      Load More
                    </button>
                  </div>
                )}
              </div>

              <div className="flex justify-end w-full my-2">
                <div className="relative">
                  <button
                    className="h-12 w-48 bg-primary-dark text-[#fff] font-semibold text-[18px]"
                    onClick={() => addSelectedBooks()}
                  >
                    Next
                  </button>
                  {buttonLoader && (
                    <GeneralLoader width={5} radius={30} dark={false} />
                  )}
                </div>
              </div>
            </>
          );
        case 2:
          return (
            <div className="flex flex-col justify-center items-center overflow-y-auto">
              <div className="w-[90%] h-[700px] relative flex flex-col justify-center items-center border border-solid border-[#70296317] p-3">
                {modals.getSelectedBooks && (
                  <GeneralLoader radius={45} width="5" dark={true} />
                )}

                <div className="flex flex-col w-[99%]  p-2 border border-solid border-[#AEAEAE2E] mt-36">
                  <p className="text-[#00000085] font-bold text-[20px] text-left ">
                    Selected books
                  </p>
                  <div className="flex justify-start overflow-hidden overflow-x-scroll w-full items-center">
                    {selected.map((item) => {
                      return (
                        <div
                          key={item.id}
                          className="flex w-[266px] h-[134px] justify-center items-center bg-primary-extra-light mr-6 p-2"
                        >
                          <div
                            className="w-[106px] h-full mr-[11px] bg-[#A93030] flex justify-center items-center py-4"
                            style={{ background: item.backgroundColor }}
                          >
                            <img
                              src={`https://${process.env.REACT_APP_AWS_BUCKET_NAME}.s3.amazonaws.com/books/${item.imageCoverFront}`}
                              alt="book"
                              className=" shadow-[5px_5px_13px_3px_rgba(0,0,0,0.5)] h-[90%] w-[75%] object-cover"
                            />
                          </div>
                          <div className="w-[160px] font-semibold">
                            <h2 className="text-[303030] text-mobile-h5">
                              {item.title}
                            </h2>
                            <h3 className="text-[#5F5D5D8F] text-mobile-p1">
                              {item.Bauthors?.map((auther, index, arr) => (
                                <span>
                                  {arr.indexOf(arr[index]) === arr.length - 1
                                    ? auther.bookAuthorName
                                    : `${auther.bookAuthorName}, `}
                                </span>
                              ))}{" "}
                            </h3>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <p className="text-p1 my-6 leading-[24px] text-secondary-dark text-left w-full ml-4">
                  Charges for the books selected:
                </p>

                <div className="flex items-center justify-start w-full pl-4 mb-2">
                  <p className="font-semibold text-secondary-light text-[18px] w-[255px]">
                    Total no. of pages <br />
                    <span className="text-[12px] ">
                      (For all the books within the set)
                    </span>
                  </p>
                  <p className="text-left font-semibold text-secondary-dark text-[16px] leading-[19.5px] text-montserrat capitalize ">
                    {metaData.numOfPages}
                  </p>
                </div>
                <div className="flex items-center justify-start w-full pl-4 mb-2">
                  <p className="font-semibold text-secondary-light text-[18px] w-[255px]">
                    Validity of the Set
                  </p>
                  <div
                    onClick={(e) => e.stopPropagation()}
                    className="flex relative items-center justify-center"
                  >
                    <button
                      onClick={() =>
                        setModals((prevState) => ({
                          ...prevState,
                          selectValidity: true
                        }))
                      }
                      className="flex items-center font-semibold justify-evenly text-secondary-dark bg-neutral-10 h-6 w-[82px] lg:h-8 lg:w-[107px]"
                    >
                      {selectedValidity} year
                      <span className="ml-2 bg-secondary-yellow w-4 h-4 rounded-sm flex justify-center items-center">
                        <svg
                          width="10"
                          height="5"
                          viewBox="0 0 10 5"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M5 5L0 0H10L5 5Z" fill="#7A7976" />
                        </svg>
                      </span>
                    </button>
                    {modals.selectValidity && (
                      <div className="absolute left-0 top-8 w-full">
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            setSelectedValidity(1);
                            setModals((prevState) => ({
                              ...prevState,
                              selectValidity: false
                            }));
                          }}
                          className="flex items-center font-semibold justify-start pl-[14px] text-secondary-dark bg-[#c4c4c4] h-6 w-[82px] lg:h-8 lg:w-[107px]"
                        >
                          1 year
                        </button>
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            setSelectedValidity(2);
                            setModals((prevState) => ({
                              ...prevState,
                              selectValidity: false
                            }));
                          }}
                          className="flex items-center font-semibold justify-start pl-[14px] text-secondary-dark bg-[#c4c4c4] h-6 w-[82px] lg:h-8 lg:w-[107px]"
                        >
                          2 year
                        </button>
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            setSelectedValidity(3);
                            setModals((prevState) => ({
                              ...prevState,
                              selectValidity: false
                            }));
                          }}
                          className="flex items-center font-semibold justify-start pl-[14px] text-secondary-dark bg-[#c4c4c4] h-6 w-[82px] lg:h-8 lg:w-[107px]"
                        >
                          3 year
                        </button>
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            setSelectedValidity(4);
                            setModals((prevState) => ({
                              ...prevState,
                              selectValidity: false
                            }));
                          }}
                          className="flex items-center font-semibold justify-start pl-[14px] text-secondary-dark bg-[#c4c4c4] h-6 w-[82px] lg:h-8 lg:w-[107px]"
                        >
                          4 year
                        </button>
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            setSelectedValidity(5);
                            setModals((prevState) => ({
                              ...prevState,
                              selectValidity: false
                            }));
                          }}
                          className="flex items-center font-semibold justify-start pl-[14px] text-secondary-dark bg-[#c4c4c4] h-6 w-[82px] lg:h-8 lg:w-[107px]"
                        >
                          5 year
                        </button>
                      </div>
                    )}
                    {/* <p className="ml-4 text-tertiary-dark font-medium">
                      = {hostingCharge} Tokens
                    </p> */}
                  </div>
                </div>
                <div className="flex items-center justify-start mb-2 w-full pl-4">
                  <p className="flex items-center font-semibold text-secondary-light w-[255px] text-[18px]">
                    Hosting charges{" "}
                    <span
                      onClick={() =>
                        setModals((prevState) => ({
                          ...prevState,
                          hostingCharges: true
                        }))
                      }
                      className="relative ml-1"
                    >
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8 16C10.1217 16 12.1566 15.1571 13.6569 13.6569C15.1571 12.1566 16 10.1217 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842855 10.1217 0 8 0C5.87827 0 3.84344 0.842855 2.34315 2.34315C0.842855 3.84344 0 5.87827 0 8C0 10.1217 0.842855 12.1566 2.34315 13.6569C3.84344 15.1571 5.87827 16 8 16ZM8.93 6.588L7.93 11.293C7.86 11.633 7.959 11.826 8.234 11.826C8.428 11.826 8.721 11.756 8.92 11.58L8.832 11.996C8.545 12.342 7.912 12.594 7.367 12.594C6.664 12.594 6.365 12.172 6.559 11.275L7.297 7.807C7.361 7.514 7.303 7.408 7.01 7.337L6.559 7.256L6.641 6.875L8.931 6.588H8.93ZM8 5.5C7.73478 5.5 7.48043 5.39464 7.29289 5.20711C7.10536 5.01957 7 4.76522 7 4.5C7 4.23478 7.10536 3.98043 7.29289 3.79289C7.48043 3.60536 7.73478 3.5 8 3.5C8.26522 3.5 8.51957 3.60536 8.70711 3.79289C8.89464 3.98043 9 4.23478 9 4.5C9 4.76522 8.89464 5.01957 8.70711 5.20711C8.51957 5.39464 8.26522 5.5 8 5.5Z"
                          fill="#C5C5C5"
                        />
                      </svg>
                      {modals.hostingCharges && (
                        <div className="p-3 absolute min-h-[116px] -top-1 left-5 bg-primary-white border-1 border-solid border-[rgba(0,0,0,0.42)] shadow-[3px_3px_2px_rgba(135,133,133,0.13)] min-w-[220px] max-w-[230px]">
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              setModals((prevState) => ({
                                ...prevState,
                                hostingCharges: false
                              }));
                            }}
                            className="absolute top-2 right-2"
                          >
                            <svg
                              width="8"
                              height="8"
                              viewBox="0 0 8 8"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M7.69542 7.72541C7.50417 7.89829 7.2448 7.99542 6.97435 7.99542C6.7039 7.99542 6.44453 7.89829 6.25328 7.72541L4.00043 5.39751L1.74757 7.72464C1.65316 7.81139 1.54068 7.88039 1.41663 7.92765C1.29257 7.97491 1.1594 7.9995 1.02478 7.99999C0.890163 8.00049 0.75677 7.97688 0.632294 7.93054C0.507819 7.88419 0.394725 7.81603 0.299536 7.72997C0.204346 7.64391 0.128946 7.54167 0.0776832 7.42914C0.0264205 7.3166 0.000310061 7.19601 0.000858208 7.07431C0.00140635 6.9526 0.0286021 6.83221 0.0808768 6.72005C0.133152 6.6079 0.209471 6.50622 0.305432 6.42086L2.64922 4.00077L0.304582 1.57914C0.208621 1.49379 0.132302 1.3921 0.0800271 1.27995C0.0277523 1.1678 0.000556602 1.0474 8.45477e-06 0.925694C-0.000539692 0.803993 0.0255708 0.683397 0.0768335 0.570864C0.128096 0.458331 0.203497 0.356087 0.298686 0.27003C0.393875 0.183973 0.506969 0.115807 0.631445 0.0694621C0.75592 0.0231175 0.889313 -0.000487914 1.02393 7.64362e-06C1.15855 0.000503201 1.29172 0.0250898 1.41578 0.0723493C1.53983 0.119609 1.65231 0.188606 1.74672 0.275361L4.00043 2.60403L6.25328 0.275361C6.34769 0.188606 6.46017 0.119609 6.58422 0.0723493C6.70828 0.0250898 6.84145 0.000503201 6.97607 7.64362e-06C7.11069 -0.000487914 7.24408 0.0231175 7.36856 0.0694621C7.49303 0.115807 7.60613 0.183973 7.70131 0.27003C7.7965 0.356087 7.8719 0.458331 7.92317 0.570864C7.97443 0.683397 8.00054 0.803993 7.99999 0.925694C7.99944 1.0474 7.97225 1.1678 7.91997 1.27995C7.8677 1.3921 7.79138 1.49379 7.69542 1.57914L5.35163 4.00077L7.69542 6.42086C7.79023 6.50649 7.86545 6.60817 7.91677 6.72009C7.96809 6.83201 7.99451 6.95198 7.99451 7.07314C7.99451 7.19429 7.96809 7.31426 7.91677 7.42618C7.86545 7.53811 7.79023 7.63978 7.69542 7.72541Z"
                                fill="black"
                                fill-opacity="0.53"
                              />
                            </svg>
                          </button>
                          <p className="text-secondary-dark text-[14px] font-medium italic leading-[18px] mb-2">
                            Hosting Charges
                          </p>
                          <p className="text-tertiary-dark text-[14px] font-medium italic leading-[18px] mb-2">
                            500 Tokens/1000 Pages
                          </p>
                          <p className="text-secondary-dark text-[14px] font-medium italic leading-[18px] mb-2">
                            Buffer charges are also included
                          </p>
                        </div>
                      )}
                    </span>
                  </p>
                  <p className="w-[170px]  font-semibold text-secondary-dark text-[16px] leading-[19.5px] text-montserrat ">
                    {hostingCharge} Tokens (Per Year)
                  </p>
                </div>
                <div className="flex items-center justify-start mb-2 w-full pl-4">
                  <p className="font-semibold text-secondary-light text-[18px] w-[255px] ">
                    Total Tokens
                  </p>
                  <p className="w-[170px] text-left font-semibold text-secondary-dark text-[16px] leading-[19.5px] text-montserrat capitalize ">
                    {(selectedValidity * parseFloat(hostingCharge)).toFixed(2)}{" "}
                    Tokens
                  </p>
                </div>

                {inSufficientBalance ? (
                  <button
                    disabled
                    className="w-[186px] capitalize disabled:opacity-[0.5] mt-5 h-[56px] font-semibold bg-primary-dark text-primary-white text-[18px]"
                  >
                    Accept Charges
                  </button>
                ) : (
                  <div className="w-full flex justify-end gap-3 mt-5">
                    <BtnSmallWhite onClick={() => setCurrentStep(1)}>
                      Back
                    </BtnSmallWhite>
                    <div className="relative h-[56px] ">
                      <button
                        onClick={() => acceptCharges()}
                        className="w-[186px] capitalize h-[56px] font-semibold bg-primary-dark text-primary-white text-[18px]"
                      >
                        Accept Charges
                      </button>
                      {modals.step2 && (
                        <GeneralLoader width={5} radius={30} dark={false} />
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          );
        case 3:
          return !otpSent ? (
            <>
              <div className="flex flex-col justify-center items-center">
                <div className="w-[90%] h-[550px] flex flex-col justify-start items-center border border-solid border-[#70296317] p-9">
                  <p className="mb-3 text-secondary-dark leading-[24px] text-montserrat font-bold text-[20px] text-left w-full">
                    Payment Details
                  </p>
                  {/* <p className="text-p1 my-6 leading-[24px] text-secondary-light text-left w-full">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. At
                    aliquet ut facilisi nulla mauris iopsum
                  </p> */}

                  <div className="p-3 w-full flex items-center justify-start border-1 border-solid border-[rgba(210,210,210,0.66)] mb-4">
                    <p className="flex items-center font-semibold text-secondary-light text-[18px]">
                      <span className="flex items-center justify-center bg-secondary-yellow border-[0.6px] border-solid border-[#ffd600] rounded-[2px] w-[20px] h-[20px] mr-3">
                        <svg
                          width="8"
                          height="12"
                          viewBox="0 0 8 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1.33073 0.308594H7.9974L7.33073 1.51494H5.1574C5.4774 1.86478 5.7174 2.27494 5.8574 2.72129H7.9974L7.33073 3.92764H5.9974C5.91478 4.67965 5.55375 5.38435 4.97289 5.92741C4.39203 6.47047 3.62541 6.82003 2.7974 6.91939V6.94351H2.33073L6.33073 11.1657H4.66406L0.664062 6.94351V5.73717H2.33073C3.50406 5.73717 4.4774 4.95304 4.6374 3.92764H0.664062L1.33073 2.72129H4.4374C4.06406 2.00955 3.26406 1.51494 2.33073 1.51494H0.664062L1.33073 0.308594Z"
                            fill="white"
                          />
                        </svg>
                      </span>
                      Total Tokens Availale:
                    </p>
                    <p className="font-semibold text-secondary-dark text-[18px] leading-[19.5px] text-montserrat capitalize ml-4">
                      {walletDetails.myCredits.credits.toFixed(3)}
                    </p>
                  </div>
                  <div className="p-3 w-full  flex items-center justify-start border-1 border-solid border-[rgba(210,210,210,0.66)] mb-4">
                    <p className="flex items-center font-semibold text-secondary-light text-[18px]">
                      <span className="flex items-center justify-center bg-secondary-yellow border-[0.6px] border-solid border-[#ffd600] rounded-[2px] w-[20px] h-[20px] mr-3">
                        <svg
                          width="8"
                          height="12"
                          viewBox="0 0 8 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1.33073 0.308594H7.9974L7.33073 1.51494H5.1574C5.4774 1.86478 5.7174 2.27494 5.8574 2.72129H7.9974L7.33073 3.92764H5.9974C5.91478 4.67965 5.55375 5.38435 4.97289 5.92741C4.39203 6.47047 3.62541 6.82003 2.7974 6.91939V6.94351H2.33073L6.33073 11.1657H4.66406L0.664062 6.94351V5.73717H2.33073C3.50406 5.73717 4.4774 4.95304 4.6374 3.92764H0.664062L1.33073 2.72129H4.4374C4.06406 2.00955 3.26406 1.51494 2.33073 1.51494H0.664062L1.33073 0.308594Z"
                            fill="white"
                          />
                        </svg>
                      </span>
                      Amount to Pay:
                    </p>
                    <p className="font-semibold text-secondary-dark text-[18px] leading-[19.5px] text-montserrat capitalize ml-[52px]">
                      {amountToPay}
                    </p>
                  </div>

                  {inSufficientBalance && (
                    <div className="mt-6 mb-8 flex items-start">
                      <span className="mt-[3px] mr-1">
                        <svg
                          width="20"
                          height="18"
                          viewBox="0 0 20 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9.953 0C4.465 0 0 4.02193 0 8.96552C0 13.9091 4.486 17.931 10 17.931C15.514 17.931 20 13.9091 20 8.96552C20 4.02193 15.493 0 9.953 0ZM11 13.4483H9V11.6552H11V13.4483ZM11 9.86207H9V4.48276H11V9.86207Z"
                            fill="#FF3737"
                          />
                        </svg>
                      </span>
                      <p className=" text-secondary-light font-medium text-p1 leading-[22px] ">
                        Your account balance is insufficient to complete this
                        transaction.{" "}
                        <RouterLink
                          to={ROUTES.WALLET}
                          className="ml-2 font-semibold text-tertiary-dark underline"
                        >
                          Add Tokens
                        </RouterLink>
                      </p>
                    </div>
                  )}

                  {inSufficientBalance ? (
                    <div className="w-full flex justify-end ">
                      <button
                        disabled
                        className="w-[186px] disabled:opacity-[0.5] mt-5 h-[56px] font-semibold bg-secondary-dark text-primary-white text-[18px]"
                      >
                        Confirm
                      </button>
                    </div>
                  ) : (
                    <div className="w-full flex justify-end gap-3 mt-5">
                      <BtnSmallWhite
                        onClick={() => setCurrentStep(2)}
                        // className="w-[186px] h-[56px] font-semibold bg-primary-dark text-primary-white text-[18px]"
                      >
                        Back
                      </BtnSmallWhite>
                      <div className="relative">
                        <button
                          onClick={() => handlePay()}
                          className="w-[186px] h-[56px] font-semibold bg-primary-dark text-primary-white text-[18px]"
                        >
                          Confirm
                        </button>
                        {modals.step3 && (
                          <GeneralLoader width={5} radius={30} dark={false} />
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </>
          ) : (
            <>
              <h2 className="text-heading font-semibold text-left mb-4">
                OTP has been sent for User Authentication
              </h2>
              <p className="text-secondary-light text-h5 text-justify mb-8">
                One Time Password had been sent to your Mobile no. and Email
                Address. Please enter the verification code.
              </p>
              <div className="flex items-center mb-8">
                {otps.map((element, index) => {
                  return (
                    <input
                      id={"input_" + index}
                      type="text"
                      key={index}
                      onChange={(e) => handleChangeOtp(e, index)}
                      value={element}
                      name="otp"
                      disabled={modals.step4}
                      maxLength="1"
                      onFocus={(e) => e.target.select()}
                      autoFocus={index === 0}
                      className="otpInput font-semibold text-heading text-h1 text-center mr-[19px] rounded-lg bg-primary-white w-[61.16px] h-[54px] border-1 border-solid border-[rgba(239, 239, 238, 0.9)] shadow-[0_3px_4px_3px_rgba(99,99,99,0.11)]"
                    />
                  );
                })}
              </div>

              <p className="mb-6 text-left text-heading-false font-semibold text-p1">
                Didn't receive the OTP?{" "}
                <span className="text-secondary-light underline">
                  <Timeout handleClick={() => handlePay()} start={60} />
                </span>
              </p>
              {!(otps.length > 0) ? (
                <div className="flex justify-end items-center">
                  <BtnSmallDisabled>Submit</BtnSmallDisabled>
                </div>
              ) : (
                <div className="flex justify-end ">
                  <div className="relative">
                    <BtnSmall onClick={() => handleVerifyOtp()}>
                      Submit
                    </BtnSmall>
                    {modals.step4 && (
                      <GeneralLoader width={5} radius={30} dark={false} />
                    )}
                  </div>
                </div>
              )}
            </>
          );
        case 4:
          return (
            <>
              <div className="flex flex-col justify-center items-center overflow-y-auto">
                <div className="w-[90%] h-[200rem] flex flex-col justify-center items-center border border-solid border-[#70296317] px-9 py-6 mt-[45rem]">
                  <p className="font-bold font-cabin text-[20px] text-left w-full mt-10">
                    Enter Set Details
                  </p>

                  <form className="flex flex-col justify-start items-start w-full mt-9">
                    <div className="flex justify-between w-full">
                      <div className="flex flex-col ">
                        <label className="text-p1 font-semibold text-secondary-dark -mb-2">
                          Set Name*
                        </label>
                        <input
                          type="text"
                          name="setName"
                          placeholder="Enter set name"
                          value={metaData.setName}
                          className="pl-2 mt-4 outline-primary-light font-semibold w-64 h-12 bg-primary-white border-1 border-primary-light border-solid"
                          onChange={handleChange}
                        />
                      </div>

                      <div className="flex flex-col">
                        <label className="text-p1 font-semibold text-secondary-dark -mb-2">
                          Set validity*
                        </label>
                        <input
                          type="number"
                          name="validity"
                          value={metaData.validity}
                          className="pl-2 mt-4 outline-primary-light text-[#8692A6C9] font-semibold w-64 h-12 bg-input border-1 border-primary-light border-solid"
                          // onChange={handleChange}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="flex flex-col my-3 mt-10 w-full">
                      <label className="text-p1 font-semibold text-secondary-dark -mb-2">
                        No. of pages* (For all the books within the set)
                      </label>
                      <input
                        type="number"
                        name="validity"
                        value={metaData.numOfPages}
                        className="pl-2 w-full mt-4 outline-primary-light text-[#8692A6C9] font-semibold min-w-[600px] h-12 bg-input border-1 border-primary-light border-solid"
                        // onChange={handleChange}
                        disabled
                      />
                    </div>
                    <div className="flex flex-col my-3 oveflow-hidden overflow-x-scroll w-full scrollbar">
                      <h2 className="text-p1 font-semibold text-secondary-dark -mb-2">
                        Category*
                      </h2>
                      <div className="relative overflow-hidden scrollbar w-full flex items-center bg-input h-[52px] mt-2 border-[1.5px] border-solid border-primary-dark-10 mb-6">
                        {metaData?.category?.map((item, index) => (
                          <div
                            key={item.id}
                            className="flex justify-between px-1 items-center relative ml-3 bg-[#939CECC7] w-[122px] h-[38px]"
                          >
                            <p className="text-center text-primary-white whitespace-nowrap overflow-hidden text-ellipsis">
                              {item.bookCategoryName}
                            </p>
                            <button
                              onClick={(e) => {
                                e.preventDefault();
                                metaData.category.splice(index, 1);
                              }}
                              className="mx-1"
                            >
                              <svg
                                width="19"
                                height="17"
                                viewBox="0 0 19 17"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M12.6954 11.7597C12.5042 11.911 12.2448 11.996 11.9744 11.996C11.7039 11.996 11.4445 11.911 11.2533 11.7597L9.00043 9.72282L6.74757 11.7591C6.65316 11.835 6.54068 11.8953 6.41663 11.9367C6.29257 11.978 6.1594 11.9996 6.02478 12C5.89016 12.0004 5.75677 11.9798 5.63229 11.9392C5.50782 11.8987 5.39472 11.839 5.29954 11.7637C5.20435 11.6884 5.12895 11.599 5.07768 11.5005C5.02642 11.402 5.00031 11.2965 5.00086 11.19C5.00141 11.0835 5.0286 10.9782 5.08088 10.88C5.13315 10.7819 5.20947 10.6929 5.30543 10.6183L7.64922 8.50067L5.30458 6.38175C5.20862 6.30706 5.1323 6.21809 5.08003 6.11995C5.02775 6.02182 5.00056 5.91647 5.00001 5.80998C4.99946 5.70349 5.02557 5.59797 5.07683 5.49951C5.1281 5.40104 5.2035 5.31158 5.29869 5.23628C5.39388 5.16098 5.50697 5.10133 5.63144 5.06078C5.75592 5.02023 5.88931 4.99957 6.02393 5.00001C6.15855 5.00044 6.29172 5.02195 6.41578 5.06331C6.53983 5.10466 6.65231 5.16503 6.74672 5.24094L9.00043 7.27852L11.2533 5.24094C11.3477 5.16503 11.4602 5.10466 11.5842 5.06331C11.7083 5.02195 11.8415 5.00044 11.9761 5.00001C12.1107 4.99957 12.2441 5.02023 12.3686 5.06078C12.493 5.10133 12.6061 5.16098 12.7013 5.23628C12.7965 5.31158 12.8719 5.40104 12.9232 5.49951C12.9744 5.59797 13.0005 5.70349 13 5.80998C12.9994 5.91647 12.9722 6.02182 12.92 6.11995C12.8677 6.21809 12.7914 6.30706 12.6954 6.38175L10.3516 8.50067L12.6954 10.6183C12.7902 10.6932 12.8655 10.7821 12.9168 10.8801C12.9681 10.978 12.9945 11.083 12.9945 11.189C12.9945 11.295 12.9681 11.4 12.9168 11.4979C12.8655 11.5958 12.7902 11.6848 12.6954 11.7597Z"
                                  fill="white"
                                  fill-opacity="0.9"
                                />
                              </svg>
                            </button>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="flex flex-col my-3 oveflow-hidden overflow-x-scroll w-full scrollbar">
                      <h2 className="text-p1 font-semibold text-secondary-dark -mb-2">
                        Authors*
                      </h2>
                      <div className="relative overflow-hidden scrollbar w-full flex items-center bg-input h-[52px] mt-2 border-[1.5px] border-solid border-primary-dark-10 mb-6">
                        {metaData?.authors?.map((item, index) => (
                          <div
                            key={item.id}
                            className="flex justify-between px-1 items-center relative ml-3 bg-[#939CECC7] w-[122px] h-[38px]"
                          >
                            <p className="text-center text-primary-white whitespace-nowrap overflow-hidden text-ellipsis">
                              {item.bookAuthorName}
                            </p>
                            <button
                              onClick={(e) => {
                                e.preventDefault();
                                metaData.authors.splice(index, 1);
                              }}
                              className="mx-1"
                            >
                              <svg
                                width="19"
                                height="17"
                                viewBox="0 0 19 17"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M12.6954 11.7597C12.5042 11.911 12.2448 11.996 11.9744 11.996C11.7039 11.996 11.4445 11.911 11.2533 11.7597L9.00043 9.72282L6.74757 11.7591C6.65316 11.835 6.54068 11.8953 6.41663 11.9367C6.29257 11.978 6.1594 11.9996 6.02478 12C5.89016 12.0004 5.75677 11.9798 5.63229 11.9392C5.50782 11.8987 5.39472 11.839 5.29954 11.7637C5.20435 11.6884 5.12895 11.599 5.07768 11.5005C5.02642 11.402 5.00031 11.2965 5.00086 11.19C5.00141 11.0835 5.0286 10.9782 5.08088 10.88C5.13315 10.7819 5.20947 10.6929 5.30543 10.6183L7.64922 8.50067L5.30458 6.38175C5.20862 6.30706 5.1323 6.21809 5.08003 6.11995C5.02775 6.02182 5.00056 5.91647 5.00001 5.80998C4.99946 5.70349 5.02557 5.59797 5.07683 5.49951C5.1281 5.40104 5.2035 5.31158 5.29869 5.23628C5.39388 5.16098 5.50697 5.10133 5.63144 5.06078C5.75592 5.02023 5.88931 4.99957 6.02393 5.00001C6.15855 5.00044 6.29172 5.02195 6.41578 5.06331C6.53983 5.10466 6.65231 5.16503 6.74672 5.24094L9.00043 7.27852L11.2533 5.24094C11.3477 5.16503 11.4602 5.10466 11.5842 5.06331C11.7083 5.02195 11.8415 5.00044 11.9761 5.00001C12.1107 4.99957 12.2441 5.02023 12.3686 5.06078C12.493 5.10133 12.6061 5.16098 12.7013 5.23628C12.7965 5.31158 12.8719 5.40104 12.9232 5.49951C12.9744 5.59797 13.0005 5.70349 13 5.80998C12.9994 5.91647 12.9722 6.02182 12.92 6.11995C12.8677 6.21809 12.7914 6.30706 12.6954 6.38175L10.3516 8.50067L12.6954 10.6183C12.7902 10.6932 12.8655 10.7821 12.9168 10.8801C12.9681 10.978 12.9945 11.083 12.9945 11.189C12.9945 11.295 12.9681 11.4 12.9168 11.4979C12.8655 11.5958 12.7902 11.6848 12.6954 11.7597Z"
                                  fill="white"
                                  fill-opacity="0.9"
                                />
                              </svg>
                            </button>
                          </div>
                        ))}
                      </div>
                    </div>

                    <div className="flex flex-col my-3 w-full overflow-hidden">
                      <h2 className="text-p1 font-semibold text-secondary-dark -mb-2">
                        Publications*
                      </h2>
                      <div className="relative oveflow-hidden overflow-x-scroll scrollbar w-full flex items-center  bg-input h-[52px] mt-2 border-[1.5px] border-solid border-primary-dark-10 mb-6">
                        {metaData?.publications?.map((item, index) => (
                          <div
                            key={item.id}
                            className="flex justify-between px-1 items-center relative ml-3 bg-[#939CECC7] w-[122px] h-[38px]"
                          >
                            <p className="text-center text-primary-white whitespace-nowrap overflow-hidden text-ellipsis">
                              {item.bookPublicationName}
                            </p>
                            <button
                              onClick={(e) => {
                                e.preventDefault();
                                metaData.publications.splice(index, 1);
                              }}
                              className="mx-1"
                            >
                              <svg
                                width="19"
                                height="17"
                                viewBox="0 0 19 17"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M12.6954 11.7597C12.5042 11.911 12.2448 11.996 11.9744 11.996C11.7039 11.996 11.4445 11.911 11.2533 11.7597L9.00043 9.72282L6.74757 11.7591C6.65316 11.835 6.54068 11.8953 6.41663 11.9367C6.29257 11.978 6.1594 11.9996 6.02478 12C5.89016 12.0004 5.75677 11.9798 5.63229 11.9392C5.50782 11.8987 5.39472 11.839 5.29954 11.7637C5.20435 11.6884 5.12895 11.599 5.07768 11.5005C5.02642 11.402 5.00031 11.2965 5.00086 11.19C5.00141 11.0835 5.0286 10.9782 5.08088 10.88C5.13315 10.7819 5.20947 10.6929 5.30543 10.6183L7.64922 8.50067L5.30458 6.38175C5.20862 6.30706 5.1323 6.21809 5.08003 6.11995C5.02775 6.02182 5.00056 5.91647 5.00001 5.80998C4.99946 5.70349 5.02557 5.59797 5.07683 5.49951C5.1281 5.40104 5.2035 5.31158 5.29869 5.23628C5.39388 5.16098 5.50697 5.10133 5.63144 5.06078C5.75592 5.02023 5.88931 4.99957 6.02393 5.00001C6.15855 5.00044 6.29172 5.02195 6.41578 5.06331C6.53983 5.10466 6.65231 5.16503 6.74672 5.24094L9.00043 7.27852L11.2533 5.24094C11.3477 5.16503 11.4602 5.10466 11.5842 5.06331C11.7083 5.02195 11.8415 5.00044 11.9761 5.00001C12.1107 4.99957 12.2441 5.02023 12.3686 5.06078C12.493 5.10133 12.6061 5.16098 12.7013 5.23628C12.7965 5.31158 12.8719 5.40104 12.9232 5.49951C12.9744 5.59797 13.0005 5.70349 13 5.80998C12.9994 5.91647 12.9722 6.02182 12.92 6.11995C12.8677 6.21809 12.7914 6.30706 12.6954 6.38175L10.3516 8.50067L12.6954 10.6183C12.7902 10.6932 12.8655 10.7821 12.9168 10.8801C12.9681 10.978 12.9945 11.083 12.9945 11.189C12.9945 11.295 12.9681 11.4 12.9168 11.4979C12.8655 11.5958 12.7902 11.6848 12.6954 11.7597Z"
                                  fill="white"
                                  fill-opacity="0.9"
                                />
                              </svg>
                            </button>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="flex flex-col my-3 w-full">
                      <h2 className="text-p1 font-semibold text-secondary-dark -mb-2">
                        Set Description
                      </h2>

                      <textarea
                        placeholder="Enter Set Description"
                        name="description"
                        value={metaData.description}
                        onChange={handleChange}
                        maxLength="250"
                        className="flex items-center justify-start text-[#8692A6C9] pl-2 mt-4 font-semibold w-full min-w-[600px] h-[128px] bg-input border-1 border-primary-light border-solid"
                      />
                    </div>
                    <label
                      className="text-heading-false font-semibold"
                      data-tip=" Both front and back cover images are required"
                    >
                      Upload a Set Cover
                      <span className="text-tertiary-dark">*</span>
                    </label>
                    {metaData.bookCover.length === 0 ? (
                      <div
                        onDragOver={dragOver}
                        onDragEnter={dragEnter}
                        onDragLeave={dragLeave}
                        onDrop={(e) => fileDrop(e, 0)}
                        className="flex justify-center flex-col items-center bg-[#F3EBF2] w-full h-[78px] border-[1.5px] border-dashed border-primary-dark-20 mt-2 mb-10"
                      >
                        <p className="text-secondary-dark text-h5">
                          Drag &#38; drop or{" "}
                          <label
                            htmlFor="book_cover"
                            className="underline text-p1 text-tertiary-dark cursor-pointer"
                          >
                            Browse
                          </label>
                          <p className="text-secondary-light text-p1">
                            Only Png/Jpg Files Supported
                          </p>
                        </p>
                        <input
                          onChange={(e) => handleBookCoverUpload(e, 0)}
                          id="book_cover"
                          type="file"
                          className="hidden "
                        />
                      </div>
                    ) : (
                      <div className="h-[133px] mt-4 mb-2 flex">
                        {metaData.bookCover.map((cover, i) => (
                          <div
                            onDragOver={dragOver}
                            onDragEnter={dragEnter}
                            onDragLeave={dragLeave}
                            onDrop={(e) => fileDrop(e, i)}
                            key={i + 90}
                            className="relative w-[103px] mr-3"
                          >
                            <img
                              className="h-full w-full block"
                              src={URL.createObjectURL(cover)}
                              alt="book cover"
                              onLoad={onImageLoad}
                            />
                            <div className="h-full absolute top-0 left-0  bg-[#7029635C] flex items-center justify-center w-full ">
                              <label htmlFor={i}>
                                <svg
                                  width="60"
                                  height="44"
                                  viewBox="0 0 60 44"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M30 16.2C28.8181 16.2 27.6478 16.4095 26.5558 16.8166C25.4639 17.2236 24.4718 17.8203 23.636 18.5724C22.8003 19.3246 22.1374 20.2175 21.6851 21.2003C21.2328 22.183 21 23.2363 21 24.3C21 25.3637 21.2328 26.417 21.6851 27.3997C22.1374 28.3825 22.8003 29.2754 23.636 30.0276C24.4718 30.7797 25.4639 31.3764 26.5558 31.7834C27.6478 32.1905 28.8181 32.4 30 32.4C32.3869 32.4 34.6761 31.5466 36.364 30.0276C38.0518 28.5085 39 26.4483 39 24.3C39 22.1517 38.0518 20.0915 36.364 18.5724C34.6761 17.0534 32.3869 16.2 30 16.2ZM54 8.1H46.8C46.2818 8.07873 45.7818 7.92177 45.3613 7.64838C44.9409 7.37499 44.6182 6.99707 44.433 6.561L42.57 1.5363C42.3835 1.10071 42.06 0.723462 41.6391 0.450634C41.2182 0.177806 40.7182 0.0212171 40.2 0H19.8C18.81 0 17.742 0.6912 17.433 1.5336L15.567 6.561C15.3815 6.99688 15.0587 7.37461 14.6383 7.64795C14.2179 7.92129 13.7181 8.07839 13.2 8.1H6C2.7 8.1 0 10.53 0 13.5V37.8C0 40.77 2.7 43.2 6 43.2H54C57.3 43.2 60 40.77 60 37.8V13.5C60 10.53 57.3 8.1 54 8.1ZM30 37.8C26.0218 37.8 22.2064 36.3777 19.3934 33.8459C16.5804 31.3142 15 27.8804 15 24.3C15 20.7196 16.5804 17.2858 19.3934 14.7541C22.2064 12.2223 26.0218 10.8 30 10.8C33.9782 10.8 37.7936 12.2223 40.6066 14.7541C43.4196 17.2858 45 20.7196 45 24.3C45 27.8804 43.4196 31.3142 40.6066 33.8459C37.7936 36.3777 33.9782 37.8 30 37.8ZM52.5 16.74C51.943 16.74 51.4089 16.5409 51.0151 16.1864C50.6213 15.832 50.4 15.3513 50.4 14.85C50.4 14.3487 50.6213 13.868 51.0151 13.5136C51.4089 13.1591 51.943 12.96 52.5 12.96C53.057 12.96 53.5911 13.1591 53.9849 13.5136C54.3787 13.868 54.6 14.3487 54.6 14.85C54.6 15.3513 54.3787 15.832 53.9849 16.1864C53.5911 16.5409 53.057 16.74 52.5 16.74Z"
                                    fill="#F3EBF2"
                                    fill-opacity="0.87"
                                  />
                                </svg>
                              </label>
                              <input
                                id={i}
                                onChange={(e) =>
                                  setMetaData((prevState) => {
                                    prevState.bookCover[i] = e.target.files[0];
                                    return { ...prevState };
                                  })
                                }
                                type="file"
                                className="hidden"
                              />
                            </div>
                          </div>
                        ))}
                        {metaData.bookCover.length === 1 && (
                          <div
                            onDragOver={dragOver}
                            onDragEnter={dragEnter}
                            onDragLeave={dragLeave}
                            onDrop={(e) => fileDrop(e, 1)}
                            className="shadow-[0_4px_5px_1px_rgba(173, 173, 173, 0.1)] bg-[#F3EBF2] border-[1.5px] border-dashed border-primary-light flex items-center justify-center w-[103px] mr-3"
                          >
                            <label htmlFor="back_cover">
                              <svg
                                width="60"
                                height="44"
                                viewBox="0 0 60 44"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M30 16.2C28.8181 16.2 27.6478 16.4095 26.5558 16.8166C25.4639 17.2236 24.4718 17.8203 23.636 18.5724C22.8003 19.3246 22.1374 20.2175 21.6851 21.2003C21.2328 22.183 21 23.2363 21 24.3C21 25.3637 21.2328 26.417 21.6851 27.3997C22.1374 28.3825 22.8003 29.2754 23.636 30.0276C24.4718 30.7797 25.4639 31.3764 26.5558 31.7834C27.6478 32.1905 28.8181 32.4 30 32.4C32.3869 32.4 34.6761 31.5466 36.364 30.0276C38.0518 28.5085 39 26.4483 39 24.3C39 22.1517 38.0518 20.0915 36.364 18.5724C34.6761 17.0534 32.3869 16.2 30 16.2ZM54 8.1H46.8C46.2818 8.07873 45.7818 7.92177 45.3613 7.64838C44.9409 7.37499 44.6182 6.99707 44.433 6.561L42.57 1.5363C42.3835 1.10071 42.06 0.723462 41.6391 0.450634C41.2182 0.177806 40.7182 0.0212171 40.2 0H19.8C18.81 0 17.742 0.6912 17.433 1.5336L15.567 6.561C15.3815 6.99688 15.0587 7.37461 14.6383 7.64795C14.2179 7.92129 13.7181 8.07839 13.2 8.1H6C2.7 8.1 0 10.53 0 13.5V37.8C0 40.77 2.7 43.2 6 43.2H54C57.3 43.2 60 40.77 60 37.8V13.5C60 10.53 57.3 8.1 54 8.1ZM30 37.8C26.0218 37.8 22.2064 36.3777 19.3934 33.8459C16.5804 31.3142 15 27.8804 15 24.3C15 20.7196 16.5804 17.2858 19.3934 14.7541C22.2064 12.2223 26.0218 10.8 30 10.8C33.9782 10.8 37.7936 12.2223 40.6066 14.7541C43.4196 17.2858 45 20.7196 45 24.3C45 27.8804 43.4196 31.3142 40.6066 33.8459C37.7936 36.3777 33.9782 37.8 30 37.8ZM52.5 16.74C51.943 16.74 51.4089 16.5409 51.0151 16.1864C50.6213 15.832 50.4 15.3513 50.4 14.85C50.4 14.3487 50.6213 13.868 51.0151 13.5136C51.4089 13.1591 51.943 12.96 52.5 12.96C53.057 12.96 53.5911 13.1591 53.9849 13.5136C54.3787 13.868 54.6 14.3487 54.6 14.85C54.6 15.3513 54.3787 15.832 53.9849 16.1864C53.5911 16.5409 53.057 16.74 52.5 16.74Z"
                                  fill="#702963"
                                  fillOpacity="0.17"
                                />
                              </svg>
                            </label>
                            <input
                              id="back_cover"
                              onChange={(e) =>
                                setMetaData((prevState) => {
                                  prevState.bookCover[1] = e.target.files[0];
                                  return { ...prevState };
                                })
                              }
                              type="file"
                              className="hidden"
                            />
                          </div>
                        )}
                      </div>
                    )}
                    <div className="flex justify-end w-full mt-16">
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          metaData.setName !== ""
                            ? setCurrentStep(5)
                            : notify("Set Name field is empty!");
                        }}
                        // type="submit"
                        // disabled={metaData.setName === ""}
                        className={`w-[186px] h-[56px] font-semibold bg-primary-dark text-primary-white text-[18px] ${
                          metaData.setName === ""
                            ? "cursor-not-allowed"
                            : "pointer"
                        }`}
                      >
                        Next
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </>
          );
        case 5:
          return (
            <>
              <div className="flex flex-col justify-center items-center overflow-y-auto">
                <div className="w-[90%] h-[200rem] flex flex-col justify-center items-center border border-solid border-[#70296317] px-9 py-6 mt-[65rem]">
                  {/* <p className="font-bold text-[20px] text-left w-full mb-9">
                    Summary
                  </p>
                  <p className="text-[#18181880] font-medium">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. At
                    aliquet ut facilisi nulla mauris iopsum. Pipsum dolor sit
                    ametre, consectetur adipiscing elit. At aliquet ut facilisi
                    nulla mauris ut facilisi nulla mauris iopsu At aliquet ut
                    facilisi nulla mauris iopsum. Pipsum dolo sit ametre,
                    consectetur adipiscing elit. mauris iopsu At aliquet ut
                    facilisi nulla mauris iopsum. Pipsum dolo sit ametre,
                    consectetur adipiscing elit.
                  </p> */}

                  {/* <div className="flex flex-col items-start font-medium w-full my-10">
                    <span className="flex items-center">
                      <p className="text-[#18181880] mr-32">No. of pages</p>
                      <p>{metaData.numOfPages}</p>
                    </span>
                    <span className="flex items-center mt-4">
                      <p className="text-[#18181880] mr-24">Validity package</p>
                      <p>{metaData.validity} Year</p>
                    </span>
                  </div> */}

                  <div className="flex flex-col justify-start items-start w-full mt-9">
                    <p className="font-bold text-[20px] text-left w-full">
                      Set details
                    </p>
                    <div className="flex justify-between w-full mt-7">
                      <div className="flex flex-col ">
                        <h2 className="text-p1 font-semibold text-secondary-dark -mb-2">
                          Set Name*
                        </h2>
                        <p className="mt-4 text-[#8692A6] font-semibold w-64 h-12 bg-primary-white">
                          {metaData.setName}
                        </p>
                      </div>

                      <div className="flex flex-col">
                        <h2 className="text-p1 font-semibold text-secondary-dark -mb-2">
                          Set validity*
                        </h2>
                        <p className="mt-4 text-[#8692A6] font-semibold w-64 h-12">
                          {metaData.validity} years
                        </p>
                      </div>
                    </div>
                    <div className="flex justify-between w-full mt-7">
                      <div className="flex flex-col ">
                        <h2 className="text-p1 font-semibold text-secondary-dark -mb-2">
                          No. of pages*
                        </h2>
                        <p className="mt-4 text-[#8692A6] font-semibold w-64 h-12 bg-primary-white">
                          {metaData.numOfPages}
                        </p>
                      </div>

                      {/* <div className="flex flex-col">
                        <h2 className="text-p1 font-semibold text-secondary-dark -mb-2">
                        Validity package*
                        </h2>
                        <p className="mt-4 text-[#8692A6] font-semibold w-64 h-12">
                        {metaData.validity} Year
                        </p>
                      </div> */}
                    </div>
                    <div className="flex flex-col justify-between w-full my-3">
                      <div className="flex flex-col">
                        <h2 className="text-p1 font-semibold text-secondary-dark -mb-2">
                          Category*
                        </h2>
                        <div className="flex items-center justify-start text-[#8692A6] mt-4 font-semibold w-full flex-wrap h-12">
                          {metaData?.category?.map((item) => (
                            <div
                              key={item.id}
                              className="flex justify-between px-1 items-center relative ml-3 bg-[#939CECC7] w-[122px] min-h-[38px] h-fit"
                            >
                              <p className="text-center text-primary-white whitespace-nowrap overflow-hidden text-ellipsis">
                                {item.bookCategoryName}
                              </p>
                              {/* <button className="mx-1">
                                <svg
                                  width="19"
                                  height="17"
                                  viewBox="0 0 19 17"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M12.6954 11.7597C12.5042 11.911 12.2448 11.996 11.9744 11.996C11.7039 11.996 11.4445 11.911 11.2533 11.7597L9.00043 9.72282L6.74757 11.7591C6.65316 11.835 6.54068 11.8953 6.41663 11.9367C6.29257 11.978 6.1594 11.9996 6.02478 12C5.89016 12.0004 5.75677 11.9798 5.63229 11.9392C5.50782 11.8987 5.39472 11.839 5.29954 11.7637C5.20435 11.6884 5.12895 11.599 5.07768 11.5005C5.02642 11.402 5.00031 11.2965 5.00086 11.19C5.00141 11.0835 5.0286 10.9782 5.08088 10.88C5.13315 10.7819 5.20947 10.6929 5.30543 10.6183L7.64922 8.50067L5.30458 6.38175C5.20862 6.30706 5.1323 6.21809 5.08003 6.11995C5.02775 6.02182 5.00056 5.91647 5.00001 5.80998C4.99946 5.70349 5.02557 5.59797 5.07683 5.49951C5.1281 5.40104 5.2035 5.31158 5.29869 5.23628C5.39388 5.16098 5.50697 5.10133 5.63144 5.06078C5.75592 5.02023 5.88931 4.99957 6.02393 5.00001C6.15855 5.00044 6.29172 5.02195 6.41578 5.06331C6.53983 5.10466 6.65231 5.16503 6.74672 5.24094L9.00043 7.27852L11.2533 5.24094C11.3477 5.16503 11.4602 5.10466 11.5842 5.06331C11.7083 5.02195 11.8415 5.00044 11.9761 5.00001C12.1107 4.99957 12.2441 5.02023 12.3686 5.06078C12.493 5.10133 12.6061 5.16098 12.7013 5.23628C12.7965 5.31158 12.8719 5.40104 12.9232 5.49951C12.9744 5.59797 13.0005 5.70349 13 5.80998C12.9994 5.91647 12.9722 6.02182 12.92 6.11995C12.8677 6.21809 12.7914 6.30706 12.6954 6.38175L10.3516 8.50067L12.6954 10.6183C12.7902 10.6932 12.8655 10.7821 12.9168 10.8801C12.9681 10.978 12.9945 11.083 12.9945 11.189C12.9945 11.295 12.9681 11.4 12.9168 11.4979C12.8655 11.5958 12.7902 11.6848 12.6954 11.7597Z"
                                    fill="white"
                                    fill-opacity="0.9"
                                  />
                                </svg>
                              </button> */}
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="flex flex-col">
                        <h2 className="text-p1 font-semibold text-secondary-dark -mb-2">
                          Authors*
                        </h2>
                        <div className="flex items-center justify-start text-[#8692A6] mt-4 font-semibold w-full flex-wrap h-12">
                          {metaData?.authors.map((item) => (
                            <div
                              key={item.id}
                              className="flex justify-between px-1 items-center relative ml-3 bg-[#939CECC7] w-[122px] h-fit min-h-[38px]"
                            >
                              <p className="text-center text-primary-white whitespace-nowrap overflow-hidden text-ellipsis">
                                {item.bookAuthorName}
                              </p>
                              {/* <button className="mx-1">
                                <svg
                                  width="19"
                                  height="17"
                                  viewBox="0 0 19 17"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M12.6954 11.7597C12.5042 11.911 12.2448 11.996 11.9744 11.996C11.7039 11.996 11.4445 11.911 11.2533 11.7597L9.00043 9.72282L6.74757 11.7591C6.65316 11.835 6.54068 11.8953 6.41663 11.9367C6.29257 11.978 6.1594 11.9996 6.02478 12C5.89016 12.0004 5.75677 11.9798 5.63229 11.9392C5.50782 11.8987 5.39472 11.839 5.29954 11.7637C5.20435 11.6884 5.12895 11.599 5.07768 11.5005C5.02642 11.402 5.00031 11.2965 5.00086 11.19C5.00141 11.0835 5.0286 10.9782 5.08088 10.88C5.13315 10.7819 5.20947 10.6929 5.30543 10.6183L7.64922 8.50067L5.30458 6.38175C5.20862 6.30706 5.1323 6.21809 5.08003 6.11995C5.02775 6.02182 5.00056 5.91647 5.00001 5.80998C4.99946 5.70349 5.02557 5.59797 5.07683 5.49951C5.1281 5.40104 5.2035 5.31158 5.29869 5.23628C5.39388 5.16098 5.50697 5.10133 5.63144 5.06078C5.75592 5.02023 5.88931 4.99957 6.02393 5.00001C6.15855 5.00044 6.29172 5.02195 6.41578 5.06331C6.53983 5.10466 6.65231 5.16503 6.74672 5.24094L9.00043 7.27852L11.2533 5.24094C11.3477 5.16503 11.4602 5.10466 11.5842 5.06331C11.7083 5.02195 11.8415 5.00044 11.9761 5.00001C12.1107 4.99957 12.2441 5.02023 12.3686 5.06078C12.493 5.10133 12.6061 5.16098 12.7013 5.23628C12.7965 5.31158 12.8719 5.40104 12.9232 5.49951C12.9744 5.59797 13.0005 5.70349 13 5.80998C12.9994 5.91647 12.9722 6.02182 12.92 6.11995C12.8677 6.21809 12.7914 6.30706 12.6954 6.38175L10.3516 8.50067L12.6954 10.6183C12.7902 10.6932 12.8655 10.7821 12.9168 10.8801C12.9681 10.978 12.9945 11.083 12.9945 11.189C12.9945 11.295 12.9681 11.4 12.9168 11.4979C12.8655 11.5958 12.7902 11.6848 12.6954 11.7597Z"
                                    fill="white"
                                    fill-opacity="0.9"
                                  />
                                </svg>
                              </button> */}
                            </div>
                          ))}
                        </div>
                      </div>

                      <div className="flex flex-col my-10">
                        <h2 className="text-p1 font-semibold text-secondary-dark -mb-2">
                          Publications*
                        </h2>
                        <div className="flex items-center justify-start text-[#8692A6] w-full flex-wrap mt-4 font-semibold w-full h-12">
                          {metaData?.publications.map((item) => (
                            <div
                              key={item.id}
                              className="flex justify-between px-1 items-center relative ml-3 bg-[#939CECC7] w-[122px] h-fit min-h-[38px] mt-2"
                            >
                              <p className="text-center text-primary-white whitespace-nowrap overflow-hidden text-ellipsis">
                                {item.bookPublicationName}
                              </p>
                              {/* <button className="mx-1">
                                <svg
                                  width="19"
                                  height="17"
                                  viewBox="0 0 19 17"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M12.6954 11.7597C12.5042 11.911 12.2448 11.996 11.9744 11.996C11.7039 11.996 11.4445 11.911 11.2533 11.7597L9.00043 9.72282L6.74757 11.7591C6.65316 11.835 6.54068 11.8953 6.41663 11.9367C6.29257 11.978 6.1594 11.9996 6.02478 12C5.89016 12.0004 5.75677 11.9798 5.63229 11.9392C5.50782 11.8987 5.39472 11.839 5.29954 11.7637C5.20435 11.6884 5.12895 11.599 5.07768 11.5005C5.02642 11.402 5.00031 11.2965 5.00086 11.19C5.00141 11.0835 5.0286 10.9782 5.08088 10.88C5.13315 10.7819 5.20947 10.6929 5.30543 10.6183L7.64922 8.50067L5.30458 6.38175C5.20862 6.30706 5.1323 6.21809 5.08003 6.11995C5.02775 6.02182 5.00056 5.91647 5.00001 5.80998C4.99946 5.70349 5.02557 5.59797 5.07683 5.49951C5.1281 5.40104 5.2035 5.31158 5.29869 5.23628C5.39388 5.16098 5.50697 5.10133 5.63144 5.06078C5.75592 5.02023 5.88931 4.99957 6.02393 5.00001C6.15855 5.00044 6.29172 5.02195 6.41578 5.06331C6.53983 5.10466 6.65231 5.16503 6.74672 5.24094L9.00043 7.27852L11.2533 5.24094C11.3477 5.16503 11.4602 5.10466 11.5842 5.06331C11.7083 5.02195 11.8415 5.00044 11.9761 5.00001C12.1107 4.99957 12.2441 5.02023 12.3686 5.06078C12.493 5.10133 12.6061 5.16098 12.7013 5.23628C12.7965 5.31158 12.8719 5.40104 12.9232 5.49951C12.9744 5.59797 13.0005 5.70349 13 5.80998C12.9994 5.91647 12.9722 6.02182 12.92 6.11995C12.8677 6.21809 12.7914 6.30706 12.6954 6.38175L10.3516 8.50067L12.6954 10.6183C12.7902 10.6932 12.8655 10.7821 12.9168 10.8801C12.9681 10.978 12.9945 11.083 12.9945 11.189C12.9945 11.295 12.9681 11.4 12.9168 11.4979C12.8655 11.5958 12.7902 11.6848 12.6954 11.7597Z"
                                    fill="white"
                                    fill-opacity="0.9"
                                  />
                                </svg>
                              </button> */}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col my-3">
                      <h2 className="text-p1 font-semibold text-secondary-dark -mb-2">
                        Set Description
                      </h2>
                      <div className="flex items-center justify-start text-[#8692A6] mt-4 font-semibold w-full">
                        <p>{metaData.description}</p>
                      </div>
                    </div>
                    <div className="flex flex-col my-3">
                      <h2 className="text-p1 font-semibold text-secondary-dark -mb-2">
                        Set Cover*
                      </h2>
                      {/* <div className="h-[133px] mt-4 mb-2 flex">
                        {metaData.bookCover.map((cover, i) => (
                          <div className="relative w-[103px] mr-3">
                            <img
                              className="h-full block"
                              src={URL.createObjectURL(cover)}
                              alt="book cover"
                              onLoad={onImageLoad}
                            />
                          </div>
                        ))}
                      </div> */}
                      <div className="h-[133px] mt-4 mb-2 flex">
                        {metaData.bookCover[0] && (
                          <div className="shadow-[0_4px_5px_1px_rgba(173, 173, 173, 0.1)] bg-[#F3EBF2] border-[1.5px] border-dashed border-primary-light flex items-center justify-center w-[103px] mr-3">
                            <img
                              className="object-cover h-full w-full"
                              src={URL.createObjectURL(metaData.bookCover[0])}
                              alt="front book cover"
                              onLoad={onImageLoad}
                            />
                          </div>
                        )}
                        {metaData.bookCover[1] && (
                          <div className="shadow-[0_4px_5px_1px_rgba(173, 173, 173, 0.1)] bg-[#F3EBF2] border-[1.5px] border-dashed border-primary-light flex items-center justify-center w-[103px] mr-3">
                            <img
                              className="object-cover h-full w-full"
                              src={URL.createObjectURL(metaData.bookCover[1])}
                              alt="book cover back"
                            />
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="flex flex-col mb-3 mt-10">
                      <h2 className="text-p1 font-semibold text-secondary-dark mb-10">
                        List of Books*
                      </h2>
                      <div className="flex justify-start flex-wrap gap-[10px] w-full items-center">
                        {selected.map((item) => {
                          return (
                            <div
                              key={item.id}
                              className="flex w-[246px] h-[134px] justify-center items-center bg-primary-extra-light mr-6 p-2"
                            >
                              <div
                                style={{ background: item.backgroundColor }}
                                className="w-[106px] h-full mr-[11px] bg-[#A93030] flex justify-center items-center py-4"
                              >
                                <img
                                  className="shadow-[5px_5px_13px_3px_rgba(0,0,0,0.5)] h-[95px] w-[75%] object-cover"
                                  src={`https://${process.env.REACT_APP_AWS_BUCKET_NAME}.s3.amazonaws.com/books/${item.imageCoverFront}`}
                                  alt="book"
                                />
                              </div>
                              <div className="w-[160px] font-semibold">
                                <h2 className="text-[303030] text-mobile-h5">
                                  {item.title}
                                </h2>
                                <h3 className="text-[#5F5D5D8F] text-mobile-p1">
                                  {item.Bauthors?.map((auther, index, arr) => (
                                    <span>
                                      {arr.indexOf(arr[index]) ===
                                      arr.length - 1
                                        ? auther.bookAuthorName
                                        : `${auther.bookAuthorName}, `}
                                    </span>
                                  ))}
                                </h3>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <div className="flex justify-end w-full mt-16">
                      <button
                        onClick={() => {
                          setModals((prevState) => ({
                            ...prevState,
                            uploadByBook: false
                          }));
                        }}
                        className="w-[186px] h-[56px] font-semibold bg-primary-white border bordr-solid border-[#CACACA] text-[18px]"
                      >
                        Cancel
                      </button>
                      <div className="relative ml-4">
                        <button
                          onClick={(e) => {
                            handleActivateSet(e);
                          }}
                          className="w-[186px] h-[56px]  font-semibold bg-primary-dark text-primary-white text-[18px]"
                        >
                          Activate
                        </button>
                        {modals.step5 && (
                          <GeneralLoader width={5} radius={30} dark={false} />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        default:
          return null;
      }
    });
  }, [
    currentStep,
    modals,
    books,
    searchString,
    selected,
    buttonLoader,
    modals,
    otpSent,
    otps,
    metaData
  ]);

  return (
    <Page active="wallet">
      <Navbar />
      {loader ? (
        <Loader show={loader} />
      ) : (
        <>
          <div className="flex p-4 lg:p-4 px-0">
            <div
              className={`${
                modals.expandedMenu ? "lg:w-expanded" : "w-full"
              } w-full`}
            >
              <div
                className={`flex justify-between items-center w-full px-4 lg:px-0 lg:mb-0 mb-[3.5rem]`}
              >
                <h1 className="flex items-center text-mobile-h1 sm:text-tablet-h1 lg:text-h1 text-tertiary-dark lg:text-heading text-left font-bold lg:font-semibold">
                  <span className=" lg:hidden mr-[18px]">
                    <svg
                      width="12"
                      height="20"
                      viewBox="0 0 12 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.0491 2.5473L9.4174 0.924805L0.351562 9.9998L9.42656 19.0748L11.0491 17.4523L3.59656 9.9998L11.0491 2.5473Z"
                        fill="#702963"
                      />
                    </svg>
                  </span>
                  Catalogue: Sets
                </h1>

                <div className="hidden lg:flex items-center">
                  {generateAccessIds ? (
                    <div>
                      <button
                        onClick={() => setGenerateAccessIds(false)}
                        className="mr-6 text-heading-false text-mobile-p1 sm:text-tablet-p1 lg:text-p1 font-[500] outline-0 border-0"
                      >
                        Cancel
                      </button>
                      {accessIdSelected ? (
                        <button
                          onClick={handleGenerate}
                          className="hover:border-primary-dark rounded-sm mr-10 font-semibold w-[225px] h-[54px] text-tertiary-dark text-mobile-h5 sm:text-tablet-h5 lg:text-h5 border-1 border-solid border-primary-dark-10"
                        >
                          Generate Access IDs
                        </button>
                      ) : (
                        <button
                          onClick={handleGenerate}
                          className="hover:border-primary-dark rounded-sm mr-10 font-semibold w-[225px] h-[54px] text-tertiary-dark text-mobile-h5 sm:text-tablet-h5 lg:text-h5 border-1 border-solid border-primary-dark-10"
                        >
                          Create Orders
                        </button>
                      )}
                    </div>
                  ) : (
                    <>
                      <button
                        onClick={() => {
                          // setAccessIdSelected(true);
                          // setGenerateAccessIds(true);
                          setModals((prevState) => ({
                            ...prevState,
                            generateIds: true
                          }));
                        }}
                        className="underline text-tertiary-dark font-semibold text-mobile-p1 sm:text-tablet-p1 lg:text-p1 mr-4"
                      >
                        Select Action
                      </button>
                      <div className="flex flex-col">
                        <button
                          onClick={() => {
                            setOtpSent(false);
                            setModals((prevState) => ({
                              ...prevState,
                              uploadByBook: true
                            }));
                            getStatus();
                          }}
                          className="flex justify-center items-center mr-10 lg:mr-0 bg-gradient-to-b from-[#702963] to-[#702963c2] w-[225px] h-[54px] text-primary-white text-mobile-p1 sm:text-tablet-p1 lg:text-p1 font-semibold rounded-sm"
                        >
                          <span className="mr-2">
                            <svg
                              width="25"
                              height="23"
                              viewBox="0 0 25 23"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M12.25 0C9.01323 0.0362614 5.92003 1.24634 3.63112 3.37175C1.34221 5.49717 0.0390507 8.36943 0 11.375C0.0390507 14.3806 1.34221 17.2528 3.63112 19.3782C5.92003 21.5037 9.01323 22.7137 12.25 22.75C15.4868 22.7137 18.58 21.5037 20.8689 19.3782C23.1578 17.2528 24.4609 14.3806 24.5 11.375C24.4609 8.36943 23.1578 5.49717 20.8689 3.37175C18.58 1.24634 15.4868 0.0362614 12.25 0ZM19.25 12.1875H13.125V17.875H11.375V12.1875H5.25V10.5625H11.375V4.875H13.125V10.5625H19.25V12.1875Z"
                                fill="white"
                              />
                            </svg>
                          </span>
                          <span>Create a New Set</span>
                        </button>
                        {/* {modals.status && <p className=" text-[14px] ml-4 text-secondary-light font-bold " >(Set creation in Progress....)</p>} */}
                      </div>
                    </>
                  )}
                </div>
              </div>
              {generateAccessIds && (
                <p className="text-[15px] text-secondary-light font-bold ">
                  Select Book for which you would like to{" "}
                  {accessIdSelected ? " Generate Access" : "Create orders"} Id’s
                </p>
              )}
              {/* <div className=" mt-8 mb-7 hidden items-center justify-between px-4 lg:px-0">
                <button
                    // onClick={() =>
                    //   setModals((prevState) => ({
                    //     ...prevState,
                    //     generateIds: true,
                    //   }))
                    // }
                    className="hidden text-h5 text-tertiary-dark font-bold underline"
                       >
                    Generate
                </button>
                {sort === -1 || !applySort ? (
                  <p></p>
                ) : (
                  <p className="font-semibold text-p1 leading-[19.4px] text-secondary-dark">
                    {sort === 0
                      ? "19 books expiring soon"
                      : sort === 1
                      ? "19 books have been expired"
                      : sort === 2
                      ? "12 books will be deactivated soon"
                      : "4 books have been deactivated"}
                  </p>
                )}
                <div>
                  <label className="text-secondary-light font-semibold mr-5">
                    Sort by
                  </label>
                  <button
                    onClick={() =>
                      setModals((prevState) => ({ ...prevState, sort: true }))
                    }
                    className="mr-1"
                  >
                    <svg
                      width="18"
                      height="16"
                      viewBox="0 0 18 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0 2C0 1.60218 0.158036 1.22064 0.43934 0.93934C0.720645 0.658036 1.10218 0.5 1.5 0.5H16.5C16.8978 0.5 17.2794 0.658036 17.5607 0.93934C17.842 1.22064 18 1.60218 18 2C18 2.39782 17.842 2.77936 17.5607 3.06066C17.2794 3.34196 16.8978 3.5 16.5 3.5H1.5C1.10218 3.5 0.720645 3.34196 0.43934 3.06066C0.158036 2.77936 0 2.39782 0 2Z"
                        fill="#702963"
                      />
                      <path
                        d="M3 8C3 7.60218 3.15804 7.22064 3.43934 6.93934C3.72064 6.65804 4.10218 6.5 4.5 6.5H13.5C13.8978 6.5 14.2794 6.65804 14.5607 6.93934C14.842 7.22064 15 7.60218 15 8C15 8.39782 14.842 8.77936 14.5607 9.06066C14.2794 9.34196 13.8978 9.5 13.5 9.5H4.5C4.10218 9.5 3.72064 9.34196 3.43934 9.06066C3.15804 8.77936 3 8.39782 3 8Z"
                        fill="#702963"
                      />
                      <path
                        d="M7.5 12.5C7.10218 12.5 6.72064 12.658 6.43934 12.9393C6.15804 13.2206 6 13.6022 6 14C6 14.3978 6.15804 14.7794 6.43934 15.0607C6.72064 15.342 7.10218 15.5 7.5 15.5H10.5C10.8978 15.5 11.2794 15.342 11.5607 15.0607C11.842 14.7794 12 14.3978 12 14C12 13.6022 11.842 13.2206 11.5607 12.9393C11.2794 12.658 10.8978 12.5 10.5 12.5H7.5Z"
                        fill="#702963"
                      />
                    </svg>
                  </button>
                </div>
              </div> */}
              <DialogBox show={modals.generateIds}>
                <div className="bottom-[60px] lg:bottom-0 bg-primary-white flex flex-col justify-between relative w-full lg:w-pop-up h-pop-up p-8">
                  <h4 className="text-h4 text-tertiary-dark font-bold">
                    Select One Option
                  </h4>
                  <p className="text-secondary-light">
                    Select one of the below options, would you like to
                  </p>
                  <div className="flex flex-col h-16 justify-between">
                    <div className="flex items-center flex-row gap-[10px]">
                      <label htmlFor="radio-1" className="custom_radio_btn">
                        <input
                          name="access_id"
                          id="radio-1"
                          type="radio"
                          checked={accessIdSelected}
                          onChange={handleIdType}
                          className="w-0 h-0 rounded-full bg-primary-dark mr-4"
                        />
                        <span
                          className={`absolute checkmark ${
                            accessIdSelected ? "inline-block" : "hidden"
                          }`}
                        ></span>
                      </label>

                      <span className="text-heading font-semibold text-h5">
                        Generate Access IDs
                      </span>
                    </div>
                    <div className="flex items-center flex-row gap-[10px]">
                      <label htmlFor="radio-2" className="custom_radio_btn">
                        <input
                          name="order_id"
                          type="radio"
                          id="radio-2"
                          checked={!accessIdSelected}
                          onChange={handleIdType}
                          className="w-0 h-0 rounded-full bg-primary-dark mr-4"
                        />
                        <span
                          className={`absolute checkmark ${
                            !accessIdSelected ? "inline-block" : "hidden"
                          }`}
                        ></span>
                      </label>
                      <span className="text-heading font-semibold text-h5">
                        Create Orders
                      </span>
                    </div>
                  </div>
                  <div className="text-right">
                    <BtnSmall
                      onClick={() => {
                        setModals((prevState) => ({
                          ...prevState,
                          generateIds: false
                        }));
                        setGenerateAccessIds(true);
                      }}
                    >
                      Select Sets
                    </BtnSmall>
                  </div>
                  <button
                    onClick={() =>
                      setModals((prevState) => ({
                        ...prevState,
                        generateIds: false
                      }))
                    }
                    className="absolute top-8 right-8"
                  >
                    <svg
                      width="12"
                      height="14"
                      viewBox="0 0 12 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.2176 12.8188C10.9475 13.0888 10.5813 13.2405 10.1994 13.2405C9.81747 13.2405 9.45122 13.0888 9.18117 12.8188L5.99997 9.18281L2.81877 12.8176C2.68545 12.9531 2.52663 13.0609 2.35145 13.1347C2.17628 13.2085 1.98823 13.2469 1.79814 13.2477C1.60805 13.2485 1.41969 13.2116 1.24392 13.1392C1.06815 13.0668 0.908454 12.9604 0.77404 12.8259C0.639626 12.6915 0.533155 12.5318 0.460769 12.3561C0.388382 12.1803 0.351512 11.9919 0.352286 11.8018C0.35306 11.6118 0.391462 11.4237 0.465278 11.2485C0.539094 11.0734 0.646862 10.9145 0.782367 10.7812L4.09197 7.00121L0.781167 3.21881C0.645662 3.0855 0.537894 2.92667 0.464078 2.7515C0.390263 2.57633 0.35186 2.38827 0.351086 2.19818C0.350312 2.00809 0.387182 1.81973 0.459569 1.64397C0.531955 1.4682 0.638426 1.3085 0.77284 1.17409C0.907255 1.03967 1.06695 0.933201 1.24272 0.860814C1.41849 0.788427 1.60685 0.751557 1.79694 0.752331C1.98703 0.753105 2.17508 0.791508 2.35025 0.865323C2.52543 0.939139 2.68425 1.04691 2.81757 1.18241L5.99997 4.81961L9.18117 1.18241C9.31448 1.04691 9.47331 0.939139 9.64848 0.865323C9.82365 0.791508 10.0117 0.753105 10.2018 0.752331C10.3919 0.751557 10.5802 0.788427 10.756 0.860814C10.9318 0.933201 11.0915 1.03967 11.2259 1.17409C11.3603 1.3085 11.4668 1.4682 11.5392 1.64397C11.6116 1.81973 11.6484 2.00809 11.6476 2.19818C11.6469 2.38827 11.6085 2.57633 11.5347 2.7515C11.4608 2.92667 11.3531 3.0855 11.2176 3.21881L7.90797 7.00121L11.2176 10.7812C11.3515 10.915 11.4577 11.0738 11.5301 11.2486C11.6026 11.4234 11.6399 11.6108 11.6399 11.8C11.6399 11.9893 11.6026 12.1766 11.5301 12.3514C11.4577 12.5263 11.3515 12.6851 11.2176 12.8188Z"
                        fill="black"
                        fillOpacity="0.53"
                      />
                    </svg>
                  </button>
                </div>
              </DialogBox>
              {expiringSoon.length > 0 && (
                <div className="flex lg:hidden border-1 mb-8 border-solid border-l-0 border-r-0 border-t-[#e7e7e7] border-b-[#e7e7e7]">
                  <button
                    onClick={() => setShowBooks(true)}
                    className={`w-1/2 h-[46px] ${
                      showBooks
                        ? "bg-primary-dark text-primary-white"
                        : "bg-primary-white text-tertiary-dark"
                    } font-bold text-[17px] text-center`}
                  >
                    Details
                  </button>
                  <button
                    onClick={() => setShowBooks(false)}
                    className={`w-1/2 h-[46px] ${
                      !showBooks
                        ? "bg-primary-dark text-primary-white"
                        : "bg-primary-white text-tertiary-dark"
                    } font-bold text-[17px] text-center`}
                  >
                    Book Expiry
                  </button>
                </div>
              )}

              {showBooks ? (
                <div>
                  <div className="w-full gap-8 lg:gap-12 my-8 mb-14 flex flex-col lg:flex-row lg:flex-wrap px-4">
                    {sets?.length > 0 ? (
                      sets.map((set, index) => {
                        return generateAccessIds ? (
                          <div
                            key={set.id}
                            onClick={() => handleSelectionSet(set)}
                          >
                            <div className="w-[260px] h-[351px]  border-1 border-solid border-primary-dark-20 scale-1 hover:scale-105 transition duration-600 hover:ease-in">
                              <div
                                style={{ background: set.backgroundColor }}
                                className="flex p-5 justify-center items-center w-[98px] h-full lg:w-full lg:h-[70%]   relative"
                              >
                                <img
                                  className="shadow-[5px_5px_13px_3px_rgba(0,0,0,0.5)]  w-[60%] object-contain"
                                  src={`https://${process.env.REACT_APP_AWS_BUCKET_NAME}.s3.amazonaws.com/setCovers/${set.cover}`}
                                  alt="front cover"
                                />
                                <div className="absolute top-2 right-2 rounded-full flex justify-center items-center bg-neutral-0 w-[69px] h-[65px]">
                                  <p className="text-mobile-sm sm:text-tablet-sm lg:text-sm text-center leading-tight text-secondary-dark">
                                    <span className="text-mobile-h3 sm:text-tablet-h3 lg:text-h3 text-tertiary-dark font-semibold">
                                      {set.validity}yrs
                                    </span>
                                    <br />
                                    Validity
                                  </p>
                                </div>
                                {checkSelectedSet(set.id) && (
                                  <div className="absolute bottom-4 right-4 bg-primary-dark flex justify-center items-center rounded-full w-6 h-6">
                                    <svg
                                      width="15"
                                      height="10"
                                      viewBox="0 0 15 10"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M0.313241 3.75601C0.51387 3.57208 0.785945 3.46875 1.06963 3.46875C1.35332 3.46875 1.6254 3.57208 1.82603 3.75601L5.60906 7.22533L13.173 0.287674C13.2723 0.196515 13.3903 0.124192 13.5201 0.0748331C13.6499 0.025474 13.789 4.5617e-05 13.9295 6.13195e-08C14.0701 -4.54943e-05 14.2092 0.0252926 14.3391 0.0745675C14.4689 0.123842 14.5869 0.196089 14.6863 0.287183C14.7857 0.378277 14.8646 0.486433 14.9184 0.605478C14.9722 0.724522 14.9999 0.852123 15 0.980994C15 1.10987 14.9724 1.23748 14.9187 1.35656C14.865 1.47564 14.7862 1.58385 14.6868 1.67501L5.60906 10L0.313241 5.14335C0.112673 4.95936 0 4.70984 0 4.44968C0 4.18952 0.112673 3.94 0.313241 3.75601Z"
                                        fill="white"
                                      />
                                    </svg>
                                  </div>
                                )}
                              </div>
                              <div className="w-full h-[30%] p-2">
                                <p className="mt-1 text-h5 text-heading font-semibold text-left leading-none">
                                  {set?.title}
                                </p>
                                <div className="flex justify-between items-center mt-4">
                                  <p className="text-secondary-dark text-mobile-p1 sm:text-tablet-p1 lg:text-p1 font-semibold">
                                    {set?.Books?.length} books
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div
                            className="block"
                            onClick={() => navigate(`/sets/${set.id}`)}
                          >
                            <div
                              key={set.id}
                              className="w-full h-[109px] flex lg:block lg:w-[260px] lg:h-[351px] shadow-[4px_4px_4px_rgba(224,224,224,0.25)] rounded-sm border-1 border-solid border-primary-dark-20 scale-1 lg:hover:scale-105 lg:transition lg:duration-600 lg:hover:ease-in"
                            >
                              <div
                                style={{ background: set.backgroundColor }}
                                className={`flex justify-center items-center h-full w-[98px] lg:w-full lg:h-[70%]  relative`}
                              >
                                <img
                                  className="h-[85px] lg:h-[85%] object-cover shadow-[5px_5px_13px_3px_rgba(0,0,0,0.5)] "
                                  src={`https://${process.env.REACT_APP_AWS_BUCKET_NAME}.s3.amazonaws.com/setCovers/${set.cover}`}
                                  alt="front cover"
                                />

                                <div className="hidden absolute top-2 right-2 rounded-full lg:flex justify-center items-center bg-neutral-0 w-[69px] h-[65px]">
                                  <p className=" text-sm text-center leading-tight text-secondary-dark">
                                    <span className="text-[18px] lg:text-h3 text-tertiary-dark font-semibold">
                                      {set.validity}yrs
                                    </span>
                                    <br />
                                    Validity
                                  </p>
                                </div>
                              </div>
                              <div className="h-full lg:w-full lg:h-[30%] p-3 lg:p-2">
                                <p className="mt-1 text-h5 text-heading font-semibold text-left leading-none">
                                  {set?.title}
                                </p>
                                <div className="flex text-[#726D6D] justify-between items-center mt-4">
                                  {set?.Books?.length} books
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div className="flex flex-col items-center justify-center w-full h-[70%]">
                        <p className="mt-[170px] text-center text-secondary-light text-montserrat font-semibold leading-[29px] text-h1">
                          Your Catalogue is Currently Empty!
                          <br />
                          Please Create a New Set.
                        </p>
                      </div>
                    )}
                  </div>
                  {sets?.length < totalSetSize && (
                    <div className="ml-4 flex items-center justify-center w-full">
                      <button
                        onClick={() => setPageNo((curr) => curr + 1)}
                        className="text-p text-thumb underline font-semibold "
                      >
                        Load More
                      </button>
                    </div>
                  )}
                </div>
              ) : (
                expiringSoon?.map((book) => (
                  <div
                    className="block px-4 lg:px-0"
                    // onClick={() => navigate(`/books/bookId-${book.id}`)}
                  >
                    <div
                      key={book.id}
                      className="w-full  mb-8 h-[212px] flex lg:block lg:w-[260px] lg:h-[351px] shadow-[4px_4px_4px_rgba(224,224,224,0.25)] rounded-sm border-1 border-solid border-primary-dark-20 scale-1 lg:hover:scale-105 lg:transition lg:duration-600 lg:hover:ease-in"
                    >
                      <div className="h-full w-[112px] lg:w-full lg:h-[70%] bg-neutral-10 bg-[rgb(180,180,180)] relative py-4 px-2 bg-">
                        <div className="rounded-full lg:flex justify-center items-center shadow-[4px 4px 4px rgba(224, 224, 224, 0.25)] bg-neutral-0 h-full w-fit lg:w-[69px] lg:h-[65px]">
                          <img
                            className=" h-full object-cover"
                            src={`https://${process.env.REACT_APP_AWS_BUCKET_NAME}.s3.amazonaws.com/setCovers/${book.cover}`}
                            alt="front cover"
                          />
                          <p className="hidden lg:block text-sm text-center leading-tight text-secondary-dark">
                            <span className="text-[18px] lg:text-h3 text-tertiary-dark font-semibold">
                              {book.validity}yrs
                            </span>
                            <br />
                            Validity
                          </p>
                        </div>
                      </div>
                      <div className="h-full lg:w-full lg:h-[30%] p-3 lg:p-2">
                        {/* <p className="text-secondary-light uppercase mb-2">
                          #law1223
                        </p> */}
                        <p className="mt-1 text-h5 text-heading font-semibold text-left leading-none">
                          {book.name}
                        </p>
                        <p className="text-secondary-dark text-p1 font-semibold mt-3 mb-1">
                          {book.Bauthors?.map((auther, index, arr) => (
                            <span>
                              {arr.indexOf(arr[index]) === arr.length - 1
                                ? auther.bookAuthorName
                                : `${auther.bookAuthorName}, `}
                            </span>
                          ))}
                        </p>
                        <div className="flex  items-center mt-3">
                          <div className="w-32 h-2 bg-[#A6A6A6] rounded-full">
                            <div
                              className={` h-2 bg-primary-dark rounded-full`}
                              style={{ width: percentage + "%" }}
                            ></div>
                          </div>
                          <p className="text-sm text-secondary-light ml-4">
                            in {daysToExpire} Days
                          </p>
                        </div>
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            setModals((prevState) => ({
                              ...prevState,
                              renew: true
                            }));
                          }}
                          className="mt-2 bg-primary-dark text-primary-white font-medium w-[88px] h-[30px] border-[0.2px] border-solid border-tertiary-dark mr-auto"
                        >
                          Renew
                        </button>
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>
            {modals.expandedMenu && (
              <div className="hidden lg:block w-354 mt-16 px-2">
                <div className="w-full flex justify-between mb-4">
                  <h2 className="text-h3 text-heading font-semibold">
                    Expiring soon
                  </h2>
                  <RouterLink
                    to={ROUTES.EXPIRING_SOON}
                    className="text-p1 text-secondary-dark font-semibold"
                  >
                    See more
                  </RouterLink>
                </div>
                {expiringSoon?.map((book) => (
                  <div className="flex rounded-sm mb-6 border-1 p-2 border-solid border-primary-dark-20 h-[148px]">
                    <div className="bg-neutral-10 flex items-center justify-center w-[103px] h-full">
                      <img
                        className=" h-[80%] w-[80%] object-cover shadow-[5px_5px_13px_3px_rgba(0,0,0,0.5)]"
                        src={`https://${process.env.REACT_APP_AWS_BUCKET_NAME}.s3.amazonaws.com/books/${book.imageCoverFront}`}
                        alt="front cover"
                      />
                    </div>
                    <div className="py-4 px-3 pl-2 pr-0 flex flex-col justify-between">
                      <h3 className="text-heading text-left font-semibold text-h5">
                        {book.title}
                      </h3>
                      <p className="text-p1 text-secondary-dark font-semibold ">
                        {book.Bauthors?.map((auther, index, arr) => (
                          <span>
                            {arr.indexOf(arr[index]) === arr.length - 1
                              ? auther.bookAuthorName
                              : `${auther.bookAuthorName}, `}
                          </span>
                        ))}{" "}
                      </p>
                      <div className="flex items-center">
                        <div className="w-20 h-2 bg-[#BB7AAF] rounded-full">
                          <div
                            className={` h-2 bg-primary-dark rounded-full`}
                            style={{ width: percentage + "%" }}
                          ></div>
                        </div>
                        <p className="text-[15px] text-secondary-light ml-4">
                          expiring in {daysToExpire}days
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>

          {modals.closeDialogBox && (
            <DialogBox show={modals.closeDialogBox}>
              <div className="bottom-[60px] lg:bottom-0 bg-primary-white flex flex-col justify-between relative w-full lg:w-pop-up h-pop-up p-8">
                <h4 className="text-h4 text-tertiary-dark font-bold">
                  Abandon the existing set process
                </h4>
                <h5 className="text-p1 font-bold">
                  Would you like to abandon the last process
                </h5>
                <p className="text-secondary-light">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eget
                  utvest-ibulum bibendum egestas. Enim mi arcu convallis enim
                  purus interdum. Ut neque at adipiscing tortor facilisis
                  sagittis libero. Ac bibendum vel blandit.
                </p>

                <div className="flex justify-end">
                  <div className="mr-4">
                    <BtnSmallWhite
                      onClick={() => {
                        setModals((prevState) => ({
                          ...prevState,
                          closeDialogBox: false
                        }));
                      }}
                    >
                      Cancel
                    </BtnSmallWhite>
                  </div>
                  <BtnSmall
                    onClick={() => {
                      setModals((prevState) => ({
                        ...prevState,
                        uploadByBook: false
                      }));
                      // setGenerateAccessIds(true);
                    }}
                  >
                    Confirm
                  </BtnSmall>
                </div>
                <button
                  onClick={() =>
                    setModals((prevState) => ({
                      ...prevState,
                      generateIds: false
                    }))
                  }
                  className="absolute top-8 right-8"
                >
                  <svg
                    width="12"
                    height="14"
                    viewBox="0 0 12 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.2176 12.8188C10.9475 13.0888 10.5813 13.2405 10.1994 13.2405C9.81747 13.2405 9.45122 13.0888 9.18117 12.8188L5.99997 9.18281L2.81877 12.8176C2.68545 12.9531 2.52663 13.0609 2.35145 13.1347C2.17628 13.2085 1.98823 13.2469 1.79814 13.2477C1.60805 13.2485 1.41969 13.2116 1.24392 13.1392C1.06815 13.0668 0.908454 12.9604 0.77404 12.8259C0.639626 12.6915 0.533155 12.5318 0.460769 12.3561C0.388382 12.1803 0.351512 11.9919 0.352286 11.8018C0.35306 11.6118 0.391462 11.4237 0.465278 11.2485C0.539094 11.0734 0.646862 10.9145 0.782367 10.7812L4.09197 7.00121L0.781167 3.21881C0.645662 3.0855 0.537894 2.92667 0.464078 2.7515C0.390263 2.57633 0.35186 2.38827 0.351086 2.19818C0.350312 2.00809 0.387182 1.81973 0.459569 1.64397C0.531955 1.4682 0.638426 1.3085 0.77284 1.17409C0.907255 1.03967 1.06695 0.933201 1.24272 0.860814C1.41849 0.788427 1.60685 0.751557 1.79694 0.752331C1.98703 0.753105 2.17508 0.791508 2.35025 0.865323C2.52543 0.939139 2.68425 1.04691 2.81757 1.18241L5.99997 4.81961L9.18117 1.18241C9.31448 1.04691 9.47331 0.939139 9.64848 0.865323C9.82365 0.791508 10.0117 0.753105 10.2018 0.752331C10.3919 0.751557 10.5802 0.788427 10.756 0.860814C10.9318 0.933201 11.0915 1.03967 11.2259 1.17409C11.3603 1.3085 11.4668 1.4682 11.5392 1.64397C11.6116 1.81973 11.6484 2.00809 11.6476 2.19818C11.6469 2.38827 11.6085 2.57633 11.5347 2.7515C11.4608 2.92667 11.3531 3.0855 11.2176 3.21881L7.90797 7.00121L11.2176 10.7812C11.3515 10.915 11.4577 11.0738 11.5301 11.2486C11.6026 11.4234 11.6399 11.6108 11.6399 11.8C11.6399 11.9893 11.6026 12.1766 11.5301 12.3514C11.4577 12.5263 11.3515 12.6851 11.2176 12.8188Z"
                      fill="black"
                      fillOpacity="0.53"
                    />
                  </svg>
                </button>
              </div>
            </DialogBox>
          )}

          {modals.renew && (
            <div className="bg-primary-light fixed top-0 left-0 z-[100] w-screen h-screen flex flex-col justify-end">
              <div className="bg-primary-white h-auto relative w-full p-6 flex flex-col py-10 pb-[4.5rem]">
                <button
                  onClick={() =>
                    setModals((prevState) => ({ ...prevState, renew: false }))
                  }
                  className="absolute right-7 top-7"
                >
                  <svg
                    width="12"
                    height="14"
                    viewBox="0 0 12 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.2181 12.8184C10.948 13.0885 10.5817 13.2402 10.1999 13.2402C9.81796 13.2402 9.4517 13.0885 9.18165 12.8184L6.00045 9.18245L2.81925 12.8172C2.68594 12.9528 2.52712 13.0605 2.35194 13.1343C2.17677 13.2082 1.98871 13.2466 1.79863 13.2473C1.60854 13.2481 1.42018 13.2112 1.24441 13.1388C1.06864 13.0665 0.908943 12.96 0.774529 12.8256C0.640115 12.6912 0.533644 12.5315 0.461257 12.3557C0.38887 12.1799 0.352 11.9916 0.352774 11.8015C0.353548 11.6114 0.391951 11.4233 0.465766 11.2482C0.539582 11.073 0.64735 10.9142 0.782855 10.7808L4.09245 7.00085L0.781655 3.21845C0.646151 3.08513 0.538382 2.92631 0.464567 2.75113C0.390751 2.57596 0.352348 2.38791 0.351574 2.19782C0.3508 2.00773 0.38767 1.81937 0.460057 1.6436C0.532444 1.46783 0.638915 1.30813 0.773329 1.17372C0.907743 1.03931 1.06744 0.932834 1.24321 0.860448C1.41898 0.788061 1.60734 0.751191 1.79743 0.751965C1.98751 0.752739 2.17557 0.791141 2.35074 0.864957C2.52592 0.938773 2.68474 1.04654 2.81806 1.18205L6.00045 4.81925L9.18165 1.18205C9.31497 1.04654 9.47379 0.938773 9.64897 0.864957C9.82414 0.791141 10.0122 0.752739 10.2023 0.751965C10.3924 0.751191 10.5807 0.788061 10.7565 0.860448C10.9323 0.932834 11.092 1.03931 11.2264 1.17372C11.3608 1.30813 11.4673 1.46783 11.5397 1.6436C11.612 1.81937 11.6489 2.00773 11.6481 2.19782C11.6474 2.38791 11.609 2.57596 11.5351 2.75113C11.4613 2.92631 11.3536 3.08513 11.2181 3.21845L7.90846 7.00085L11.2181 10.7808C11.3519 10.9146 11.4582 11.0734 11.5306 11.2482C11.6031 11.423 11.6404 11.6104 11.6404 11.7996C11.6404 11.9889 11.6031 12.1763 11.5306 12.3511C11.4582 12.5259 11.3519 12.6847 11.2181 12.8184Z"
                      fill="black"
                      fill-opacity="0.53"
                    />
                  </svg>
                </button>
                {renderedStep}
              </div>
            </div>
          )}

          <DialogBox show={modals.uploadByBook}>
            <div className="bg-primary-white flex flex-col justify-between relative w-[1090px] h-[600px] p-8">
              <h4 className="text-h4 text-tertiary-dark font-bold">
                Create a New Set
              </h4>
              <div className="flex justify-between items-center my-12 mt-5">
                <div className="relative rounded-full w-12 h-12 bg-primary-dark border-2 border-solid border-primary-light flex justify-center items-center">
                  <svg
                    width="27"
                    height="23"
                    viewBox="0 0 27 23"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15.833 1.91699H4.94775V21.0837H22.3641V7.66699L15.833 1.91699ZM14.7445 14.3753V18.2087H12.5674V14.3753H9.30185L13.6668 10.542L18.01 14.3753H14.7445ZM14.7445 8.62533V3.35449L20.7314 8.62533H14.7445Z"
                      fill="white"
                    />
                  </svg>

                  <p className="text-heading font-semibold text-p1 absolute -bottom-8 -left-1 w-24">
                    Select Books
                  </p>
                </div>
                <div
                  style={{
                    background:
                      currentStep === 1
                        ? "linear-gradient(90deg, #702963, #fff)"
                        : currentStep > 1
                        ? "#702963"
                        : "#c2c2bd"
                  }}
                  className="w-progress-chart-1 h-1 bg-primary-dark"
                ></div>
                <div
                  className={`relative rounded-full w-12 h-12 ${
                    currentStep > 1 ? "bg-primary-dark" : "bg-[#c2c2bd]"
                  } border-2 border-solid border-primary-light flex justify-center items-center`}
                >
                  <svg
                    width="21"
                    height="24"
                    viewBox="0 0 21 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M5.06866 0.916992H13.9129L20.0359 6.16699V19.5837C20.0359 19.8931 19.8925 20.1898 19.6374 20.4086C19.3822 20.6274 19.0361 20.7503 18.6752 20.7503H5.06866C4.7078 20.7503 4.36171 20.6274 4.10654 20.4086C3.85136 20.1898 3.70801 19.8931 3.70801 19.5837V2.08366C3.70801 1.77424 3.85136 1.47749 4.10654 1.2587C4.36171 1.03991 4.7078 0.916992 5.06866 0.916992ZM17.9949 6.16699L13.9129 2.66699V5.58366C13.9129 5.73837 13.9846 5.88674 14.1122 5.99614C14.2398 6.10553 14.4128 6.16699 14.5933 6.16699H17.9949ZM16.4349 10.4212C16.5624 10.5306 16.6341 10.679 16.6341 10.8337C16.6341 10.9883 16.5624 11.1367 16.4349 11.2461L10.5113 16.3252L7.30898 13.5794C7.18506 13.4694 7.11648 13.322 7.11803 13.1691C7.11958 13.0161 7.19113 12.8698 7.31727 12.7617C7.44341 12.6535 7.61405 12.5922 7.79243 12.5908C7.97081 12.5895 8.14266 12.6483 8.27097 12.7546L10.5113 14.6755L15.4729 10.4212C15.6005 10.3119 15.7735 10.2505 15.9539 10.2505C16.1343 10.2505 16.3073 10.3119 16.4349 10.4212Z"
                      fill="white"
                    />
                    <path
                      d="M2.34747 4.41699H0.986816V20.167C0.986816 20.9405 1.3452 21.6824 1.98313 22.2294C2.62107 22.7764 3.48629 23.0837 4.38846 23.0837H17.3147V21.917H4.38846C3.84715 21.917 3.32802 21.7326 2.94526 21.4044C2.5625 21.0762 2.34747 20.6311 2.34747 20.167V4.41699Z"
                      fill="white"
                    />
                  </svg>

                  <p className="text-heading font-semibold text-p1 absolute -bottom-8 -left-8 w-28">
                    Accept Charges
                  </p>
                </div>
                <div
                  style={{
                    background:
                      currentStep === 2
                        ? "linear-gradient(90deg, #702963, #fff)"
                        : currentStep > 2
                        ? "#702963"
                        : "#c2c2bd"
                  }}
                  className="w-progress-chart-1 h-1 bg-primary-dark"
                ></div>
                <div
                  className={`relative rounded-full w-12 h-12 ${
                    currentStep > 2 ? "bg-primary-dark" : "bg-[#c2c2bd]"
                  } border-2 border-solid border-primary-light flex justify-center items-center`}
                >
                  {/* <svg
                    width="35"
                    height="26"
                    viewBox="0 0 35 26"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.1106 3.25H26.1707L24.7647 5.41667H20.1811C20.856 6.045 21.3622 6.78167 21.6574 7.58333H26.1707L24.7647 9.75H21.9527C21.7784 11.1007 21.017 12.3663 19.792 13.3417C18.5669 14.317 16.9501 14.9449 15.2038 15.1233V15.1667H14.2196L22.6557 22.75H19.1407L10.7046 15.1667V13H14.2196C16.6942 13 18.747 11.5917 19.0844 9.75H10.7046L12.1106 7.58333H18.6626C17.8752 6.305 16.188 5.41667 14.2196 5.41667H10.7046L12.1106 3.25Z"
                      fill="white"
                    />
                  </svg> */}

                  <svg
                    width="19"
                    height="20"
                    viewBox="0 0 19 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.7382 10.9526V13.8098C16.7382 15.0479 13.9027 16.6669 10.4049 16.6669C6.90706 16.6669 4.07153 15.0479 4.07153 13.8098V11.4288"
                      stroke="white"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M4.3374 11.6755C5.12002 12.7688 7.54026 13.7945 10.4047 13.7945C13.9025 13.7945 16.7381 12.265 16.7381 10.9507C16.7381 10.2126 15.8451 9.40404 14.4436 8.83643"
                      stroke="white"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M14.0238 6.19043V9.04757C14.0238 10.2857 11.1883 11.9047 7.69051 11.9047C4.1927 11.9047 1.35718 10.2857 1.35718 9.04757V6.19043"
                      stroke="white"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M7.69051 9.03254C11.1883 9.03254 14.0238 7.50302 14.0238 6.18873C14.0238 4.8735 11.1883 3.3335 7.69051 3.3335C4.1927 3.3335 1.35718 4.8735 1.35718 6.18873C1.35718 7.50302 4.1927 9.03254 7.69051 9.03254Z"
                      fill="white"
                      stroke="white"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>

                  <p className="text-heading font-semibold text-p1 absolute -bottom-8 left-2">
                    Payment
                  </p>
                </div>
                <div
                  style={{
                    background:
                      currentStep === 3
                        ? "linear-gradient(90deg, #702963, #fff)"
                        : currentStep > 3
                        ? "#702963"
                        : "#c2c2bd"
                  }}
                  className="w-progress-chart-1 h-1 bg-primary-dark"
                ></div>
                <div
                  className={`relative rounded-full w-12 h-12 ${
                    currentStep > 3 ? "bg-primary-dark" : "bg-[#c2c2bd]"
                  } border-2 border-solid border-primary-light flex justify-center items-center`}
                >
                  <svg
                    width="34"
                    height="27"
                    viewBox="0 0 34 27"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M19.7672 9H7.06775C6.82717 9 6.59644 8.92098 6.42633 8.78033C6.25621 8.63968 6.16064 8.44891 6.16064 8.25V5.25C6.16064 5.05109 6.25621 4.86032 6.42633 4.71967C6.59644 4.57902 6.82717 4.5 7.06775 4.5H19.7672C20.0078 4.5 20.2385 4.57902 20.4086 4.71967C20.5787 4.86032 20.6743 5.05109 20.6743 5.25V8.25C20.6743 8.44891 20.5787 8.63968 20.4086 8.78033C20.2385 8.92098 20.0078 9 19.7672 9ZM7.97485 7.5H18.8601V5.955H7.97485V7.5Z"
                      fill="white"
                    />
                    <path
                      d="M19.7672 10.5596H7.06775C6.82717 10.5596 6.59644 10.6386 6.42633 10.7792C6.25621 10.9199 6.16064 11.1107 6.16064 11.3096V14.2496C6.16064 14.4485 6.25621 14.6392 6.42633 14.7799C6.59644 14.9206 6.82717 14.9996 7.06775 14.9996H17.3725L20.6743 12.2246V11.3096C20.6743 11.1107 20.5787 10.9199 20.4086 10.7792C20.2385 10.6386 20.0078 10.5596 19.7672 10.5596ZM18.8601 13.4996H7.97485V11.9996H18.8601V13.4996Z"
                      fill="white"
                    />
                    <path
                      d="M10.7506 23.6325V23.5875L11.0409 22.545H4.34643V3H22.4885V10.6875L24.3027 9.27V2.25C24.3027 2.05109 24.2072 1.86032 24.037 1.71967C23.8669 1.57902 23.6362 1.5 23.3956 1.5H3.43933C3.19875 1.5 2.96803 1.57902 2.79791 1.71967C2.6278 1.86032 2.53223 2.05109 2.53223 2.25V23.25C2.53223 23.4489 2.6278 23.6397 2.79791 23.7803C2.96803 23.921 3.19875 24 3.43933 24H10.6962C10.7036 23.8766 10.7218 23.7538 10.7506 23.6325Z"
                      fill="white"
                    />
                    <path
                      d="M20.6744 14.3779L19.9668 14.9704C20.1423 14.9405 20.3032 14.8683 20.4292 14.7628C20.5551 14.6574 20.6404 14.5234 20.6744 14.3779Z"
                      fill="white"
                    />
                    <path
                      d="M6.16064 20.205C6.16064 20.4039 6.25621 20.5947 6.42633 20.7353C6.59644 20.876 6.82717 20.955 7.06775 20.955H11.4581L11.7303 19.98L11.8482 19.5675V19.53H7.97485V18H13.7259L15.5401 16.5H7.06775C6.82717 16.5 6.59644 16.579 6.42633 16.7197C6.25621 16.8603 6.16064 17.0511 6.16064 17.25V20.205Z"
                      fill="white"
                    />
                    <path
                      d="M31.097 12.5025L28.04 9.97496C27.9044 9.86248 27.7432 9.77324 27.5657 9.71235C27.3883 9.65146 27.198 9.62012 27.0059 9.62012C26.8138 9.62012 26.6236 9.65146 26.4461 9.71235C26.2687 9.77324 26.1075 9.86248 25.9718 9.97496L13.5354 20.3175L12.5104 23.925C12.472 24.0806 12.4712 24.241 12.5079 24.3969C12.5446 24.5528 12.6181 24.7012 12.7243 24.8335C12.8304 24.9659 12.9672 25.0797 13.1266 25.1683C13.286 25.2569 13.465 25.3186 13.6533 25.35C13.7468 25.3577 13.841 25.3577 13.9345 25.35C14.0457 25.3649 14.159 25.3649 14.2702 25.35L18.6696 24.5475L31.097 14.25C31.2327 14.1384 31.3405 14.0058 31.414 13.8597C31.4875 13.7136 31.5254 13.5569 31.5254 13.3987C31.5254 13.2405 31.4875 13.0838 31.414 12.9377C31.3405 12.7916 31.2327 12.659 31.097 12.5475V12.5025ZM17.7444 23.1825L14.4244 23.79L15.2317 21.0675L24.5567 13.275L27.1148 15.39L17.7444 23.1825ZM28.1398 14.5425L25.5818 12.4275L27.0241 11.25L29.6002 13.38L28.1398 14.5425Z"
                      fill="white"
                    />
                  </svg>
                  <p className="text-heading font-semibold text-p1 absolute -bottom-8 -left-2 w-20">
                    Set Data
                  </p>
                </div>

                <div
                  style={{
                    background:
                      currentStep === 4
                        ? "linear-gradient(90deg, #702963, #fff)"
                        : currentStep > 4
                        ? "#702963"
                        : "#c2c2bd"
                  }}
                  className="w-progress-chart-1 h-1 "
                ></div>
                <div
                  className={`relative rounded-full w-12 h-12 ${
                    currentStep > 4 ? "bg-primary-dark" : "bg-[#c2c2bd]"
                  } border-2 border-solid border-primary-light flex justify-center items-center`}
                >
                  <svg
                    width="27"
                    height="22"
                    viewBox="0 0 27 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_1608_8310)">
                      <path
                        fillRule="evenodd"
                        clip-rule="evenodd"
                        d="M0.574219 11C0.574219 8.08262 1.95042 5.28473 4.40008 3.22183C6.84973 1.15893 10.1722 0 13.6365 0C17.1009 0 20.4233 1.15893 22.873 3.22183C25.3226 5.28473 26.6988 8.08262 26.6988 11C26.6988 13.9174 25.3226 16.7153 22.873 18.7782C20.4233 20.8411 17.1009 22 13.6365 22C10.1722 22 6.84973 20.8411 4.40008 18.7782C1.95042 16.7153 0.574219 13.9174 0.574219 11H0.574219ZM12.8911 15.708L20.4115 7.79093L19.053 6.87573L12.6403 13.6239L8.0981 10.4368L6.98345 11.5632L12.8911 15.7095V15.708Z"
                        fill="white"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1608_8310">
                        <rect
                          width="26.1246"
                          height="22"
                          fill="white"
                          transform="translate(0.574219)"
                        />
                      </clipPath>
                    </defs>
                  </svg>

                  <p className="text-heading font-semibold text-p1 absolute -bottom-8 -left-2">
                    Overview
                  </p>
                </div>
              </div>

              {renderedStep}

              <button
                onClick={() => {
                  setModals((prevState) => ({
                    ...prevState,
                    uploadByBook: false
                  }));
                }}
                className="absolute top-8 right-8"
              >
                <svg
                  width="12"
                  height="14"
                  viewBox="0 0 12 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.2176 12.8188C10.9475 13.0888 10.5813 13.2405 10.1994 13.2405C9.81747 13.2405 9.45122 13.0888 9.18117 12.8188L5.99997 9.18281L2.81877 12.8176C2.68545 12.9531 2.52663 13.0609 2.35145 13.1347C2.17628 13.2085 1.98823 13.2469 1.79814 13.2477C1.60805 13.2485 1.41969 13.2116 1.24392 13.1392C1.06815 13.0668 0.908454 12.9604 0.77404 12.8259C0.639626 12.6915 0.533155 12.5318 0.460769 12.3561C0.388382 12.1803 0.351512 11.9919 0.352286 11.8018C0.35306 11.6118 0.391462 11.4237 0.465278 11.2485C0.539094 11.0734 0.646862 10.9145 0.782367 10.7812L4.09197 7.00121L0.781167 3.21881C0.645662 3.0855 0.537894 2.92667 0.464078 2.7515C0.390263 2.57633 0.35186 2.38827 0.351086 2.19818C0.350312 2.00809 0.387182 1.81973 0.459569 1.64397C0.531955 1.4682 0.638426 1.3085 0.77284 1.17409C0.907255 1.03967 1.06695 0.933201 1.24272 0.860814C1.41849 0.788427 1.60685 0.751557 1.79694 0.752331C1.98703 0.753105 2.17508 0.791508 2.35025 0.865323C2.52543 0.939139 2.68425 1.04691 2.81757 1.18241L5.99997 4.81961L9.18117 1.18241C9.31448 1.04691 9.47331 0.939139 9.64848 0.865323C9.82365 0.791508 10.0117 0.753105 10.2018 0.752331C10.3919 0.751557 10.5802 0.788427 10.756 0.860814C10.9318 0.933201 11.0915 1.03967 11.2259 1.17409C11.3603 1.3085 11.4668 1.4682 11.5392 1.64397C11.6116 1.81973 11.6484 2.00809 11.6476 2.19818C11.6469 2.38827 11.6085 2.57633 11.5347 2.7515C11.4608 2.92667 11.3531 3.0855 11.2176 3.21881L7.90797 7.00121L11.2176 10.7812C11.3515 10.915 11.4577 11.0738 11.5301 11.2486C11.6026 11.4234 11.6399 11.6108 11.6399 11.8C11.6399 11.9893 11.6026 12.1766 11.5301 12.3514C11.4577 12.5263 11.3515 12.6851 11.2176 12.8188Z"
                    fill="black"
                    fillOpacity="0.53"
                  />
                </svg>
              </button>
            </div>
          </DialogBox>
          <DialogBox show={modals.status}>
            <PopUp
              height={true}
              style={{ bottom: "0px" }}
              onClick={() => {
                setModals((prev) => ({ ...prev, status: false }));
              }}
              onClose={() => {
                setModals((prev) => ({ ...prev, status: false }));
              }}
              button="Done"
              title="Notification"
              main="Set creation in Progress.... "
              //  body="You will be able to create AccessIDs for the books &#38; then distribute then same by creating OrderIDs"
            />
          </DialogBox>
          <ToastContainer
            position="top-right"
            autoClose={3000}
            closeOnClick
            pauseOnFocusLoss
            pauseOnHover
          />
        </>
      )}
    </Page>
  );
}
