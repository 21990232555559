import { Routes, Route } from "react-router-dom";
import {
  Login,
  ForgotPassword,
  ResetPassword,
  Home,
  BusinessProfile,
  Wallet,
  Catalogue,
  BookDetails,
  ExpiringSoon,
  AddNewBook,
  GenerateAccessIds,
  GenerateOrderIds,
  Signup,
  Profile,
  Users,
  Organisations,
  ThirdPartySellers,
  IndividualUsers,
  Grievances,
  Sets,
  SetDetails,
} from "./pages";
import * as ROUTES from "./constant/routes";
import { IsUserRedirect, RequireAuth } from "./helpers/routes";
import AuthProvider from "./helpers/authProvider";
import OrderHistory from "./containers/order_history";
import OrderDetails from "./containers/order_details";
import OrgOrderHistory from "./containers/org_order_history";
import OrgOrderDetails from "./containers/org_order_details";
import TpsOrderHistory from "./containers/tps_order_history";
import TpsOrderDetails from "./containers/tps_order_details";
import OrganizationDetails from "./containers/organization_details";
import GenerateAccessIdsforsets from "./pages/generate_accessId_for_sets";
import GenerateOrderIdsforsets from "./pages/generate_orderId_for_sets";
import Notification from "./pages/notifications/Notification";
import Setting from "./pages/Settings/Setting";
import BookRenew from "./pages/bookrenew";
import SearchPage from "./pages/searchPage";
import ValidateQr from "./pages/validateQr/ValidateQr";
function App() {
    return (
    <AuthProvider>
      <Routes>
        <Route
          path={ROUTES.HOME}
          element={
            <RequireAuth>
              <Home />
            </RequireAuth>
          }
        />
        <Route
          path={ROUTES.LOG_IN}
          element={
            <IsUserRedirect>
              <Login />
            </IsUserRedirect>
          }
        />
        <Route
          path={ROUTES.SIGN_UP}
          element={
            <IsUserRedirect>
              <Signup />
            </IsUserRedirect>
          }
        />
        <Route
          path={ROUTES.FORGOT_PASSWORD}
          element={
            <IsUserRedirect>
              <ForgotPassword />
            </IsUserRedirect>
          }
        />
        <Route
          path={ROUTES.RESET_PASSWORD}
          element={
            <IsUserRedirect>
              <ResetPassword />
            </IsUserRedirect>
          }
        />

        <Route
          path={ROUTES.SEARCH_BOOK}
          element={
            <RequireAuth>
              <SearchPage />
            </RequireAuth>
          }
        />
        <Route
          path={ROUTES.NOTIFICATION}
          element={
            <RequireAuth>
              <Notification />
            </RequireAuth>
          }
        />
        <Route
          path={ROUTES.ADD_NEW_BOOK}
          element={
            <RequireAuth>
              <AddNewBook />
            </RequireAuth>
          }
        />
        <Route
          path={ROUTES.BUSINESS_PROFILE}
          element={
            <RequireAuth>
              <BusinessProfile />
            </RequireAuth>
          }
        />
        <Route
          path={ROUTES.PROFILE}
          element={
            <RequireAuth>
              <Profile />
            </RequireAuth>
          }
        />
        <Route
          path={ROUTES.WALLET}
          element={
            <RequireAuth>
              <Wallet />
            </RequireAuth>
          }
        />
        <Route
          path={ROUTES.CATALOGUE}
          element={
            <RequireAuth>
              <Catalogue />
            </RequireAuth>
          }
        />
        <Route
          path={ROUTES.GENERATE_ACCESS_IDS}
          element={
            <RequireAuth>
              <GenerateAccessIds />
            </RequireAuth>
          }
        />
        <Route
          path={ROUTES.GENERATE_ORDER_IDS}
          element={
            <RequireAuth>
              <GenerateOrderIds />
            </RequireAuth>
          }
        />
        <Route
          path={ROUTES.GENERATE_ACCESS_IDS_FOR_SETS}
          element={
            <RequireAuth>
              <GenerateAccessIdsforsets />
            </RequireAuth>
          }
        />
        <Route
          path={ROUTES.GENERATE_ORDER_IDS_FOR_SETS}
          element={
            <RequireAuth>
              <GenerateOrderIdsforsets />
            </RequireAuth>
          }
        />
        <Route
          path={ROUTES.BOOK_RENEWAL}
          element={
            <RequireAuth>
              <BookRenew />
            </RequireAuth>
          }
        />
        <Route
          path={ROUTES.EXPIRING_SOON}
          element={
            <RequireAuth>
              <ExpiringSoon />
            </RequireAuth>
          }
        />
        <Route
          path={ROUTES.BOOK_DETAILS}
          element={
            <RequireAuth>
              <BookDetails />
            </RequireAuth>
          }
        />
        <Route
          path={ROUTES.USERS}
          element={
            <RequireAuth>
              <Users />
            </RequireAuth>
          }
        />
        <Route
          path={ROUTES.ORGANISATIONS}
          element={
            <RequireAuth>
              <Organisations />
            </RequireAuth>
          }
        />
        <Route
          path={ROUTES.THIRD_PARTY_SELLERS}
          element={
            <RequireAuth>
              <ThirdPartySellers />
            </RequireAuth>
          }
        />
        <Route
          path={ROUTES.INDIVIDUAL_USERS}
          element={
            <RequireAuth>
              <IndividualUsers />
            </RequireAuth>
          }
        />
        <Route
          path={ROUTES.ORDER_HISTORY}
          element={
            <RequireAuth>
              <OrderHistory />
            </RequireAuth>
          }
        />
        <Route
          path={ROUTES.ORDER_DETAILS}
          element={
            <RequireAuth>
              <OrderDetails />
            </RequireAuth>
          }
        />
        <Route
          path={ROUTES.ORG_ORDER_HISTORY}
          element={
            <RequireAuth>
              <OrgOrderHistory />
            </RequireAuth>
          }
        />
        <Route
          path={ROUTES.ORG_ORDER_DETAILS}
          element={
            <RequireAuth>
              <OrgOrderDetails />
            </RequireAuth>
          }
        />
        <Route
          path={ROUTES.ORGANIZATION_DETAILS}
          element={
            <RequireAuth>
              <OrganizationDetails />
            </RequireAuth>
          }
        />
        <Route
          path={ROUTES.TPS_ORDER_HISTORY}
          element={
            <RequireAuth>
              <TpsOrderHistory />
            </RequireAuth>
          }
        />
        <Route
          path={ROUTES.TPS_ORDER_DETAILS}
          element={
            <RequireAuth>
              <TpsOrderDetails />
            </RequireAuth>
          }
        />
        <Route
          path={ROUTES.SETTINGS}
          element={
            <RequireAuth>
              <Setting />
            </RequireAuth>
          }
        />
        <Route
          path={ROUTES.GRIEVANCES}
          element={
            <RequireAuth>
              <Grievances />
            </RequireAuth>
          }
        />
        <Route
          path={ROUTES.SETS}
          element={
            <RequireAuth>
              <Sets />
            </RequireAuth>
          }
        />
        <Route
          path={ROUTES.SET_DETAILS}
          element={
            <RequireAuth>
              <SetDetails />
            </RequireAuth>
          }
        />
        <Route
          path={ROUTES.VALIDATE_QR}
          element={
            <RequireAuth>
              <ValidateQr />
            </RequireAuth>
          }
        />
      </Routes>
    </AuthProvider>
  );
}

export default App;
