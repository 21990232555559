import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDtYxbvqxunWClMlWc7TuCo2xq63Y6yhr4",
  authDomain: "eprakashak-a6a97.firebaseapp.com",
  projectId: "eprakashak-a6a97",
  storageBucket: "eprakashak-a6a97.appspot.com",
  messagingSenderId: "108887440347",
  appId: "1:108887440347:web:7b9ecc486452cee47645fe",
  measurementId: "G-5DQJ193803"
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);