import { useState, useEffect, useRef } from "react";
import {
  Page,
  Navbar,
  BtnBlock,
  DialogBox,
  BtnSmall,
  BtnSmallDisabled,
  Loader,
  BtnSmallWhite,
  PopUp,
} from "../components";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import * as ROUTES from "../constant/routes";
import Book from "../assets/images/book.png";
import {
  addABookAddCategory,
  addABookAddPublication,
  addABookAddAuthor,
  addABookSearchCategory,
  addABookSearchPublication,
  addABookSearchAuthor,
  getOngoingConversion,
  wallet,
  addABookStepZero,
  addABookStepalpha,
  addAddendumsStepFirst,
  addABookStepSecond,
  addABookStepThird,
  addAddendumsStepFourth,
  getAdendumTotalPages,
  getBookDetailsById,
} from "../assets/Js/script";
import Timeout from "../helpers/Timeout";
import { ToastContainer, toast } from "react-toastify";

export default function Addendums({
  modals = { addendums: true, addNew: false, uploadByBook: false },
  setModals = null,
  parentId,
  setTrigger,
  active,
  currentStep: step = 1,
  // setCurrentStep
}) {
  const Hoverref = useRef(null);
  const [filterSort, setFilterSort] = useState(false);
  const [filterData, setFilterData] = useState("1");
  const [sortDropdown, setSortDropDown] = useState([1, 2, 3, 4, 5]);
  const [isLoading, setIsLoading] = useState(true);
  const [newBookAdded, setNewBookAdded] = useState(false);
  const [cancelNewBook, setCancelNewBook] = useState(false);
  const [showUploadBook, setShowUploadBook] = useState(true);
  const [paymentSuccessfully, setPaymentSuccessfull] = useState(false);



  const notify = (message) => toast(message);

  const [hover, setHover] = useState({
    conversionCharges: false,
    hostingCharges: false,
  });

  useEffect(() => {
    window.addEventListener("click", handleClosePop);
    function handleClosePop() {
      setHover((prev) => ({
        ...prev,
        conversionCharges: false,
        hostingCharges: false,
      }));
    }
    return () => {
      window.removeEventListener("click", handleClosePop);
    };
  }, [hover]);

  function changeData(data) {
    setFilterData(data);
    setFilterSort(false);
  }
  const navigate = useNavigate();
  const [amountToPay, setAmountToPay] = useState("");
  const [uploadByBook, setUploadByBook] = useState(true);
  const [metaData, setMetaData] = useState({
    bookId: "",
    title: "",
    parentPageNo: '',
    bookCover: [],
    ISBN: "",
    category: [],
    edition: "",
    authors: [],
    publications: [],
    price: null,
    validity: null,
    shortDescription: "",
    longDescription: "",
    numberOfPages: null,
    customKeyName: null,
    customValueName: null,
    printedBookCost: null,
    eBookPrice: 0,
    noOfPages: 0,
    isPrintedBookAvailable: false,
    parentBookCountOfPages:0,
    additionalField: {
      name: "",
      value: "",
      enable: false,
    },
  });
  const [category, setCategory] = useState({ id: null, bookCategoryName: "" });
  const [author, setAuthor] = useState({ id: null, bookAuthorName: "" });
  const [publication, setPublication] = useState({
    id: null,
    bookPublicationName: "",
  });
  const [miniLoader, setMiniLoader] = useState(false);
  const [showCategorySuggestion, setShowCategorySuggestion] = useState(false);
  const [showAuthorSuggestion, setShowAuthorSuggestion] = useState(false);
  const [showPublicationSuggestion, setShowPublicationSuggestion] =
    useState(false);

  const [categorySuggestionArr, setCategorySuggestionArr] = useState([]);
  const [authorSuggestionArr, setAuthorSuggestionArr] = useState([]);
  const [publicationSuggestionArr, setPublicationSuggestionArr] = useState([]);

  const [showPopup, setShowPopup] = useState(true);

  const [books, setBooks] = useState([]);

  function handleUploadType({ target }) {
    setUploadByBook(target.name === "radio-1" ? true : false);
  }

  // stepper
  const [currentStep, setCurrentStep] = useState(step);
  const [uploading, setUploading] = useState(false);
  const [inSufficientBalance, setInSufficientBalance] = useState(false);
  const [paid, setPaid] = useState(false);
  const [renderedStep, setRenderedStep] = useState(null);
  const [uploadedBookData, setUploadedBookData] = useState(null);
  const [resend, setResend] = useState(false);
  const [walletDetails, setWalletDetails] = useState(null);

  async function handleStepFirst(e) {
    setUploading(true);
    e.preventDefault();
    const file = e.target.files;

    const { response, error, } = await addABookStepZero();



    if (response.status === "success") {
      const { responses, errors, data } = await addABookStepalpha(
        file[0],
        response.data.url
      );

      if (responses) {
        console.log(data, 'rereer')

        const { responsed, error } = await addAddendumsStepFirst(
          response.data.key,
          file[0].name,
          parentId
        );
        const { response: response2 } = await getAdendumTotalPages(parentId)
        if (response2?.data?.totalPages + responsed.data.uploadedBook.countOfPages >= 250) {
          return notify('Maximum adendum pages for this year is 250')
        }
        if (responsed.status === "success") {
          setUploadedBookData({ ...responsed.data.uploadedBook });
          setCurrentStep(2);
          setUploading(false);
          setMetaData((prevState) => ({
            ...prevState,
            noOfPages: responsed.data.uploadedBook.countOfPages,
            bookId: responsed.data.uploadedBook.id,
            publicationId: responsed.data.uploadedBook.publicationId,
            hostingCharge: responsed.data.uploadedBook.hostingCharge,
            conversionCharge: responsed.data.uploadedBook.conversionCharge,
          }));
        } else {
          notify(response?.status ? response?.status : "Something went wrong");
          setMiniLoader(false);
        }
      }
    }
  }

  async function handleStepSecond(e) {
    setMiniLoader(true);
  
    setOtps([...otps.map((v) => "")]);
   setModals&&   setModals((prevState) => ({
        ...prevState,
        addNew: false,
        addendums: uploadByBook ? true : false,
      }));
    const { response, error } = await addABookStepSecond({
      bookId: uploadedBookData.id,
      validity: filterData,
      totalCredits:
        parseFloat(uploadedBookData.conversionCharge) +
        parseInt(uploadedBookData.hostingCharge) * parseInt(filterData),
    });
    if (response.status === "success"||response?.message=="SMS OTP Failed! Please check OTP on your email.") {
      setMiniLoader(false);
      setPaid(true);
      setOtps([...otps.map((v) => "")]);

      

    } else {
      notify(response?.message ? response?.message : "Something went wrong");
      setMiniLoader(false);
    }
  }

  async function handleAcceptCharges() {
    setMiniLoader(true)
    const { response, error } = await wallet();
    if (response.status === "success") {
      setWalletDetails({ ...response.data });
      response.data.myCredits.credits <
        parseFloat(uploadedBookData.conversionCharge) +
        parseInt(uploadedBookData.hostingCharge) * parseInt(filterData) &&
        setInSufficientBalance(true);
      setMiniLoader(false)
      setAmountToPay(
        parseFloat(uploadedBookData.conversionCharge) +
        parseInt(uploadedBookData.hostingCharge) * parseInt(filterData)
      );
      setCurrentStep(3);
    } else {
      notify(response.message ? response.message : "Somthing went wrong");
      setMiniLoader(false);
    }
  }

  async function handleStepThird(e) {
    setMiniLoader(true);
    const { response, error } = await addABookStepThird(otps.join(""));
    if (response.status === "success") {
      setModals && setModals((prevState) => ({ ...prevState, addendums: false }));
      setTrigger && setTrigger((prev) => ({ ...prev, addNew: true }));
      setShowPopup(false);
      setPaymentSuccessfull(true);

      setMiniLoader(false);
      setCurrentStep(1);
    } else {
      notify(response?.message ? response?.message : "Something went wrong");
      setOtps([...otps.map((v) => "")]);
      setMiniLoader(false);
    }
  }
  const [otps, setOtps] = useState(new Array(6).fill(""));

  function handleChnges(data, index) {
    if (isNaN(data.value)) {
      return false;
    }
    setOtps([...otps.map((d, idx) => (idx === index ? data.value : d))]);

    // for AutoFocus

    if (data.nextSibling) {
      data.nextSibling.focus();
    }
  }

  useEffect(() => {
    (async () => {
      const { response, error } = await addABookSearchCategory(
        category.bookCategoryName
      );
      if (response.status === "success") {
        setCategorySuggestionArr([...response.data.categories]);
      }
    })();
    setShowCategorySuggestion(category.bookCategoryName.length > 0);
  }, [category]);

  useEffect(() => {
    (async () => {
      const { response, error } = await addABookSearchAuthor(
        author.bookAuthorName
      );

      if (response.status === "success") {
        setAuthorSuggestionArr([...response.data.authors]);
      }
    })();
    setShowAuthorSuggestion(author.bookAuthorName.length > 0);
  }, [author]);

  useEffect(() => {
    (async () => {
      const { response, error } = await addABookSearchPublication(
        publication.bookAuthorName
      );

      if (response.status === "success") {
        setPublicationSuggestionArr([...response.data.publications]);
      }
    })();
    setShowPublicationSuggestion(publication.bookPublicationName.length > 0);
  }, [publication]);

  useEffect(() => {

    (async () => {
      const { response, error } = await getOngoingConversion();
      const {response:{data:parentBookData}}=await getBookDetailsById(parentId)
      console.log(response,'response')
      if (response.status === "success") {

        let page = response.data.uploadedBook?.find((elm) => elm.parentId === parentId).countOfPages
        let bookId = response.data.uploadedBook?.find((elm) => elm.parentId === parentId).id
     
        

        let secondPagedata = response.data.uploadedBook?.find((elm) => elm.parentId === parentId);
      let parentBookCountOfPages= parentBookData?.bookDetail?.totalCountOfPages??0
        

        setUploadedBookData({ ...secondPagedata });
        setMetaData((prev) => ({ ...prev,parentBookCountOfPages, noOfPages: page, bookId: bookId }));
        setBooks([...response.data.uploadedBook]);



      }
    })();
  }, []);

  console.log(metaData, "hshxs")

  function handleBookCoverUpload(e) {
    e.preventDefault();
    const file = e.target.files[0];
    setMetaData((prevState) => {
      prevState.bookCover.push(file);
      return { ...prevState };
    });
  }

  function handleChangeText({ target }) {
    const { name, value } = target;
    setMetaData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  async function handleActivate() {
    setMiniLoader(true);
    const { response, error } = await addAddendumsStepFourth(metaData);
    // const Response = JSON.parse(response);
    if (response.status === "success") {
      setMiniLoader(false);
      setNewBookAdded(true);
      setShowPopup(false);
      setModals &&
        setModals((prevState) => ({ ...prevState, addendums: false }));
      // setUploadedBookData({});
      setMetaData({
        bookId: "",
        title: "",
        ISBN: "",
        category: [],
        edition: "",
        price: null,
        numberOfPages: null,
        noOfPages: 0,
      });
      setTrigger((prev) => ({ ...prev, addNew: true }));
    } else {
      notify(response?.status ? response?.message : "Something went wrong");
      setMiniLoader(false);
      setCurrentStep(4);
    }
  }
  function handleGotoForm(data) {
    if (data.id) {
      if (data.isPaid) {
        setModals((prev) => ({ ...prev, addendums: true }));
        setCurrentStep(4);
        setMetaData((prev) => ({
          ...prev,
          bookId: data.id,
          validity: data.validity,
          noOfPages: data.countOfPages,
          hostingCharge: data.hostingCharge,
          conversionCharge: data.conversionCharge,
        }));
        setUploadedBookData((prev) => ({
          prev,
          ...data,
        }));
      } else {
        setModals((prev) => ({ ...prev, addendums: true }));
        setUploadedBookData((prev) => ({
          prev,
          ...data,
        }));
        setCurrentStep(2);
      }
    }
  }

  const validateStep4 =
    metaData.bookId == "" || metaData.title == "" || metaData.parentPageNo == "" || metaData.noOfPages == 0;


  console.log(metaData, "");

  const dragOver = (e) => {
    e.preventDefault();
  };

  const dragEnter = (e) => {
    e.preventDefault();
  };

  const dragLeave = (e) => {
    e.preventDefault();
  };

  async function fileDrop(e) {
    e.preventDefault();
    setUploading(true);

    const file = e.dataTransfer.files[0];
    console.log(file);

    const { response, error } = await addABookStepZero();

    if (response.status === "success") {
      const { responses, errors } = await addABookStepalpha(
        file,
        response.data.url
      );
      if (responses) {
        const { responsed, error } = await addAddendumsStepFirst(
          response.data.key,
          file.name,
          parentId
        );
        if (responsed.status === "success") {
          setUploadedBookData({ ...responsed.data.uploadedBook });
          setCurrentStep(2);
          setUploading(false);
          setMetaData((prevState) => ({
            ...prevState,
            noOfPages: responsed.data.uploadedBook.countOfPages,
            bookId: responsed.data.uploadedBook.id,
            publicationId: responsed.data.uploadedBook.publicationId,
            hostingCharge: responsed.data.uploadedBook.hostingCharge,
            conversionCharge: responsed.data.uploadedBook.conversionCharge,
          }));
        } else {
          notify(response?.status ? response?.status : "Something went wrong");
          setMiniLoader(false);
        }
      }
    }
  }

  useEffect(() => {
    switch (currentStep) {
      case 1:
        setRenderedStep(
          <div className="flex justify-center items-center bg-[#f3ebf2] w-[970px] h-[350px] mt-3 mx-auto border-[1.5px] border-dashed border-primary-dark">
            <div
              className="flex items-center justify-center flex-col">
              {!uploading ? (
                <div className="flex items-center justify-center flex-col"
                  onDragOver={dragOver}
                  onDragEnter={dragEnter}
                  onDragLeave={dragLeave}
                  onDrop={fileDrop}>
                  <svg
                    width="90"
                    height="94"
                    viewBox="0 0 90 94"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.11875 76.5751C8.81625 77.6717 9.99 78.3337 11.25 78.3337H67.5C69 78.3337 70.3575 77.4015 70.9462 75.9601L82.1963 48.5435C82.4428 47.9479 82.5434 47.2976 82.4892 46.6512C82.435 46.0048 82.2275 45.3827 81.8856 44.841C81.5436 44.2993 81.078 43.855 80.5306 43.5482C79.9832 43.2414 79.3712 43.0818 78.75 43.0837H75V31.3337C75 27.0136 71.6362 23.5003 67.5 23.5003H42.5437L32.9587 15.667H15C10.8637 15.667 7.5 19.1802 7.5 23.5003V74.417H7.52625C7.51789 75.1813 7.72385 75.9315 8.11875 76.5751ZM67.5 31.3337V43.0837H22.5C21 43.0837 19.6425 44.0158 19.0537 45.4572L15 55.3389V31.3337H67.5Z"
                      fill="#DAC5D7"
                    />
                  </svg>
                  <h3 className="font-[500] text-heading text-h3 mb-3 mt-2">
                    Drag &#38; Drop or{" "}
                    <label
                      htmlFor="book_drop"
                      className="cursor-pointer text-tertiary-dark underline"
                    >
                      Browse
                    </label>{" "}
                  </h3>
                  <p className="text-secondary-light text-p1">
                    Only PDF Supported
                  </p>
                  <input
                    onChange={handleStepFirst}
                    id="book_drop"
                    className="hidden"
                    type="file"
                    accept=".pdf"
                  />
                </div>
              ) : (
                <div>
                  <div
                    className="flex items-center justify-center flex-col"

                  >
                    <svg
                      className="animate-spin ml-1 mr-3 h-12 w-12 text-[#702963]"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        class="opacity-20"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        stroke-width="4"
                      ></circle>
                      <path
                        class="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  </div>

                  <h3 className="font-[500] text-center text-heading text-h3 mb-3 mt-2">
                    Upload in Progress...
                  </h3>
                  <p className="text-secondary-light text-center text-p1">
                    Please wait while the book gets uploaded
                  </p>
                </div>
              )}
            </div>
          </div>
        );
        break;

      case 2:
        setRenderedStep(
          <div className="flex border-[1.5px] border-solid border-primary-dark-10 p-4  w-[902px] h-[370px] mt-3 mx-auto ">
            {/* <div className="w-1/3 ">
              <div className="relative h-[361px] w-[278px] mx-auto drop-shadow-[15px_15px_10px_rgba(0,0,0,0.25)]">
                <img src={Book} alt="book" />
                <button className="rounded-md w-[133px] h-8 absolute bottom-12 left-[68px] bg-[#979797] text-primary-white ">
                  Page 1 of
                  {uploadedBookData.countOfPages}
                </button>
              </div>
            </div> */}
            <div className="w-full px-10 pt-4 text-left ">
              <p className="text-secondary-light text-h5 text-justify mb-6">
                Charges for Conversion of the Uploaded Addendums
              </p>
              <div className="flex mb-[10px]">
                <p className="text-h5 text-secondary-dark font-semibold mr-[101px]">
                  No. of pages
                </p>
                <h4 className="text-h5 text-heading font-semibold">
                  {uploadedBookData?.countOfPages}
                </h4>
              </div>

              <div className="relative flex mb-[10px]">
                <p className="flex items-center text-h5 text-secondary-dark font-semibold mr-6 ">
                  Conversion charges
                  <span
                    onMouseEnter={(e) =>
                      setHover((prevState) => ({
                        ...prevState,
                        conversionCharges: true,
                      }))
                    }
                    className="ml-1"
                  >
                    <svg
                      width="20"
                      height="18"
                      viewBox="0 0 20 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10 18C12.6522 18 15.1957 17.0518 17.0711 15.364C18.9464 13.6761 20 11.3869 20 9C20 6.61305 18.9464 4.32387 17.0711 2.63604C15.1957 0.948212 12.6522 0 10 0C7.34784 0 4.8043 0.948212 2.92893 2.63604C1.05357 4.32387 0 6.61305 0 9C0 11.3869 1.05357 13.6761 2.92893 15.364C4.8043 17.0518 7.34784 18 10 18ZM11.1625 7.4115L9.9125 12.7046C9.825 13.0871 9.94875 13.3043 10.2925 13.3043C10.535 13.3043 10.9012 13.2255 11.15 13.0275L11.04 13.4955C10.6812 13.8848 9.89 14.1683 9.20875 14.1683C8.33 14.1683 7.95625 13.6935 8.19875 12.6844L9.12125 8.78288C9.20125 8.45325 9.12875 8.334 8.7625 8.25412L8.19875 8.163L8.30125 7.73438L11.1637 7.4115H11.1625ZM10 6.1875C9.66848 6.1875 9.35054 6.06897 9.11612 5.858C8.8817 5.64702 8.75 5.36087 8.75 5.0625C8.75 4.76413 8.8817 4.47798 9.11612 4.267C9.35054 4.05603 9.66848 3.9375 10 3.9375C10.3315 3.9375 10.6495 4.05603 10.8839 4.267C11.1183 4.47798 11.25 4.76413 11.25 5.0625C11.25 5.36087 11.1183 5.64702 10.8839 5.858C10.6495 6.06897 10.3315 6.1875 10 6.1875Z"
                        fill="#C5C5C5"
                      />
                    </svg>
                  </span>
                </p>
                <h4 className="text-h5 text-heading font-semibold">
                  {uploadedBookData?.conversionCharge}{" "}
                  Tokens
                </h4>
                {hover.conversionCharges && (
                  <div
                    onClick={(e) => e.stopPropagation()}
                    // className="grid grid-cols-[40%_60%] grid-rows-[repeat(4,25%)] absolute top-0 left-48 w-[187px] h-[101px] border-[0.5px] border-solid border-[rgba(182,182,182,0.33)] bg-[#fbfafb]"
                    className="p-2 absolute top-0 left-48 w-[187px] border-[0.5px] border-solid border-[rgba(182,182,182,0.33)] bg-[#fbfafb]"
                  
                  >
                    <p className="text-tertiary-dark text-[13px] font-medium text-left">8 tokens per page</p>
                    {/* <span className="absolute top-2 -left-3"></span>
                    <p className="w-full h-full row-span-[0/1] border-b-[0.5px] border-solid border-[rgba(174,174,174,0.26)] text-secondary-light text-[12px] flex justify-center items-center">
                      Pages
                    </p>
                    <p className="w-full h-full row-span-[0/1] col-span-[1/2] border-b-[0.5px] border-l-[0.5px] border-solid border-[rgba(174,174,174,0.26)] text-secondary-light text-[12px] flex justify-center items-center ">
                      Charges/page
                    </p>
                    <p className="w-full h-full row-span-[1/2] border-b-[0.5px] border-solid border-[rgba(174,174,174,0.26)] text-secondary-light text-[12px] flex justify-center items-center">
                      1-500
                    </p>
                    <p className="w-full h-full row-span-[1/2] col-span-[1/2] border-b-[0.5px] border-l-[0.5px] border-solid border-[rgba(174,174,174,0.26)] text-[rgba(112,41,99,0.67)] text-[12px] flex justify-center items-center ">
                      10 Tokens
                    </p>
                    <p className="w-full h-full row-span-[2/3] border-b-[0.5px] border-solid border-[rgba(174,174,174,0.26)] text-secondary-light text-[12px] flex justify-center items-center">
                      501-2000
                    </p>
                    <p className="w-full h-full row-span-[2/3] col-span-[1/2] border-b-[0.5px] border-l-[0.5px] border-solid border-[rgba(174,174,174,0.26)] text-[rgba(112,41,99,0.67)] text-[12px] flex justify-center items-center ">
                      9 Tokens
                    </p>
                    <p className="w-full h-full row-span-[3/4] border-b-[0.5px] border-solid border-[rgba(174,174,174,0.26)] text-secondary-light text-[12px] flex justify-center items-center">
                      Above 2000
                    </p>
                    <p className="w-full h-full row-span-[3/4] col-span-[1/2] border-b-[0.5px] border-l-[0.5px] border-solid border-[rgba(174,174,174,0.26)] text-[rgba(112,41,99,0.67)] text-[12px] flex justify-center items-center ">
                      8 Tokens
                    </p> */}
                    <button
                      onClick={() =>
                        setHover((prevState) => ({
                          ...prevState,
                          conversionCharges: false,
                        }))
                      }
                      className="absolute top-2 right-2"
                    >
                      <svg
                        width="5"
                        height="5"
                        viewBox="0 0 5 5"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M4.80964 4.82838C4.69011 4.93643 4.528 4.99714 4.35897 4.99714C4.18994 4.99714 4.02783 4.93643 3.9083 4.82838L2.50027 3.37344L1.09223 4.8279C1.03322 4.88212 0.962925 4.92524 0.885391 4.95478C0.807858 4.98432 0.724623 4.99969 0.640487 5C0.556352 5.00031 0.472981 4.98555 0.395184 4.95659C0.317387 4.92762 0.246703 4.88502 0.18721 4.83123C0.127716 4.77745 0.0805912 4.71354 0.048552 4.64321C0.0165128 4.57288 0.000193788 4.4975 0.00053638 4.42144C0.000878972 4.34538 0.0178763 4.27013 0.050548 4.20003C0.0832197 4.12994 0.130919 4.06638 0.190895 4.01304L1.65576 2.50048L0.190364 0.986961C0.130388 0.933616 0.0826886 0.870062 0.0500169 0.799967C0.0173452 0.729872 0.000347876 0.654623 5.28423e-06 0.578559C-0.000337307 0.502495 0.0159817 0.427123 0.0480209 0.35679C0.0800601 0.286457 0.127185 0.222554 0.186679 0.168769C0.246172 0.114983 0.316856 0.0723792 0.394653 0.0434138C0.47245 0.0144485 0.555821 -0.000304946 0.639956 4.77726e-06C0.724092 0.000314501 0.807327 0.0156811 0.88486 0.0452183C0.962394 0.0747555 1.03269 0.117879 1.0917 0.1721L2.50027 1.62752L3.9083 0.1721C3.96731 0.117879 4.03761 0.0747555 4.11514 0.0452183C4.19267 0.0156811 4.27591 0.000314501 4.36004 4.77726e-06C4.44418 -0.000304946 4.52755 0.0144485 4.60535 0.0434138C4.68314 0.0723792 4.75383 0.114983 4.81332 0.168769C4.87281 0.222554 4.91994 0.286457 4.95198 0.35679C4.98402 0.427123 5.00034 0.502495 4.99999 0.578559C4.99965 0.654623 4.98265 0.729872 4.94998 0.799967C4.91731 0.870062 4.86961 0.933616 4.80964 0.986961L3.34477 2.50048L4.80964 4.01304C4.8689 4.06655 4.91591 4.1301 4.94798 4.20005C4.98006 4.27001 4.99657 4.34499 4.99657 4.42071C4.99657 4.49643 4.98006 4.57141 4.94798 4.64136C4.91591 4.71132 4.8689 4.77487 4.80964 4.82838Z"
                          fill="black"
                          fill-opacity="0.53"
                        />
                      </svg>
                    </button>
                  </div>
                )}
              </div>
              <div className="flex mb-[10px]">
                <p className="relative flex items-center text-h5 text-secondary-dark font-semibold mr-[50px] ">
                  Hosting charges
                  <span
                    onMouseEnter={(e) =>
                      setHover((prevState) => ({
                        ...prevState,
                        hostingCharges: true,
                      }))
                    }
                    className="ml-1"
                  >
                    <svg
                      width="20"
                      height="18"
                      viewBox="0 0 20 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10 18C12.6522 18 15.1957 17.0518 17.0711 15.364C18.9464 13.6761 20 11.3869 20 9C20 6.61305 18.9464 4.32387 17.0711 2.63604C15.1957 0.948212 12.6522 0 10 0C7.34784 0 4.8043 0.948212 2.92893 2.63604C1.05357 4.32387 0 6.61305 0 9C0 11.3869 1.05357 13.6761 2.92893 15.364C4.8043 17.0518 7.34784 18 10 18ZM11.1625 7.4115L9.9125 12.7046C9.825 13.0871 9.94875 13.3043 10.2925 13.3043C10.535 13.3043 10.9012 13.2255 11.15 13.0275L11.04 13.4955C10.6812 13.8848 9.89 14.1683 9.20875 14.1683C8.33 14.1683 7.95625 13.6935 8.19875 12.6844L9.12125 8.78288C9.20125 8.45325 9.12875 8.334 8.7625 8.25412L8.19875 8.163L8.30125 7.73438L11.1637 7.4115H11.1625ZM10 6.1875C9.66848 6.1875 9.35054 6.06897 9.11612 5.858C8.8817 5.64702 8.75 5.36087 8.75 5.0625C8.75 4.76413 8.8817 4.47798 9.11612 4.267C9.35054 4.05603 9.66848 3.9375 10 3.9375C10.3315 3.9375 10.6495 4.05603 10.8839 4.267C11.1183 4.47798 11.25 4.76413 11.25 5.0625C11.25 5.36087 11.1183 5.64702 10.8839 5.858C10.6495 6.06897 10.3315 6.1875 10 6.1875Z"
                        fill="#C5C5C5"
                      />
                    </svg>
                  </span>
                  {hover.hostingCharges && (
                    <div
                      onClick={(e) => e.stopPropagation()}
                      className="p-3 absolute top-0 left-48 w-[276px] h-fit border-[0.5px] border-solid border-[rgba(182,182,182,0.33)] bg-[#fbfafb]"
                    >
                      <span className="absolute top-2 -left-3"></span>
                      <p className="text-secondary-light text-[13px] font-medium text-left mb-2">
                        Hosting charges
                      </p>
                      <p className="text-tertiary-dark text-[13px] font-medium text-left mb-2">
                        500 Tokens/ 1000 Pages
                      </p>
                      <p className="text-secondary-light text-[13px] font-medium text-left mb-2">
                        Buffer charges are also included here
                      </p>
                      <button
                        onClick={() =>
                          setHover((prevState) => ({
                            ...prevState,
                            hostingCharges: false,
                          }))
                        }
                        className="absolute top-2 right-2"
                      >
                        <svg
                          width="5"
                          height="5"
                          viewBox="0 0 5 5"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M4.80964 4.82838C4.69011 4.93643 4.528 4.99714 4.35897 4.99714C4.18994 4.99714 4.02783 4.93643 3.9083 4.82838L2.50027 3.37344L1.09223 4.8279C1.03322 4.88212 0.962925 4.92524 0.885391 4.95478C0.807858 4.98432 0.724623 4.99969 0.640487 5C0.556352 5.00031 0.472981 4.98555 0.395184 4.95659C0.317387 4.92762 0.246703 4.88502 0.18721 4.83123C0.127716 4.77745 0.0805912 4.71354 0.048552 4.64321C0.0165128 4.57288 0.000193788 4.4975 0.00053638 4.42144C0.000878972 4.34538 0.0178763 4.27013 0.050548 4.20003C0.0832197 4.12994 0.130919 4.06638 0.190895 4.01304L1.65576 2.50048L0.190364 0.986961C0.130388 0.933616 0.0826886 0.870062 0.0500169 0.799967C0.0173452 0.729872 0.000347876 0.654623 5.28423e-06 0.578559C-0.000337307 0.502495 0.0159817 0.427123 0.0480209 0.35679C0.0800601 0.286457 0.127185 0.222554 0.186679 0.168769C0.246172 0.114983 0.316856 0.0723792 0.394653 0.0434138C0.47245 0.0144485 0.555821 -0.000304946 0.639956 4.77726e-06C0.724092 0.000314501 0.807327 0.0156811 0.88486 0.0452183C0.962394 0.0747555 1.03269 0.117879 1.0917 0.1721L2.50027 1.62752L3.9083 0.1721C3.96731 0.117879 4.03761 0.0747555 4.11514 0.0452183C4.19267 0.0156811 4.27591 0.000314501 4.36004 4.77726e-06C4.44418 -0.000304946 4.52755 0.0144485 4.60535 0.0434138C4.68314 0.0723792 4.75383 0.114983 4.81332 0.168769C4.87281 0.222554 4.91994 0.286457 4.95198 0.35679C4.98402 0.427123 5.00034 0.502495 4.99999 0.578559C4.99965 0.654623 4.98265 0.729872 4.94998 0.799967C4.91731 0.870062 4.86961 0.933616 4.80964 0.986961L3.34477 2.50048L4.80964 4.01304C4.8689 4.06655 4.91591 4.1301 4.94798 4.20005C4.98006 4.27001 4.99657 4.34499 4.99657 4.42071C4.99657 4.49643 4.98006 4.57141 4.94798 4.64136C4.91591 4.71132 4.8689 4.77487 4.80964 4.82838Z"
                            fill="black"
                            fill-opacity="0.53"
                          />
                        </svg>
                      </button>
                    </div>
                  )}
                </p>
                <h4 className="text-h5 text-heading font-semibold">
                  {parseInt(filterData) *
                    parseFloat(uploadedBookData?.hostingCharge)}{" "}
                  Tokens
                </h4>
              </div>
              <div className="flex mt-4">
                <p className="text-h4 text-heading font-semibold mr-[92px] ">
                  Total Tokens
                </p>
                <h4 className="text-h5 text-heading font-semibold">
                  {parseFloat(uploadedBookData?.conversionCharge) +
                    parseInt(uploadedBookData?.hostingCharge) *
                    parseInt(filterData)}
                </h4>
              </div>

              <div className="flex mt-8 justify-end">
                <BtnSmall onClick={handleAcceptCharges}>
                  {miniLoader ? (
                    <div className="flex">
                      <svg
                        className="animate-spin ml-1 mr-3 h-5 w-5 "
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          class="opacity-20"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          stroke-width="4"
                        ></circle>
                        <path
                          class="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                      Processing...
                    </div>
                  ) : (
                    "Accept Charges"
                  )}
                </BtnSmall>
              </div>
            </div>
          </div>
        );
        break;

      case 3:
        setRenderedStep(
          <div className=" border-[1.5px] border-solid border-primary-dark-10 p-6  w-[902px] h-[370px] mt-3 mx-auto ">
            {!paid ? (
              <>
                <h2 className="text-heading font-semibold text-left mb-4">
                  Payment Details
                </h2>
                {/* <p className="text-secondary-light text-h5 text-justify mb-4">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. At
                  aliquet ut facilisi nulla mauris iopsum. Pipsum dolor sit
                  ametre, consectetur adipiscing elit. At aliquet ut facilisi
                  nulla mauris.
                </p> */}
                <div className="flex items-center pl-4 mb-4 border-[1.5px] border-solid border-primary-dark-10 w-[730px] h-12">
                  <div className="w-6 h-6 text-white font-semibold bg-secondary-yellow flex justify-center items-center rounded-sm mr-3">
                    &#8377;
                  </div>
                  <p className="text-h5 text-secondary-dark font-semibold">
                    Total Tokens Available:{" "}
                    <span className="text-heading  ml-6">
                      {walletDetails.myCredits.credits.toFixed(2)}
                    </span>
                  </p>
                </div>
                <div className="mb-4 flex items-center pl-4 border-[1.5px] border-solid border-primary-dark-10 w-[730px] h-12">
                  <div className="w-6 h-6 text-white font-semibold bg-secondary-yellow flex justify-center items-center rounded-sm mr-3">
                    &#8377;
                  </div>
                  <p className=" text-h5 text-secondary-dark font-semibold">
                    Amount to pay:{" "}
                    <span className="text-heading  ml-[73px]">
                      {(amountToPay * 1).toFixed(2)}
                    </span>
                  </p>
                </div>
                {inSufficientBalance && (
                  <p className="flex items-center text-secondary-light text-h5 text-left mb-2">
                    <span className="mr-2">
                      <svg
                        width="25"
                        height="22"
                        viewBox="0 0 25 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12.0265 0C5.39521 0 0 4.85983 0 10.8333C0 16.8068 5.42058 21.6667 12.0833 21.6667C18.7461 21.6667 24.1667 16.8068 24.1667 10.8333C24.1667 4.85983 18.7207 0 12.0265 0ZM13.2917 16.25H10.875V14.0833H13.2917V16.25ZM13.2917 11.9167H10.875V5.41667H13.2917V11.9167Z"
                          fill="#FF3737"
                        />
                      </svg>

                    </span>
                    You have Insufficient Balance to complete this transaction.

                    <RouterLink
                      to={ROUTES.WALLET}
                      className="ml-2 font-semibold text-tertiary-dark underline"
                    >
                      Add Token
                    </RouterLink>
                  </p>
                )}
                {inSufficientBalance ? (
                  <div className="flex justify-end items-center">
                    <BtnSmallDisabled onClick={() => 1}>
                      Pay
                    </BtnSmallDisabled>
                  </div>
                ) : (
                  // <div className="flex justify-end items-center mt-10">
                  //   <BtnSmall onClick={handleStepSecond}>
                  //     {miniLoader ? (
                  //       <div className="flex">
                  //         <svg
                  //           className="animate-spin ml-1 mr-3 h-5 w-5 "
                  //           xmlns="http://www.w3.org/2000/svg"
                  //           fill="none"
                  //           viewBox="0 0 24 24"
                  //         >
                  //           <circle
                  //             class="opacity-20"
                  //             cx="12"
                  //             cy="12"
                  //             r="10"
                  //             stroke="currentColor"
                  //             stroke-width="4"
                  //           ></circle>
                  //           <path
                  //             class="opacity-75"
                  //             fill="currentColor"
                  //             d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  //           ></path>
                  //         </svg>
                  //         Processing...
                  //       </div>
                  //     ) : (
                  //       "Pay"
                  //     )}
                  //   </BtnSmall>
                  // </div>
                  <div className="flex justify-end items-center gap-3 mt-10">
                    <BtnSmallWhite onClick={() => setCurrentStep(2)}>
                      Back
                    </BtnSmallWhite>
                    <BtnSmall onClick={handleStepSecond}>
                      {miniLoader ? (
                        <div className="flex">
                          <svg
                            className="animate-spin ml-1 mr-3 h-5 w-5 "
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <circle
                              class="opacity-20"
                              cx="12"
                              cy="12"
                              r="10"
                              stroke="currentColor"
                              stroke-width="4"
                            ></circle>
                            <path
                              class="opacity-75"
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                          </svg>
                          Processing...
                        </div>
                      ) : (
                        "Pay"
                      )}
                    </BtnSmall>
                  </div>
                )}
              </>
            ) : (
              <>
                <h2 className="text-heading font-semibold text-left mb-4">
                  Authorise Payment
                </h2>
                <p className="text-secondary-light text-h5 text-justify mb-8">
                  OTP has been sent to your registered mobile number and email
                  address. Please authorize the payment <br /> with the OTP.
                </p>
                <div className="flex items-center mb-8">
                  {otps.map((element, index) => {
                    return (
                      <input
                        type="text"
                        disabled={miniLoader}
                        key={index}
                        onChange={(e) => handleChnges(e.target, index)}
                        value={element}
                        name="otp"
                        maxLength="1"
                        onFocus={(e) => e.target.select()}
                        autoFocus={index === 0}
                        className="font-semibold text-heading text-h1 text-center mr-[19px] rounded-lg bg-primary-white w-[61.16px] h-[54px] border-1 border-solid border-[rgba(239, 239, 238, 0.9)] shadow-[0_3px_4px_3px_rgba(99,99,99,0.11)]"
                      />
                    );
                  })}
                </div>

                <p className="mb-6 text-left text-heading-false font-semibold text-p1">
                  Didn't receive the OTP?{" "}
                  <span className="text-secondary-light underline">
                    <Timeout handleClick={handleStepSecond} start={60} />
                  </span>
                </p>
                {otps[5] == "" ? (
                  <div className="flex justify-end items-center">
                    <BtnSmallDisabled
                      onClick={() =>
                        setModals((prevState) => ({
                          ...prevState,
                          addNew: false,
                          addendums: uploadByBook ? true : false,
                        }))
                      }
                    >
                      Submit
                    </BtnSmallDisabled>
                  </div>
                ) : (
                  <div className="flex justify-end">
                    <BtnSmall onClick={handleStepThird}>
                      {miniLoader ? (
                        <div className="flex">
                          <svg
                            className="animate-spin ml-1 mr-3 h-5 w-5 "
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <circle
                              class="opacity-20"
                              cx="12"
                              cy="12"
                              r="10"
                              stroke="currentColor"
                              stroke-width="4"
                            ></circle>
                            <path
                              class="opacity-75"
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                          </svg>
                          Processing...
                        </div>
                      ) : (
                        "Submit"
                      )}
                    </BtnSmall>
                  </div>
                )}
              </>
            )}
          </div>
        );
        break;

      case 4:
        setRenderedStep(
          <form className="scrollbar scrollbar-thumb-thumb scrollbar-track-track border-[1.5px] border-solid border-primary-dark-10 p-6  w-[902px] h-[370px] mt-3 mx-auto ">
            <h2 className="text-heading font-semibold text-left mb-8">
              Enter Addendums details
            </h2>
            <div className="w-full flex  justify-between items-center mb-6">
              <div className="flex flex-col">
                <label
                  className="text-heading-false font-semibold"
                  htmlFor="book_title"
                >
                  Addendum Title<span className="text-tertiary-dark">*</span>
                </label>
                <input
                  className="bg-input text-heading-false text-h5 w-[333px] h-[52px] pl-3 mt-2 border-[1.5px] border-solid border-primary-dark-10"
                  id="book_title"
                  name="title"
                  value={metaData.title}
                  onChange={handleChangeText}
                  type="text"
                  placeholder="Enter Addendum title"
                />
              </div>

              <div className="flex flex-col">
                <label
                  className="text-heading-false font-semibold"
                  htmlFor="publication"
                >
                  No. of pages<span className="text-tertiary-dark">*</span>
                </label>
                <input
                  className="bg-input text-heading-false text-h5 w-[333px] h-[52px] pl-3 mt-2 border-[1.5px] border-solid border-primary-dark-10"
                  id="noOfPages"
                  name="noOfPages"
                  disabled
                  type="number"
                  placeholder="Enter publication"
                  value={metaData?.noOfPages}
                  onChange={handleChangeText}
                />
              </div>
            </div>
            <div className="w-full flex  justify-between items-center mb-6">

              <div className="flex flex-col">
                <label
                  className="text-heading-false font-semibold flex"
                  htmlFor="book_title"
                >
                  Page number on parent book<span className="text-tertiary-dark">*</span>
                  <div style={{ marginTop: -20, marginLeft: 5, position: 'relative' }} >
                    <span
                      onMouseEnter={(e) =>
                        setHover((prevState) => ({
                          ...prevState,
                          pageNumber: true,
                        }))
                      }
                      className="ml-1"

                    >
                      <svg
                        width="20"
                        height="18"
                        viewBox="0 0 20 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10 18C12.6522 18 15.1957 17.0518 17.0711 15.364C18.9464 13.6761 20 11.3869 20 9C20 6.61305 18.9464 4.32387 17.0711 2.63604C15.1957 0.948212 12.6522 0 10 0C7.34784 0 4.8043 0.948212 2.92893 2.63604C1.05357 4.32387 0 6.61305 0 9C0 11.3869 1.05357 13.6761 2.92893 15.364C4.8043 17.0518 7.34784 18 10 18ZM11.1625 7.4115L9.9125 12.7046C9.825 13.0871 9.94875 13.3043 10.2925 13.3043C10.535 13.3043 10.9012 13.2255 11.15 13.0275L11.04 13.4955C10.6812 13.8848 9.89 14.1683 9.20875 14.1683C8.33 14.1683 7.95625 13.6935 8.19875 12.6844L9.12125 8.78288C9.20125 8.45325 9.12875 8.334 8.7625 8.25412L8.19875 8.163L8.30125 7.73438L11.1637 7.4115H11.1625ZM10 6.1875C9.66848 6.1875 9.35054 6.06897 9.11612 5.858C8.8817 5.64702 8.75 5.36087 8.75 5.0625C8.75 4.76413 8.8817 4.47798 9.11612 4.267C9.35054 4.05603 9.66848 3.9375 10 3.9375C10.3315 3.9375 10.6495 4.05603 10.8839 4.267C11.1183 4.47798 11.25 4.76413 11.25 5.0625C11.25 5.36087 11.1183 5.64702 10.8839 5.858C10.6495 6.06897 10.3315 6.1875 10 6.1875Z"
                          fill="#C5C5C5"
                        />
                      </svg>
                    </span>
                    {hover.pageNumber && (
                      <div
                        onClick={(e) => e.stopPropagation()}
                        className="p-3 absolute top-0 left-1 w-[276px] h-fit border-[0.5px] border-solid border-[rgba(182,182,182,0.33)] bg-[#fbfafb]"
                      >
                        <span className="absolute top-2 -left-3"></span>
                        <p className="text-secondary-light text-[13px] font-medium text-left mb-2">
                          Page number on the book where this adendum to be added. </p>

                        <button
                          onClick={(e) => {
                            e.preventDefault()
                            setHover((prevState) => ({
                              ...prevState,
                              pageNumber: false,
                            }))
                          }
                          }
                          className="absolute top-2 right-2"
                        >
                          <svg
                            width="5"
                            height="5"
                            viewBox="0 0 5 5"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M4.80964 4.82838C4.69011 4.93643 4.528 4.99714 4.35897 4.99714C4.18994 4.99714 4.02783 4.93643 3.9083 4.82838L2.50027 3.37344L1.09223 4.8279C1.03322 4.88212 0.962925 4.92524 0.885391 4.95478C0.807858 4.98432 0.724623 4.99969 0.640487 5C0.556352 5.00031 0.472981 4.98555 0.395184 4.95659C0.317387 4.92762 0.246703 4.88502 0.18721 4.83123C0.127716 4.77745 0.0805912 4.71354 0.048552 4.64321C0.0165128 4.57288 0.000193788 4.4975 0.00053638 4.42144C0.000878972 4.34538 0.0178763 4.27013 0.050548 4.20003C0.0832197 4.12994 0.130919 4.06638 0.190895 4.01304L1.65576 2.50048L0.190364 0.986961C0.130388 0.933616 0.0826886 0.870062 0.0500169 0.799967C0.0173452 0.729872 0.000347876 0.654623 5.28423e-06 0.578559C-0.000337307 0.502495 0.0159817 0.427123 0.0480209 0.35679C0.0800601 0.286457 0.127185 0.222554 0.186679 0.168769C0.246172 0.114983 0.316856 0.0723792 0.394653 0.0434138C0.47245 0.0144485 0.555821 -0.000304946 0.639956 4.77726e-06C0.724092 0.000314501 0.807327 0.0156811 0.88486 0.0452183C0.962394 0.0747555 1.03269 0.117879 1.0917 0.1721L2.50027 1.62752L3.9083 0.1721C3.96731 0.117879 4.03761 0.0747555 4.11514 0.0452183C4.19267 0.0156811 4.27591 0.000314501 4.36004 4.77726e-06C4.44418 -0.000304946 4.52755 0.0144485 4.60535 0.0434138C4.68314 0.0723792 4.75383 0.114983 4.81332 0.168769C4.87281 0.222554 4.91994 0.286457 4.95198 0.35679C4.98402 0.427123 5.00034 0.502495 4.99999 0.578559C4.99965 0.654623 4.98265 0.729872 4.94998 0.799967C4.91731 0.870062 4.86961 0.933616 4.80964 0.986961L3.34477 2.50048L4.80964 4.01304C4.8689 4.06655 4.91591 4.1301 4.94798 4.20005C4.98006 4.27001 4.99657 4.34499 4.99657 4.42071C4.99657 4.49643 4.98006 4.57141 4.94798 4.64136C4.91591 4.71132 4.8689 4.77487 4.80964 4.82838Z"
                              fill="black"
                              fill-opacity="0.53"
                            />
                          </svg>
                        </button>
                      </div>
                    )}
                  </div>
                </label>
                <input
                  className="bg-input text-heading-false text-h5 w-[333px] h-[52px] pl-3 mt-2 border-[1.5px] border-solid border-primary-dark-10"
                  id="book_title"
                  name="parentPageNo"
                  value={metaData.parentPageNo}
                  onChange={handleChangeText}
                  type="text"
                  placeholder="Enter page number"
                />
              </div>

            </div>
            <div className="flex mt-8 justify-end items-center">
              {validateStep4 ? (
                <BtnSmallDisabled disabled style={{ cursor: "not-allowed" }} >Next</BtnSmallDisabled>
              ) : (
                <BtnSmall
                  onClick={(e) => {

                    e.preventDefault();

                    // inorder to prevent adendum attaching page going greater than total count of parent book
                    if(Number(metaData.parentPageNo)<=metaData.parentBookCountOfPages){
                      !validateStep4 && setCurrentStep(5);
                    }else{
                      notify("Parent page number shouldn't exceed total pages of book.");

                    }
                  }}
                >
                  Next
                </BtnSmall>
              )}
            </div>
          </form>
        );
        break;

      /// 4!!! end step -------------------------------------------------
      // change in 5 div width 902 to 950px
      case 5:
        setRenderedStep(
          <div className="overflow-x-hidden flex flex-col w-[950px] h-[370px] mx-auto border-[1.5px] border-solid border-primary-dark-10 scrollbar scrollbar-thumb-thumb scrollbar-track-track">
            {/* <h2 className="text-heading text-h1 font-semibold text-left mt-4 pl-6 mb-0">
              Summary
            </h2> */}
            {/* <div className="flex  justify-center p-4  w-full h-[370px] mt-3 mx-auto "> */}
            {/* <div className="w-1/3 ">
                <div className="relative h-[361px] w-[278px] mx-auto drop-shadow-[15px_15px_10px_rgba(0,0,0,0.25)]">
                  <img src={Book} alt="book" />
                  <button className="rounded-md w-[133px] h-8 absolute bottom-12 left-[68px] bg-[#979797] text-primary-white ">
                    Page 1 of {metaData.noOfPages}
                  </button>
                </div>
              </div> */}
            {/* </div> */}
            <div className="p-6  w-[902px] h-[370px] mt-0 mx-auto ">
              <h2 className="text-heading text-h1 font-semibold text-left mb-8">
                Addendum details
              </h2>
              <div className="w-full flex justify-between items-center mb-6">
                <div className="flex">
                  <p className="text-h5 text-secondary-dark font-semibold mr-16">

                    Book Title<span className="text-tertiary-dark">*</span>
                  </p>
                  <h4 className="text-h5 text-heading font-semibold">
                    {metaData.title}
                  </h4>
                </div>
                <div className="w-2/3 px-10 pt-4 text-left ">
                  <div className="flex mb-3">
                    <p className="text-h5 text-secondary-dark font-semibold mr-16">
                      No. of pages
                    </p>
                    <h4 className="text-h5 text-heading font-semibold">
                      {metaData.noOfPages}
                    </h4>
                  </div>
                </div>
              </div>

              <div className="flex justify-end mt-[7.5rem] pb-4">
                <BtnSmallWhite
                  onClick={() => {
                    setModals((prevState) => ({
                      ...prevState,
                      uploadByBook: false,
                    }));
                    setShowUploadBook(false)
                    setCancelNewBook(true);
                  }}
                >
                  Cancel
                </BtnSmallWhite>
                <span className="mx-3"></span>
                <BtnSmall onClick={handleActivate}>
                  {miniLoader ? (
                    <div className="flex">
                      <svg
                        className="animate-spin ml-1 mr-3 h-5 w-5 "
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          class="opacity-20"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          stroke-width="4"
                        ></circle>
                        <path
                          class="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                      Processing...
                    </div>
                  ) : (
                    "Activate"
                  )}
                </BtnSmall>
              </div>
            </div>
          </div>
        );
        break;

      default:
        break;
    }
  }, [
    resend,
    currentStep,
    paid,
    metaData,
    otps,
    category,
    author,
    publication,
    // confirmOTP,
    hover,
    uploading,
    // otp,
    showCategorySuggestion,
    filterData,
    filterSort,
  ]);

  /*==================================== stepper ends============================*/

  return (
    <>
      <DialogBox show={modals.addendums && showPopup}>
        <div className="bg-primary-white flex flex-col justify-between relative w-[1090px] p-8">
          <h4 className=" text-h4 text-tertiary-dark font-bold">
            <button onClick={() => { }} className="mr-3">
              <svg
                width="10"
                height="17"
                viewBox="0 0 10 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.71183 16.3754C7.53132 16.376 7.35295 16.3389 7.18985 16.2669C7.02675 16.1948 6.88306 16.0897 6.76934 15.9592L0.933085 9.20918C0.755361 9.00788 0.658203 8.75539 0.658203 8.49481C0.658203 8.23423 0.755361 7.98173 0.933085 7.78043L6.97475 1.03043C7.17985 0.800689 7.47458 0.656211 7.79409 0.628784C8.11361 0.601357 8.43174 0.693226 8.6785 0.884182C8.92526 1.07514 9.08044 1.34954 9.1099 1.64702C9.13936 1.9445 9.04069 2.24069 8.83559 2.47043L3.43433 8.50043L8.65434 14.5304C8.80209 14.6956 8.89595 14.8966 8.92481 15.1099C8.95366 15.3231 8.9163 15.5396 8.81715 15.7337C8.718 15.9278 8.56121 16.0914 8.36533 16.2051C8.16944 16.3188 7.94267 16.3779 7.71183 16.3754Z"
                  fill="#494949"
                />
              </svg>
            </button>
            Add a New Addendum for this Book
          </h4>
          <div className="flex justify-between items-center my-12">
            <div className="relative rounded-full w-12 h-12 bg-primary-dark border-2 border-solid border-primary-light flex justify-center items-center">
              <svg
                width="27"
                height="23"
                viewBox="0 0 27 23"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.833 1.91699H4.94775V21.0837H22.3641V7.66699L15.833 1.91699ZM14.7445 14.3753V18.2087H12.5674V14.3753H9.30185L13.6668 10.542L18.01 14.3753H14.7445ZM14.7445 8.62533V3.35449L20.7314 8.62533H14.7445Z"
                  fill="white"
                />
              </svg>

              <p className="text-heading font-semibold text-p1 absolute -bottom-8 -left-1">
                Upload
              </p>
            </div>
            <div
              style={{
                background:
                  currentStep === 1
                    ? "linear-gradient(90deg, #702963, #fff)"
                    : currentStep > 1
                      ? "#702963"
                      : "#c2c2bd",
              }}
              className="w-progress-chart-1 h-1 bg-primary-dark"
            ></div>
            <div
              className={`relative rounded-full w-12 h-12 ${currentStep > 1 ? "bg-primary-dark" : "bg-[#c2c2bd]"
                } border-2 border-solid border-primary-light flex justify-center items-center`}
            >
              <svg
                width="21"
                height="24"
                viewBox="0 0 21 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M5.06866 0.916992H13.9129L20.0359 6.16699V19.5837C20.0359 19.8931 19.8925 20.1898 19.6374 20.4086C19.3822 20.6274 19.0361 20.7503 18.6752 20.7503H5.06866C4.7078 20.7503 4.36171 20.6274 4.10654 20.4086C3.85136 20.1898 3.70801 19.8931 3.70801 19.5837V2.08366C3.70801 1.77424 3.85136 1.47749 4.10654 1.2587C4.36171 1.03991 4.7078 0.916992 5.06866 0.916992ZM17.9949 6.16699L13.9129 2.66699V5.58366C13.9129 5.73837 13.9846 5.88674 14.1122 5.99614C14.2398 6.10553 14.4128 6.16699 14.5933 6.16699H17.9949ZM16.4349 10.4212C16.5624 10.5306 16.6341 10.679 16.6341 10.8337C16.6341 10.9883 16.5624 11.1367 16.4349 11.2461L10.5113 16.3252L7.30898 13.5794C7.18506 13.4694 7.11648 13.322 7.11803 13.1691C7.11958 13.0161 7.19113 12.8698 7.31727 12.7617C7.44341 12.6535 7.61405 12.5922 7.79243 12.5908C7.97081 12.5895 8.14266 12.6483 8.27097 12.7546L10.5113 14.6755L15.4729 10.4212C15.6005 10.3119 15.7735 10.2505 15.9539 10.2505C16.1343 10.2505 16.3073 10.3119 16.4349 10.4212Z"
                  fill="white"
                />
                <path
                  d="M2.34747 4.41699H0.986816V20.167C0.986816 20.9405 1.3452 21.6824 1.98313 22.2294C2.62107 22.7764 3.48629 23.0837 4.38846 23.0837H17.3147V21.917H4.38846C3.84715 21.917 3.32802 21.7326 2.94526 21.4044C2.5625 21.0762 2.34747 20.6311 2.34747 20.167V4.41699Z"
                  fill="white"
                />
              </svg>

              <p className="text-heading font-semibold text-p1 absolute -bottom-8 -left-8 w-28">
                Accept Charges
              </p>
            </div>
            <div
              style={{
                background:
                  currentStep === 2
                    ? "linear-gradient(90deg, #702963, #fff)"
                    : currentStep > 2
                      ? "#702963"
                      : "#c2c2bd",
              }}
              className="w-progress-chart-1 h-1 bg-primary-dark"
            ></div>
            <div
              className={`relative rounded-full w-12 h-12 ${currentStep > 2 ? "bg-primary-dark" : "bg-[#c2c2bd]"
                } border-2 border-solid border-primary-light flex justify-center items-center`}
            >
              {/* <svg
                width="35"
                height="26"
                viewBox="0 0 35 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.1106 3.25H26.1707L24.7647 5.41667H20.1811C20.856 6.045 21.3622 6.78167 21.6574 7.58333H26.1707L24.7647 9.75H21.9527C21.7784 11.1007 21.017 12.3663 19.792 13.3417C18.5669 14.317 16.9501 14.9449 15.2038 15.1233V15.1667H14.2196L22.6557 22.75H19.1407L10.7046 15.1667V13H14.2196C16.6942 13 18.747 11.5917 19.0844 9.75H10.7046L12.1106 7.58333H18.6626C17.8752 6.305 16.188 5.41667 14.2196 5.41667H10.7046L12.1106 3.25Z"
                  fill="white"
                />
              </svg> */}

              <svg
                width="19"
                height="20"
                viewBox="0 0 19 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.7382 10.9526V13.8098C16.7382 15.0479 13.9027 16.6669 10.4049 16.6669C6.90706 16.6669 4.07153 15.0479 4.07153 13.8098V11.4288"
                  stroke="white"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M4.3374 11.6755C5.12002 12.7688 7.54026 13.7945 10.4047 13.7945C13.9025 13.7945 16.7381 12.265 16.7381 10.9507C16.7381 10.2126 15.8451 9.40404 14.4436 8.83643"
                  stroke="white"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M14.0238 6.19043V9.04757C14.0238 10.2857 11.1883 11.9047 7.69051 11.9047C4.1927 11.9047 1.35718 10.2857 1.35718 9.04757V6.19043"
                  stroke="white"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M7.69051 9.03254C11.1883 9.03254 14.0238 7.50302 14.0238 6.18873C14.0238 4.8735 11.1883 3.3335 7.69051 3.3335C4.1927 3.3335 1.35718 4.8735 1.35718 6.18873C1.35718 7.50302 4.1927 9.03254 7.69051 9.03254Z"
                  fill="white"
                  stroke="white"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <p className="text-heading font-semibold text-center text-p1 absolute -bottom-8 left-[-0.5rem]">
                Payment
              </p>
            </div>
            <div
              style={{
                background:
                  currentStep === 3
                    ? "linear-gradient(90deg, #702963, #fff)"
                    : currentStep > 3
                      ? "#702963"
                      : "#c2c2bd",
              }}
              className="w-progress-chart-1 h-1 bg-primary-dark"
            ></div>
            <div
              className={`relative rounded-full w-12 h-12 ${currentStep > 3 ? "bg-primary-dark" : "bg-[#c2c2bd]"
                } border-2 border-solid border-primary-light flex justify-center items-center`}
            >
              <svg
                width="34"
                height="27"
                viewBox="0 0 34 27"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19.7672 9H7.06775C6.82717 9 6.59644 8.92098 6.42633 8.78033C6.25621 8.63968 6.16064 8.44891 6.16064 8.25V5.25C6.16064 5.05109 6.25621 4.86032 6.42633 4.71967C6.59644 4.57902 6.82717 4.5 7.06775 4.5H19.7672C20.0078 4.5 20.2385 4.57902 20.4086 4.71967C20.5787 4.86032 20.6743 5.05109 20.6743 5.25V8.25C20.6743 8.44891 20.5787 8.63968 20.4086 8.78033C20.2385 8.92098 20.0078 9 19.7672 9ZM7.97485 7.5H18.8601V5.955H7.97485V7.5Z"
                  fill="white"
                />
                <path
                  d="M19.7672 10.5596H7.06775C6.82717 10.5596 6.59644 10.6386 6.42633 10.7792C6.25621 10.9199 6.16064 11.1107 6.16064 11.3096V14.2496C6.16064 14.4485 6.25621 14.6392 6.42633 14.7799C6.59644 14.9206 6.82717 14.9996 7.06775 14.9996H17.3725L20.6743 12.2246V11.3096C20.6743 11.1107 20.5787 10.9199 20.4086 10.7792C20.2385 10.6386 20.0078 10.5596 19.7672 10.5596ZM18.8601 13.4996H7.97485V11.9996H18.8601V13.4996Z"
                  fill="white"
                />
                <path
                  d="M10.7506 23.6325V23.5875L11.0409 22.545H4.34643V3H22.4885V10.6875L24.3027 9.27V2.25C24.3027 2.05109 24.2072 1.86032 24.037 1.71967C23.8669 1.57902 23.6362 1.5 23.3956 1.5H3.43933C3.19875 1.5 2.96803 1.57902 2.79791 1.71967C2.6278 1.86032 2.53223 2.05109 2.53223 2.25V23.25C2.53223 23.4489 2.6278 23.6397 2.79791 23.7803C2.96803 23.921 3.19875 24 3.43933 24H10.6962C10.7036 23.8766 10.7218 23.7538 10.7506 23.6325Z"
                  fill="white"
                />
                <path
                  d="M20.6744 14.3779L19.9668 14.9704C20.1423 14.9405 20.3032 14.8683 20.4292 14.7628C20.5551 14.6574 20.6404 14.5234 20.6744 14.3779Z"
                  fill="white"
                />
                <path
                  d="M6.16064 20.205C6.16064 20.4039 6.25621 20.5947 6.42633 20.7353C6.59644 20.876 6.82717 20.955 7.06775 20.955H11.4581L11.7303 19.98L11.8482 19.5675V19.53H7.97485V18H13.7259L15.5401 16.5H7.06775C6.82717 16.5 6.59644 16.579 6.42633 16.7197C6.25621 16.8603 6.16064 17.0511 6.16064 17.25V20.205Z"
                  fill="white"
                />
                <path
                  d="M31.097 12.5025L28.04 9.97496C27.9044 9.86248 27.7432 9.77324 27.5657 9.71235C27.3883 9.65146 27.198 9.62012 27.0059 9.62012C26.8138 9.62012 26.6236 9.65146 26.4461 9.71235C26.2687 9.77324 26.1075 9.86248 25.9718 9.97496L13.5354 20.3175L12.5104 23.925C12.472 24.0806 12.4712 24.241 12.5079 24.3969C12.5446 24.5528 12.6181 24.7012 12.7243 24.8335C12.8304 24.9659 12.9672 25.0797 13.1266 25.1683C13.286 25.2569 13.465 25.3186 13.6533 25.35C13.7468 25.3577 13.841 25.3577 13.9345 25.35C14.0457 25.3649 14.159 25.3649 14.2702 25.35L18.6696 24.5475L31.097 14.25C31.2327 14.1384 31.3405 14.0058 31.414 13.8597C31.4875 13.7136 31.5254 13.5569 31.5254 13.3987C31.5254 13.2405 31.4875 13.0838 31.414 12.9377C31.3405 12.7916 31.2327 12.659 31.097 12.5475V12.5025ZM17.7444 23.1825L14.4244 23.79L15.2317 21.0675L24.5567 13.275L27.1148 15.39L17.7444 23.1825ZM28.1398 14.5425L25.5818 12.4275L27.0241 11.25L29.6002 13.38L28.1398 14.5425Z"
                  fill="white"
                />
              </svg>
              <p className="text-heading whitespace-nowrap text-center font-semibold text-p1 absolute -bottom-8 -left-6 w-20">
                Addendums data
              </p>
            </div>

            <div
              style={{
                background:
                  currentStep === 4
                    ? "linear-gradient(90deg, #702963, #fff)"
                    : currentStep > 4
                      ? "#702963"
                      : "#c2c2bd",
              }}
              className="w-progress-chart-1 h-1 "
            ></div>
            <div
              className={`relative rounded-full w-12 h-12 ${currentStep > 4 ? "bg-primary-dark" : "bg-[#c2c2bd]"
                } border-2 border-solid border-primary-light flex justify-center items-center`}
            >
              <svg
                width="27"
                height="22"
                viewBox="0 0 27 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_1608_8310)">
                  <path
                    fillRule="evenodd"
                    clip-rule="evenodd"
                    d="M0.574219 11C0.574219 8.08262 1.95042 5.28473 4.40008 3.22183C6.84973 1.15893 10.1722 0 13.6365 0C17.1009 0 20.4233 1.15893 22.873 3.22183C25.3226 5.28473 26.6988 8.08262 26.6988 11C26.6988 13.9174 25.3226 16.7153 22.873 18.7782C20.4233 20.8411 17.1009 22 13.6365 22C10.1722 22 6.84973 20.8411 4.40008 18.7782C1.95042 16.7153 0.574219 13.9174 0.574219 11H0.574219ZM12.8911 15.708L20.4115 7.79093L19.053 6.87573L12.6403 13.6239L8.0981 10.4368L6.98345 11.5632L12.8911 15.7095V15.708Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1608_8310">
                    <rect
                      width="26.1246"
                      height="22"
                      fill="white"
                      transform="translate(0.574219)"
                    />
                  </clipPath>
                </defs>
              </svg>

              <p className="text-heading font-semibold text-p1 absolute -bottom-8 -left-2">
                Overview
              </p>
            </div>
          </div>

          {renderedStep}

          <button
            onClick={() => {
              setShowPopup(false);
              setModals &&
                setModals((prevState) => ({
                  ...prevState,
                  addendums: false,
                }));
              active((prev) => ({ ...prev, addNew: false }))
              setCurrentStep(1);
            }}
            className="absolute top-8 right-8"
          >
            <svg
              width="12"
              height="14"
              viewBox="0 0 12 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.2176 12.8188C10.9475 13.0888 10.5813 13.2405 10.1994 13.2405C9.81747 13.2405 9.45122 13.0888 9.18117 12.8188L5.99997 9.18281L2.81877 12.8176C2.68545 12.9531 2.52663 13.0609 2.35145 13.1347C2.17628 13.2085 1.98823 13.2469 1.79814 13.2477C1.60805 13.2485 1.41969 13.2116 1.24392 13.1392C1.06815 13.0668 0.908454 12.9604 0.77404 12.8259C0.639626 12.6915 0.533155 12.5318 0.460769 12.3561C0.388382 12.1803 0.351512 11.9919 0.352286 11.8018C0.35306 11.6118 0.391462 11.4237 0.465278 11.2485C0.539094 11.0734 0.646862 10.9145 0.782367 10.7812L4.09197 7.00121L0.781167 3.21881C0.645662 3.0855 0.537894 2.92667 0.464078 2.7515C0.390263 2.57633 0.35186 2.38827 0.351086 2.19818C0.350312 2.00809 0.387182 1.81973 0.459569 1.64397C0.531955 1.4682 0.638426 1.3085 0.77284 1.17409C0.907255 1.03967 1.06695 0.933201 1.24272 0.860814C1.41849 0.788427 1.60685 0.751557 1.79694 0.752331C1.98703 0.753105 2.17508 0.791508 2.35025 0.865323C2.52543 0.939139 2.68425 1.04691 2.81757 1.18241L5.99997 4.81961L9.18117 1.18241C9.31448 1.04691 9.47331 0.939139 9.64848 0.865323C9.82365 0.791508 10.0117 0.753105 10.2018 0.752331C10.3919 0.751557 10.5802 0.788427 10.756 0.860814C10.9318 0.933201 11.0915 1.03967 11.2259 1.17409C11.3603 1.3085 11.4668 1.4682 11.5392 1.64397C11.6116 1.81973 11.6484 2.00809 11.6476 2.19818C11.6469 2.38827 11.6085 2.57633 11.5347 2.7515C11.4608 2.92667 11.3531 3.0855 11.2176 3.21881L7.90797 7.00121L11.2176 10.7812C11.3515 10.915 11.4577 11.0738 11.5301 11.2486C11.6026 11.4234 11.6399 11.6108 11.6399 11.8C11.6399 11.9893 11.6026 12.1766 11.5301 12.3514C11.4577 12.5263 11.3515 12.6851 11.2176 12.8188Z"
                fill="black"
                fillOpacity="0.53"
              />
            </svg>
          </button>
        </div>
      </DialogBox>
      <DialogBox show={newBookAdded}>
        <PopUp
          height={true}
          style={{ bottom: "0px" }}
          onClick={() => {
            setNewBookAdded(false);
            setCurrentStep(1);
            setModals((prev) => ({
              ...prev,
            }));
          }}
          onClose={() => {
            setNewBookAdded(false);
            setCurrentStep(1);
          }}
          button="Done"
          title="New Book Added Successfully!"
          main="Congratulations! Your Ebook has been created, please visit the Catalogue Book Page to view it."
          body="You will be able to create AccessIDs for the books &#38; then distribute then same by creating OrderIDs"
        />
      </DialogBox>
      <DialogBox show={paymentSuccessfully}>
        <PopUp
          height={true}
          style={{ bottom: "0px" }}
          onClick={() => {
            setPaymentSuccessfull(false);
            setCurrentStep(1);
            // setModals((prev) => ({
            //   ...prev,
            // }));
          }}
          onClose={() => {
            setNewBookAdded(false);
            setCurrentStep(1);
          }}
          button="Close"
          title="Payment Successfully"
          main="Your Addendum is under going conversion"
          body=" Checkout draft to complete the last step. we will notify you once the Book is Converted."
        />
      </DialogBox>

      <DialogBox show={cancelNewBook}>
        <div className="bottom-0 bg-primary-white flex flex-col gap-10 justify-between relative w-full h-[100%] lg:w-pop-up p-8">
          <h4 className="text-h4 text-tertiary-dark font-bold">
            Are you sure you want to leave?
          </h4>

          <div className="flex gap-2 lg:justify-center text-center lg:text-right">
            <BtnSmallWhite
              onClick={() => {
                setCancelNewBook(false);
                setShowUploadBook(true);
              }}
            >
              Cancel
            </BtnSmallWhite>

            <button
              onClick={() => {
                setCancelNewBook(false);
                setModals((prev) => ({ ...prev, addendums: false }));
              }}
              className="w-full max-w-[372px] lg:w-[150px] h-[56px] bg-primary-dark text-primary-white text-mobile-h5 lg:text-h5"
            >
              Confirm
            </button>
          </div>
          <button
            onClick={() => {
              setCancelNewBook(false);
              setShowUploadBook(true);
              setCurrentStep(1);
            }}
            className="absolute top-8 right-8"
          >
            <svg
              width="12"
              height="14"
              viewBox="0 0 12 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.2176 12.8188C10.9475 13.0888 10.5813 13.2405 10.1994 13.2405C9.81747 13.2405 9.45122 13.0888 9.18117 12.8188L5.99997 9.18281L2.81877 12.8176C2.68545 12.9531 2.52663 13.0609 2.35145 13.1347C2.17628 13.2085 1.98823 13.2469 1.79814 13.2477C1.60805 13.2485 1.41969 13.2116 1.24392 13.1392C1.06815 13.0668 0.908454 12.9604 0.77404 12.8259C0.639626 12.6915 0.533155 12.5318 0.460769 12.3561C0.388382 12.1803 0.351512 11.9919 0.352286 11.8018C0.35306 11.6118 0.391462 11.4237 0.465278 11.2485C0.539094 11.0734 0.646862 10.9145 0.782367 10.7812L4.09197 7.00121L0.781167 3.21881C0.645662 3.0855 0.537894 2.92667 0.464078 2.7515C0.390263 2.57633 0.35186 2.38827 0.351086 2.19818C0.350312 2.00809 0.387182 1.81973 0.459569 1.64397C0.531955 1.4682 0.638426 1.3085 0.77284 1.17409C0.907255 1.03967 1.06695 0.933201 1.24272 0.860814C1.41849 0.788427 1.60685 0.751557 1.79694 0.752331C1.98703 0.753105 2.17508 0.791508 2.35025 0.865323C2.52543 0.939139 2.68425 1.04691 2.81757 1.18241L5.99997 4.81961L9.18117 1.18241C9.31448 1.04691 9.47331 0.939139 9.64848 0.865323C9.82365 0.791508 10.0117 0.753105 10.2018 0.752331C10.3919 0.751557 10.5802 0.788427 10.756 0.860814C10.9318 0.933201 11.0915 1.03967 11.2259 1.17409C11.3603 1.3085 11.4668 1.4682 11.5392 1.64397C11.6116 1.81973 11.6484 2.00809 11.6476 2.19818C11.6469 2.38827 11.6085 2.57633 11.5347 2.7515C11.4608 2.92667 11.3531 3.0855 11.2176 3.21881L7.90797 7.00121L11.2176 10.7812C11.3515 10.915 11.4577 11.0738 11.5301 11.2486C11.6026 11.4234 11.6399 11.6108 11.6399 11.8C11.6399 11.9893 11.6026 12.1766 11.5301 12.3514C11.4577 12.5263 11.3515 12.6851 11.2176 12.8188Z"
                fill="black"
                fillOpacity="0.53"
              />
            </svg>
          </button>
        </div>
      </DialogBox>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        closeOnClick
        pauseOnFocusLoss
        pauseOnHover
      />

      {/* <Loader show={true} /> */}
    </>
  );
}
