import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Loader,
  Navbar,
  Page,
  BtnSmall,
  DialogBox,
  PopUp
} from "../components";
import { ToastContainer, toast } from "react-toastify";
import {
  addBooksInCart,
  getAdendumTotalPages,
  getBookDetailsById
} from "../assets/Js/script";
import TopBar from "../components/TopBar";
import Book from "../assets/images/book.png";
import Addendums from "./add_Addendums";
import * as ROUTES from "../constant/routes";
import CovertIco from "../assets/images/convert.png";
import CovertIcoGrey from "../assets/images/convert_grey.png";

import PrintIco from "../assets/images/print.png";
import ConvertToQrModal from "./components/ConvertToQrModal";
import { ddmmyyyyhhmm } from "../helpers/ddmmyyyyhhmm";
import PrintQrConfigModal from "./components/PrintQrConfigModal";
import ReactTooltip from "react-tooltip";
import { getOrdinalSuffix } from "../helpers/getOrdinalSuffix";

export default function Catalogue() {
  const navigate = useNavigate();
  const params = useParams();

  const [loading, setLoading] = useState(false);
  const [book, setBook] = useState({});
  const [selectedTab, setSelectedTab] = useState("Book Details");
  const [modals, setModals] = useState({});
  const [accessCodes, setAccessCodes] = useState();
  const [selectedData, setSelectedData] = useState();
  const [qrCodeDetails, setQrCodeDetails] = useState([]);

  const onAddAdendumClick = async () => {
    let { response } = await getAdendumTotalPages(params.bookId);
    if (response.data.totalPages < 250) {
      setModals((prevState) => ({ ...prevState, addNew: true }));
      console.log(response.data.totalPages);
    } else {
      toast("Maximum pages of adendum reached.");
    }
  };

  const getBookDetails = async () => {
    setLoading(true);
    const { response } = await getBookDetailsById(params.bookId);
    if (response.status === "success") {
      setBook(response.data.bookDetail);
      setAccessCodes(response.data.accessIdDetails);
      setQrCodeDetails(response.data.qrCodeDetails);

      console.log(response, "resss ");
      setLoading(false);
    } else {
      setLoading(false);
      toast(response?.message ?? "Something went wrong");
    }
  };
  function calculateExpiry(expireDate, createdDate) {
    console.log(expireDate, createdDate, "aaaa");
    const date1 = Date.parse(expireDate);

    const date2 = Date.parse(new Date().toString());

    const difference = date1 - date2;

    const day = Math.round(difference / (3600000 * 24));

    const percent = day / (book.validity * 365);

    return { day, percent: percent * 100 };
  }

  async function handleGenerate() {
    const { response, error } = await addBooksInCart(
      [{ parentId: params.bookId, validity: book.validity }],
      true
    );
    if (response.status === "success") {
      navigate(ROUTES.GENERATE_ACCESS_IDS);
    } else {
      toast(response?.message ? response?.message : "Something went wrong");
    }
  }

  const onCompletePrint = () => {
    let tmpQrCodeDetails = [...qrCodeDetails];
    tmpQrCodeDetails.forEach((a, id) => {
      if (a.BatchId == selectedData.BatchId) {
        tmpQrCodeDetails[id].IsPrint = true;
      }
    });
    setQrCodeDetails(tmpQrCodeDetails)
  };

  const BookDetails = () => {
    const [selectedCover, setSelectedCover] = useState(book?.imageCoverFront);
    const [seeMore, setSeeMore] = useState(false);
    return (
      <div className="mt-5 flex  ">
        <div className="w-1/4">
          <img
            className="object-cover"
            src={`https://${process.env.REACT_APP_AWS_BUCKET_NAME}.s3.amazonaws.com/books/${selectedCover}`}
            alt="book front cover"
          />
          <div className="flex flex-col lg:flex-row ml-3 lg:ml-0 lg:mt-4">
            <div
              className="overflow-hidden lg:ml-1 lg:mr-4 lg:w-[58px] cursor-pointer"
              onClick={() => setSelectedCover(book?.imageCoverFront)}
            >
              <img
                className="h-full object-cover"
                src={`https://${process.env.REACT_APP_AWS_BUCKET_NAME}.s3.amazonaws.com/books/${book?.imageCoverFront}`}
                alt="front cover"
              />
            </div>
            {book?.imageCoverBack && (
              <div
                className="overflow-hidden lg:mr-4 lg:w-[58px] cursor-pointer"
                onClick={() => setSelectedCover(book?.imageCoverBack)}
              >
                <img
                  className="h-full object-cover "
                  src={`https://${process.env.REACT_APP_AWS_BUCKET_NAME}.s3.amazonaws.com/books/${book?.imageCoverBack}`}
                  alt="back cover"
                />
              </div>
            )}
          </div>
        </div>
        <div className="w-3/4 ml-2">
          <h1 className="flex items-center text-mobile-h1  lg:text-h1 text-tertiary-dark lg:text-heading text-left font-bold lg:font-semibold">
            {book?.title}
          </h1>
          {!(
            book?.shortDescription == null || book?.longDescription == null
          ) && (
            <>
              <p className="text-tertiary-dark capitalize lg:uppercase tracking-wide font-bold lg:font-semibold text-h5 mt-4">
                description
              </p>
              <p className="text-secondary-dark text-p1 my-2 lg:my-1 lg:text-h5 tracking-[0.01em] leading-6 text-left">
                {book?.shortDescription}
                {seeMore && book.longDescription}
                {book.longDescription && (
                  <span
                    onClick={() => setSeeMore((prev) => !prev)}
                    className="cursor-pointer text-tertiary-dark underline"
                  >
                    {" "}
                    {!seeMore ? "Read more" : "Read less"}
                  </span>
                )}
              </p>
            </>
          )}
        </div>
      </div>
    );
  };

  const AdendumDetails = () => {
    return (
      <div>
        <h2 className="hidden lg:block uppercase lg:text-laptop-h3 xl:text-h4 text-tertiary-dark font-semibold text-left pt-4 mb-4">
          ADDENDUMS
        </h2>
        <p className="hidden lg:block text-h5 text-secondary-dark tracking-wide text-left mb-4">
          A. New Notifications/Chapters can be added as an Addendum to an Active
          Book
          <br />
          (Note: Addendum cannot be added to an Expired Book)
          <br />
          B. Users having active subscriptions will get notified once the
          addendum is added. They can view and read the same within the EBook
        </p>
        {book.Addendums.length > 0 ? (
          <div className="flex flex-wrap gap-3">
            {book.Addendums.map((data, index) => (
              <div className="flex justify-between flex-col w-[155px]  bg-[#70296313]">
                <div className="flex justify-center w-full items-center">
                  <img
                    src={Book}
                    alt="pages"
                    className="w-[139px] mt-2 h-[155px] object-cover"
                  />
                </div>
                <div className="p-2 flex flex-col w-full bg-[#70296313]  ">
                  <p className="text-ellipsis w-full whitespace-nowrap overflow-hidden font-[600] text-[#343434] text-[15px] ">
                    {data.title}
                  </p>
                  <p className=" text-[#343434] text-[12px] ">
                    {data.countOfPages} Pages
                  </p>
                  <p className=" text-[#343434] text-[12px] ">
                    At {getOrdinalSuffix(data.parentPageNo)} page of book
                  </p>
                </div>
              </div>
            ))}
            <div
              onClick={onAddAdendumClick}
              className="flex justify-center items-center flex-col w-[155px]  bg-[#70296313]"
            >
              <button className="flex justify-center items-center">
                <svg
                  width="52"
                  height="50"
                  viewBox="0 0 52 50"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M25.9732 0.5C19.2921 0.578758 12.9073 3.20698 8.18264 7.82327C3.45801 12.4396 0.768106 18.6779 0.6875 25.2059C0.768106 31.7338 3.45801 37.9722 8.18264 42.5885C12.9073 47.2048 19.2921 49.833 25.9732 49.9118C32.6544 49.833 39.0392 47.2048 43.7638 42.5885C48.4884 37.9722 51.1783 31.7338 51.2589 25.2059C51.1783 18.6779 48.4884 12.4396 43.7638 7.82327C39.0392 3.20698 32.6544 0.578758 25.9732 0.5ZM40.4222 26.9706H27.7793V39.3235H24.1671V26.9706H11.5242V23.4412H24.1671V11.0882H27.7793V23.4412H40.4222V26.9706Z"
                    fill="#702963"
                    fill-opacity="0.21"
                  />
                </svg>
              </button>
              <p className="text-[#6a6a6a] text-[20px] font-[700] mt-3 ">
                Add Addendum
              </p>
            </div>
          </div>
        ) : (
          <BtnSmall onClick={onAddAdendumClick}>
            <span className=" lg:hidden mr-1">+</span>Add Addendum{" "}
            <span className="hidden lg:block"> +</span>
          </BtnSmall>
        )}

        {modals.addNew && (
          <Addendums active={setModals} parentId={book?.parentId} />
        )}
      </div>
    );
  };

  const AccessCodeDetails = () => {
    return (
      <div>
        <h3 className="capitalize lg:uppercase lg:text-laptop-h3 xl:text-h5 text-tertiary-dark text-left my-4 text-[18px] font-bold lg:font-semibold">
          Access Id
        </h3>
        <p className="text-h5 text-secondary-dark tracking-wide text-left mb-4">
          A. Access Id Validity can be less than or equal to the Book Validity{" "}
          <br />
          B. One Access Id provides Access of this Book for the Mentioned Access
          Code Validity to One User
        </p>

        <div class="relative overflow-x-auto">
          <table class="w-full border-collapse text-s text-[#6A6A6A] text-center align-middle">
            <thead class="bg-neutral-10 text-secondary-dark ">
              <tr>
                <th
                  scope="col"
                  rowSpan="2"
                  class="px-6 py-1  border border-[#AEAEAE]"
                >
                  Quantity
                </th>
                <th
                  scope="col"
                  rowSpan="2"
                  class="px-6 py-1  border border-[#AEAEAE]"
                >
                  Validity (in years)
                </th>
                <th
                  scope="col"
                  colSpan="2"
                  class="px-6 py-1  border border-[#AEAEAE]"
                >
                  Total Access Id’s
                </th>
                <th
                  scope="col"
                  colSpan="2"
                  class="px-6 py-1  border border-[#AEAEAE]"
                >
                  Total Qr-Code Id’s
                </th>
                <th
                  scope="col"
                  rowSpan="2"
                  class="px-6 py-1  border border-[#AEAEAE]"
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              <tr class="bg-white border border-[#AEAEAE] ">
                <td
                  align="center"
                  class="px-6 py-1  border border-[#AEAEAE]"
                ></td>
                <td
                  align="center"
                  class="px-6 py-1  border border-[#AEAEAE]"
                ></td>
                <td
                  align="center"
                  class="px-6 py-1  border border-[#AEAEAE] border-dashed"
                >
                  Unused
                </td>
                <td
                  align="center"
                  class="px-6 py-1  border border-[#AEAEAE] border-dashed"
                >
                  Used
                </td>
                <td
                  align="center"
                  class="px-6 py-1  border border-[#AEAEAE] "
                  style={{ borderRight: "1px dashed #AEAEAE" }}
                >
                  Unused
                </td>
                <td
                  align="center"
                  class="px-6 py-1  border border-[#AEAEAE] border-dashed"
                >
                  Used
                </td>
                <td
                  align="center"
                  class="px-6 py-1  border border-[#AEAEAE]"
                ></td>
              </tr>
              {console.log(accessCodes, "accessCodes")}
              {Object.keys(accessCodes ?? {}).map((validity) => {
                const total =
                  Number(accessCodes[validity].unUsedAccessIds) +
                  Number(accessCodes[validity].usedAccessIds) +
                  Number(accessCodes[validity].unUsedQrCodes) +
                  Number(accessCodes[validity].usedQrCodes);
                return (
                  <tr tr class="bg-white border border-[#AEAEAE] ">
                    <td class="px-6 py-1  border border-[#AEAEAE]">{total}</td>
                    <td class="px-6 py-1  border border-[#AEAEAE]">
                      {validity}
                    </td>
                    <td class="px-6 py-1  border border-[#AEAEAE] border-dashed">
                      {accessCodes[validity].unUsedAccessIds}
                    </td>
                    <td class="px-6 py-1  border border-[#AEAEAE] border-dashed">
                      {accessCodes[validity].usedAccessIds}
                    </td>
                    <td
                      class="px-6 py-1  border border-[#AEAEAE]"
                      style={{ borderRight: "1px dashed #AEAEAE" }}
                    >
                      {accessCodes[validity].unUsedQrCodes}
                    </td>
                    <td class="px-6 py-1  border border-[#AEAEAE] border-dashed">
                      {accessCodes[validity].usedQrCodes}
                    </td>
                    <td class="px-6 py-1  border border-[#AEAEAE]">
                      <div className={`flex items-center justify-center `}>
                        <ReactTooltip
                          place="right"
                          backgroundColor="#737272"
                          type="success"
                          effect="solid"
                          offset={{ bottom: 5 }}
                          delayShow="200"
                        />
                        <img
                          data-tip="Convert AID to QRID"
                          src={
                            accessCodes[validity].unUsedAccessIds > 0
                              ? CovertIco
                              : CovertIcoGrey
                          }
                          className=" h-4 w-4 object-contain cursor-pointer"
                          onClick={() => {
                            if (accessCodes[validity].unUsedAccessIds > 0) {
                              setModals((prev) => ({ ...prev, convert: true }));
                              setSelectedData({
                                validity,
                                unusedAids:
                                  accessCodes[validity].unUsedAccessIds
                              });
                            }
                          }}
                        />
                        {/* <img src={PrintIco} className='h-6 w-6 object-contain cursor-pointer' title='Print QRID' /> */}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="hidden mt-3 lg:block">
          <button
            onClick={handleGenerate}
            className=" w-fit px-3  h-[54px] bg-primary-dark border-[0.5px] border-solid border-[#cacaca] text-primary-white text-h5 font-bold"
          >
            Generate New Access Ids
          </button>
        </div>
        <div className="flex mt-3 mb-16 lg:hidden items-center">
          <button
            onClick={handleGenerate}
            className=" w-fit h-[54px] py-3 bg-primary-dark border-[0.5px] border-solid border-[#cacaca] text-primary-white text-h5 font-bold"
          >
            Generate New Access Id’s
          </button>
        </div>
      </div>
    );
  };

  const QrCodeBatches = () => {
    return (
      <div>
        <h3 className="capitalize lg:uppercase lg:text-laptop-h3 xl:text-h5 text-tertiary-dark text-left my-4 text-[18px] font-bold lg:font-semibold">
          Qr Code Batches
        </h3>
        <p className="text-h5 text-secondary-dark tracking-wide text-left mb-4">
          A. Access Id Validity can be less than or equal to the Book Validity{" "}
          <br />
          B. One Access Id provides Access of this Book for the Mentioned Access
          Code Validity to One User
        </p>

        <div class="relative overflow-x-auto">
          <table class="w-full border border-[#AEAEAE] border-collapse text-s text-[#6A6A6A] text-center align-middle">
            <thead class="bg-neutral-10 text-secondary-dark ">
              <tr>
                <th scope="col" class="px-6 py-1  border border-[#AEAEAE]">
                  Batch Id
                </th>
                <th scope="col" class="px-6 py-1  border border-[#AEAEAE]">
                  Created At
                </th>
                <th scope="col" class="px-6 py-1  border border-[#AEAEAE]">
                  Validity
                </th>
                <th scope="col" class="px-6 py-1  border border-[#AEAEAE]">
                  Total Qty
                </th>
                <th scope="col" class="px-6 py-1  border border-[#AEAEAE]">
                  Unused Qty
                </th>
                <th scope="col" class="px-6 py-1  border border-[#AEAEAE]">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {qrCodeDetails.map((qrCode, idx) => {
                return (
                  <tr tr class="bg-white border border-[#AEAEAE] ">
                    <td class="px-6 py-1  border border-[#AEAEAE]">
                      {qrCode.BatchId}
                    </td>
                    <td class="px-6 py-1  border border-[#AEAEAE]">
                      {ddmmyyyyhhmm(qrCode.createdAt)}
                    </td>

                    <td class="px-6 py-1  border border-[#AEAEAE]">
                      {qrCode.validity}
                    </td>
                    <td class="px-6 py-1  border border-[#AEAEAE]">
                      {qrCode.totalCount}
                    </td>
                    <td class="px-6 py-1  border border-[#AEAEAE]">
                      {qrCode.unUsedCount}
                    </td>
                    <td class="px-6 py-1  border border-[#AEAEAE]">
                      <div className="flex items-center justify-center">
                        <ReactTooltip
                          place="right"
                          backgroundColor="#737272"
                          type="success"
                          effect="solid"
                          offset={{ bottom: 2 }}
                          delayShow="200"
                        />
                        <img
                          src={PrintIco}
                          className="h-6 w-6 object-contain cursor-pointer"
                          data-tip="Print QRID"
                          alt="print-ico"
                          onClick={() => {
                            setSelectedData(qrCode);
                            setModals((prev) => ({ ...prev, printQr: true }));
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          {qrCodeDetails.length == 0 && (
            <p className="mt-2 text-center text-secondary-dark">
              No QR Codes/ Batches available.
            </p>
          )}
        </div>
      </div>
    );
  };
  const ExpirationInfo = () => {
    return (
      <div className=" ">
        <div className="hidden lg:block p-2 border-1 border-solid border-primary-dark-20 w-full h-fit -48 mb-8">
          <div className=" px-4">
            <h2 className="text-heading font-bold text-h4 text-left mb-2">
              Expiration Date
            </h2>
            <div className="flex justify-between items-center mb-2">
              <p className="text-secondary-light text-semibold text-h5 mb-2">
                {book?.title}
              </p>
              <p className="text-secondary-dark text-semibold text-h5 w-10">
                {
                  calculateExpiry(book.expiresOn, book.createdAt, book.validity)
                    .day
                }{" "}
                days
              </p>
            </div>
            <div className=" h-[18px] bg-primary-light mb-6 rounded-full">
              <div
                className="h-full bg-primary-dark rounded"
                style={{
                  rounded: "50px",
                  width:
                    calculateExpiry(
                      book.expiresOn,
                      book.createdAt,
                      book.validity
                    ).percent + "%"
                }}
              ></div>
            </div>
          </div>
          {/* {expired ? (""
            // <button className="text-heading font-semibold text-p1 h-[41px] w-full border-1 border-solid border-primary-dark-20">
            //   Reactivate
            // </button>
          ) : (
            <button className="text-heading font-semibold text-p1 h-[41px] w-full border-1 border-solid border-primary-dark-20">
              Renew
            </button>
          )} */}
        </div>

        <div className=" hidden lg:block p-4 border-1 border-solid border-primary-dark-20 w-full ">
          <h2 classNaame="text-heading font-bold text-h4 text-left mb-2">
            Details
          </h2>
          <div className="my-4 mb-0 flex justify-between">
            <div className="w-1/2">
              <div className="mb-4">
                <h3 className="text-h5 text-secondary-dark font-semibold">
                  Edition
                </h3>
                <p className="text-h4 text-tertiary-dark font-semibold">
                  {book?.edition}
                </p>
              </div>

              {/* <div className="mb-4">
                      <h3 className="text-h5 text-secondary-dark font-semibold">
                        Book ID
                      </h3>
                      <p className="text-ellipsis overflow-hidden whitespace-nowrap mr-6 text-h4 text-tertiary-dark font-semibold">
                        {book?.id}
                      </p>
                    </div> */}

              <div className="mb-4">
                <h3 className="text-h5 text-secondary-dark font-semibold">
                  Page Count
                </h3>
                <p className="text-h4 text-tertiary-dark font-semibold">
                  {book?.countOfPages} Pages
                </p>
              </div>

              <div className="mb-4">
                <h3 className="text-h5 text-secondary-dark font-semibold">
                  Allow Print
                </h3>
                <p className="text-h4 text-tertiary-dark font-semibold">
                  {book?.allowPrint ? "Yes" : "No"}
                </p>
              </div>
              {book?.customFieldName && (
                <div className="mb-4">
                  <h3 className="text-h5 text-secondary-dark font-semibold">
                    {book?.customFieldName}
                  </h3>
                  <p className="text-h4 text-tertiary-dark font-semibold">
                    {book?.customFieldContents}
                  </p>
                </div>
              )}

              <div className="mb-4">
                <h3 className="text-h5 text-secondary-dark font-semibold">
                  ISBN NO.
                </h3>
                <p className="text-h4 text-tertiary-dark font-semibold">
                  {book?.ISBN}
                </p>
              </div>
            </div>

            <div className="w-1/2">
              <div className="mb-4">
                <h3 className="text-h5 text-secondary-dark font-semibold">
                  Category
                </h3>
                <p className="text-h4 text-tertiary-dark font-semibold">
                  {book?.Bcategories?.map((elm, index) => (
                    <p
                      key={index}
                      className=" capitalize text-h4 text-tertiary-dark font-semibold"
                    >
                      {elm?.bookCategoryName}
                    </p>
                  ))}
                </p>
              </div>

              <div className="mb-4">
                <h3 className="text-h5 text-secondary-dark font-semibold">
                  Author
                </h3>
                <p className="text-h4 text-tertiary-dark font-semibold">
                  {book?.Bauthors?.map((elm, index) => (
                    <p
                      key={index}
                      className="text-h4 text-tertiary-dark font-semibold"
                    >
                      {elm?.bookAuthorName}
                    </p>
                  ))}
                </p>
              </div>

              <div className="mb-4">
                <h3 className="text-h5 text-secondary-dark font-semibold">
                  Publishers
                </h3>
                {book?.Bpublications?.map((elm, index) => (
                  <p
                    key={index}
                    className="text-h4 text-tertiary-dark font-semibold"
                  >
                    {elm?.bookPublicationName}
                  </p>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    getBookDetails();
  }, []);
  return (
    <Page active="wallet">
      <Navbar />
      {loading ? (
        <Loader show={true} />
      ) : (
        <div>
          <h1 className="flex items-center text-mobile-h1  lg:text-h1 text-tertiary-dark lg:text-heading text-left font-bold lg:font-semibold">
            <span onClick={() => navigate(-1)} className=" mr-3">
              <svg
                width="12"
                height="20"
                viewBox="0 0 12 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.0491 2.5473L9.4174 0.924805L0.351562 9.9998L9.42656 19.0748L11.0491 17.4523L3.59656 9.9998L11.0491 2.5473Z"
                  fill="#702963"
                />
              </svg>
            </span>
            {book?.title}
          </h1>
          <div className="m-3 mt-7">
            <TopBar
              tabs={[
                "Book Details",
                "Addendums",
                "Access IDs/ QrID’s",
                "QR Batches"
              ]}
              selectedTab={selectedTab}
              onChange={(t) => setSelectedTab(t)}
            />
            <div className="flex items-start">
              <div className="flex-1 mr-2 ">
                {selectedTab === "Book Details" && <BookDetails />}
                {selectedTab === "Addendums" && <AdendumDetails />}
                {selectedTab === "Access IDs/ QrID’s" && <AccessCodeDetails />}
                {selectedTab === "QR Batches" && <QrCodeBatches />}
              </div>
              {selectedTab !== "Access IDs/ QrID’s" &&
                selectedTab !== "QR Batches" && (
                  <div className="w-[30%] min-w-[350px]">
                    <ExpirationInfo />
                  </div>
                )}
            </div>
          </div>

          <ConvertToQrModal
            modals={modals}
            setModals={setModals}
            selectedData={selectedData}
            data={book}
            from={"bookDetails"}
          />
          <PrintQrConfigModal
            show={modals?.printQr}
            selectedData={selectedData}
            onClose={() => setModals((prev) => ({ ...prev, printQr: false }))}
            parentData={book}
            onCompletePrint={onCompletePrint}
          />
          <DialogBox show={modals.convertSuccess ?? false}>
            <PopUp
              height={true}
              style={{ bottom: "0px" }}
              onClick={() => {
                setModals((prev) => ({ ...prev, convertSuccess: false }));
                getBookDetails();
              }}
              onClose={() => {
                setModals((prev) => ({ ...prev, convertSuccess: false }));
                getBookDetails();
              }}
              button="Close"
              title="Converted Successfully"
              main=""
              body=""
            />
          </DialogBox>

          <ToastContainer
            position="top-right"
            autoClose={3000}
            closeOnClick
            pauseOnFocusLoss
            pauseOnHover
          />
        </div>
      )}
    </Page>
  );
}
