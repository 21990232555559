import { Sidebar } from ".";

export default function Page({ loading, active, children, restProps }) {
  return (
    <div className="box-border min-h-screen flex">
      <Sidebar active={active} />
      <div
        {...restProps}
        className="w-full lg:w-top lg:border-r-24 border-r-primary-dark lg:px-12 pt-8 border-r-solid lg:border-t-24 border-t-primary-dark border-t-solid"
      >
        {children}
      </div>
    </div>
  );
}
