import { useState, useEffect, useContext, useMemo } from "react";
import {
  DialogBox,
  BtnSmall,
  BtnSmallWhite,
  Loader,
  PopUp,
} from "../../components";
import { useNavigate } from "react-router-dom";
import * as ROUTES from "../../constant/routes";
import {
  getallTickets,
  getAllComments,
  searchPortalId,
  createNewcomments,
  createNewTicket,
  updateStatus,
} from "../../assets/Js/script";
import moment from "moment";
import direction from "../../assets/images/direction.png";
import conversion from "../../assets/images/conversion.png";
import { useForm } from "react-hook-form";
import { AuthContext } from "../../context/auth";
import { ToastContainer, toast } from "react-toastify";

export default function HelpSupport() {
  const { user, setUser } = useContext(AuthContext);
  const [tickets, setTickets] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [comment, setComment] = useState("");
  const [comments, setComments] = useState([]);
  const [page, setPage] = useState(1);
  const [dropToSee, setDropToSee] = useState("");
  const [filter, setFilter] = useState({
    ticketDate: "",
    resolutionDate: "",
    status: "",
    statusPopUp: false,
  });
  const [totalPage, setTotalPage] = useState(null);
  const [dropdownActive, setDropdownActive] = useState({
    Category: false,
    RaiseTicketTo: false,
    pubName: false,
  });
  const [dropDownValue, setDropdownValue] = useState({
    raiseTicket: "",
    Category: "",
    publisherName: "",
    subject: "",
    description: "",
    file: [],
  });
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [popUps, setPopUps] = useState({
    isOpen: false,
    confirmOpen: false,
    addComment: false,
    progress: false,
  });

  const notify = (message) => toast(message);

  const [personalProfile, setPersonalProfile] = useState({
    fullName: "",
    email: "",
    subject: "",
    description: "",
  });
  function handleChange(e) {
    setPersonalProfile(e.target.value);
  }
  function handleFile(e) {
    const file = e.target.files[0];
    setDropdownValue((prev) => ({ ...prev, file: file }));
  }

  const navigate = useNavigate();
  // api

  useEffect(() => {
    async function tem() {
      setIsLoading(true);
      const { response, error } = await getallTickets(
        page,
        filter.status,
        filter.resolutionDate,
        filter.ticketDate
      );
      if (response.status === "success") {
        setIsLoading(false);
        setTotalPage(response.data.count);
        setTickets([...response.data.tickets]);
      } else {
        notify(response?.message ? response?.message : "Something went wrong");
      }
    }
    tem();
  }, [
    page,
    filter.status,
    filter.resolutionDate,
    filter.ticketDate,
    popUps.isOpen,
  ]);

  // useEffect(() => {
  async function search() {
    const { response, error } = await searchPortalId("plbAdmin");
    if (response.status === "success") {
      setDropdownValue((prev) => ({
        ...prev,
        publisherName: response.data[0].id,
      }));
    }
  }
  // }, [dropdownActive.RaiseTicketTo]);

  async function handleAddComment(data) {
    setSelectedTicket({ ...data });
    const { response, error } = await getAllComments(data?.id);
    if (response.status === "success") {
      setComments([...response.data.comments]);
    }
  }
  async function addComments() {
    if (comments !== null) {
      setComments((prev) => [
        ...prev,
        {
          comment: comment,
          fromPortalId: user.publicationId,
        },
      ]);
    } else {
      setComments((prev) => [
        ...prev,
        {
          comment: comment,
          fromPortalId: user.publicationId,
        },
      ]);
    }
    const { response, error } = await createNewcomments(
      comment,
      selectedTicket.id
    );
    if (response.status === "success") {
      setComment("");
    } else {
      setComment("");
      notify(response?.message ? response?.message : "Something went wrong");
    }
  }

  async function handleNewTickets(e) {
    e.preventDefault();
    const { response, error } = await createNewTicket({
      type: dropDownValue.Category,
      subject: dropDownValue.subject,
      description: dropDownValue.description,
      toportalId: dropDownValue.publisherName,
      toportalName: dropDownValue.raiseTicket,
      file: dropDownValue.file,
    });
    if (response.status === "success") {
      setDropdownValue((prev) => ({
        raiseTicket: "",
        Category: "",
        publisherName: "",
        subject: "",
        description: "",
        file: [],
      }));
      setPopUps((prev) => ({ ...prev, confirmOpen: true }));
    } else {
      notify(response?.message ? response?.message : "Something went wrong");
    }
  }

  async function handleStatus(status, id) {
    const { response, error } = await updateStatus(id, status);
    if (response.status == "success") {
    } else {
      notify(response?.message ? response?.message : "Something went wrong");
    }
  }

  function getTicketNo(id) {
    return tickets.findIndex((elm) => elm.id === id) + 1;
  }

  function handlePaginationPrev() {
    if (page == 1) {
      return 1;
    } else {
      setPage((curr) => curr - 1);
    }
  }

  function handlePaginationNext() {
    if (totalPage / 5 <= page) {
      return 1;
    } else {
      setPage((curr) => curr + 1);
    }
  }
  function handleFilter(key) {
    if (key == 1) {
      setFilter((prev) => ({ ...prev, status: "close", statusPopUp: false }));
    } else if (key == 2) {
      setFilter((prev) => ({
        ...prev,
        status: "in-progress",
        statusPopUp: false,
      }));
    } else {
      setFilter((prev) => ({ ...prev, status: "open", statusPopUp: false }));
    }
  }

  function handleSorting(sort) {
    if (sort == 1) {
      if (filter.ticketDate !== "createdAt") {
        setFilter((prev) => ({ ...prev, ticketDate: "createdAt" }));
      } else {
        setFilter((prev) => ({ ...prev, ticketDate: "+createdAt" }));
      }
    } else if (sort == 2) {
      if (filter.resolutionDate !== "resolutionDate") {
        setFilter((prev) => ({ ...prev, resolutionDate: "resolutionDate" }));
      } else {
        setFilter((prev) => ({ ...prev, resolutionDate: "+resolutionDate" }));
      }
    }
  }

  function totalPages() {
    if (totalPage) {
      const totalPageValue = Math.ceil(totalPage / 5);
      return totalPageValue;
    }
    return false;
  }

  useMemo(() => {
    const handleClose = () => {
        setFilter((prev) => ({
          ...prev,
          statusPopUp: false,
        }));
    };

    window.addEventListener("click", handleClose);

    return () => {
      window.removeEventListener("click", handleClose);
    };
  }, []);

  return (
    <>
      {isLoading ? (
        <Loader show={isLoading} />
      ) : (
        <div className="relative px-2">
          <h1 className=" mt-[15px] mb-4 flex items-center text-heading text-left text-[20px] font-[600] ">
            Help &#38; Support
          </h1>
          {/* <p className="font-600 text-[17px] text-[#726d6d mb-4 ">
          Accounts Settings Help and Support

          </p> */}
          <div className="relative flex flex-col min-h-[400px] gap-1 my-6 mb-16">
            <button
              onClick={() => setPopUps((prev) => ({ ...prev, isOpen: true }))}
              className=" absolute right-0  top-[-76px] w-[152px] h-[21px] underline-offset-2 border-b-[1px] border-solid border- text-[18px] font-[600] text-[#702963] "
            >
              Create a new ticket
            </button>
            <div className="grid grid-rows-1 grid-cols-9 content-center items-center ">
              <p className="text-[#000] cursor-pointer font-bold m-1 col-span-1 ">
                Ticket #
              </p>
              <p className="text-[#000] cursor-pointer font-bold m-1 col-span-1 ml-2">
                Type
              </p>
              <p className="text-[#000] cursor-pointer font-bold m-1 col-span-1 ml-2">
                Raised By
              </p>
              {/* <p className="text-[#000] cursor-pointer font-bold m-1 col-span-1 pl-0">
            Department
          </p> */}
              {/* <p className="text-[#000] cursor-pointer font-bold m-1 col-span-2 pl-2">
            Description
          </p> */}
              <p className="text-[#000] cursor-pointer justify-self-center font-bold m-1 col-span-2 flex ">
                Ticket Date
                <span onClick={() => handleSorting(1)}>
                  <img src={direction} />
                </span>
              </p>
              <p className="text-[#000] cursor-pointer font-bold m-1 col-span-2 flex">
                Resolution Date
                <span onClick={() => handleSorting(2)}>
                  <img src={direction} />
                </span>
              </p>
              <p
                onClick={(e) => {
                  e.stopPropagation();
                  setFilter((prev) => ({
                    ...prev,
                    statusPopUp: !filter.statusPopUp,
                  }));
                }}
                className="group text-[#000] cursor-pointer font-bold m-1 relative col-span-1 justify-self-start flex "
              >
                Status
                <span>
                  <svg
                    width="16"
                    height="26"
                    viewBox="0 0 16 26"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    xlink="http://www.w3.org/1999/xlink"
                  >
                    <rect width="16" height="26" fill="url(#pattern0)" />
                    <defs>
                      <pattern
                        id="pattern0"
                        patternContentUnits="objectBoundingBox"
                        width="1"
                        height="1"
                      >
                        <use
                          href="#image0_6713_33455"
                          transform="translate(0 0.192308) scale(0.0333333 0.0205128)"
                        />
                      </pattern>
                      <image
                        id="image0_6713_33455"
                        width="30"
                        height="30"
                        href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAABmJLR0QA/wD/AP+gvaeTAAAA2ElEQVRIie3UP06CMRwG4Ac34glIHBzYXFyYWFycWNicmNw8hSR4AL0EE6dgcWGEA5g4mDgSgtEIS7/gwp+WD6Kxb9Kt7+9pk6bk/LcMsTjQGv6ETg5+lVUW2zac4y1sfNwDegoz3lHftdTEPBTvEtDb0P3AVWz5Bt/4Qiuid43P0O3EokUepD+mXioKFfQT0IESHm4VzxHoCKf7okVqeNkBfcVZWWiRS0w3oDM0ykaLtDfA7ZhBlQR83Q8UNeuYX2aGM5zh3wlP0MVFCWfZmjHuj4Xl/N0sAQ6/bOtEwCsWAAAAAElFTkSuQmCC"
                      />
                    </defs>
                  </svg>
                </span>
                {filter.statusPopUp && (
                  <span className=" absolute top-6 bg-primary-white left-0 shadow-md w-[120px] py-2 flex flex-col justify-between">
                    <span
                      onClick={() => handleFilter(1)}
                      className="hover:bg-[#F3EBF2] cursor-pointer font-thin py-2 px-3"
                    >
                      Close
                    </span>
                    <span
                      onClick={() => handleFilter(2)}
                      className="hover:bg-[#F3EBF2] cursor-pointer font-thin py-2 px-3"
                    >
                      In Progress
                    </span>
                    <span
                      onClick={() => handleFilter(3)}
                      className="hover:bg-[#F3EBF2] cursor-pointer font-thin py-2 px-3"
                    >
                      Open
                    </span>
                  </span>
                )}
              </p>
              <p className="text-[#000] cursor-pointer font-bold m-1 col-span-1 justify-self-end "></p>
            </div>
            <div className="flex flex-col gap-4">
              {tickets?.length === 0 ? (
                <div
                  className={`border-1 h-[64px]  items-center flex justify-center shadow-sm border-b-1 border-[#AEAEAE] border-solid  bg-[#FBFAFB]`}
                >
                  Your Ticket history will be shown here
                </div>
              ) : (
                tickets?.map((ticket, index) => (
                  <div
                    onClick={() => setDropToSee(ticket.id)}
                    key={ticket.id}
                    className="group cursor-pointer"
                  >
                    <div
                      className={`border-1 h-[64px] grid grid-cols-9 items-center grid-rows-1 w-full shadow-sm border-b-1 border-[#AEAEAE] border-solid  bg-[#FBFAFB]`}
                    >
                      <p className="text-[#5F5D5D] m-1 col-span-1 pl-1">
                        {index + 1}
                      </p>
                      <p className="text-[#5F5D5D] m-1 col-span-1">
                        {ticket.type}
                      </p>
                      <p className="text-[#5F5D5D] m-1 col-span-1">
                        {ticket.fromUserRole}
                      </p>
                      {/* <p className="text-[#5F5D5D] m-1 col-span-1 w-[180px] whitespace-nowrap overflow-hidden text-ellipsis">
                    {ticket.toPortalName}
                  </p> */}
                      {/* <p className="text-[#5F5D5D] m-1 col-span-2 w-[180px] whitespace-nowrap overflow-hidden text-ellipsis">
                    {ticket.description}
                  </p> */}
                      <p className="text-[#5F5D5D] justify-self-center col-span-2 m-1">
                        {moment(ticket.createdAt).format("DD/MM/YYYY")}
                      </p>
                      <p
                        className={`text-[#5F5D5D] col-span-2 m-1  pr-2 ${
                          ticket.resolutionDate === null
                            ? "ml-[64px]"
                            : "justify-self-start"
                        } `}
                      >
                        {ticket.resolutionDate === null
                          ? "-"
                          : moment(ticket.resolutionDate).format("DD/MM/YYYY")}
                      </p>
                      <p className="text-[#5F5D5D] justify-self-start col-span-1 m-1  ">
                        {ticket.status}
                      </p>
                      <div className="text-[#702963] col-span-1 m-1 justify-self-end pr-3">
                        <svg
                          width="13"
                          height="7"
                          viewBox="0 0 13 7"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M6.5 7L0 0H13L6.5 7Z" fill="#7A7976" />
                        </svg>
                      </div>
                    </div>
                    {/* <div className="hidden group-hover:flex py-3 px-6 bg-[#fff] flex-col border-1 border-primary-gray border-solid gap-2">
                <p className="w-full text-heading font-bold">Description</p>
                <div className="flex items-center flex-row justify-between">
                  <p className="flex flex-col justify-between items-center w-[60%]">
                    {ticket.description}
                  </p>
                  <p className="text-[14px] text-[#293241] text-left">Status</p>

                  <div className="bg-[#f5f5f5d6] w-28 cursor-pointer font-bold m-1 p-1 border border-solid relative border-[#bb7aafc2] rounded-sm">
                    <div
                      className="relative"
                      onClick={() =>
                        setPopUps((prev) => ({
                          ...prev,
                          progress: !prev.progress,
                        }))
                      }
                    >
                      <p className="text-primary-dark">In Progress</p>
                      <div className="absolute top-3 right-1">
                        <svg
                          width="7"
                          height="4"
                          viewBox="0 0 7 4"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M3.5 4L0 0H7L3.5 4Z" fill="#939393" />
                        </svg>
                      </div>
                    </div>
                    {popUps.progress && (
                      <span className="absolute top-10 bg-primary-white left-0 shadow-md w-[120px] py-2 flex flex-col justify-between">
                        <span className="hover:bg-[#F3EBF2] cursor-pointer font-thin py-2 px-3">
                          Close
                        </span>
                        <span className="hover:bg-[#F3EBF2] cursor-pointer font-thin py-2 px-3">
                          In Progress
                        </span>
                        <span className="hover:bg-[#F3EBF2] cursor-pointer font-thin py-2 px-3">
                          Pending
                        </span>
                      </span>
                    )}
                  </div>
                </div>

                <button
                  onClick={() => {
                    setPopUps((prev) => ({
                      ...prev,
                      addComment: true,
                    }));
                    handleAddComment(ticket);
                  }}
                  className="w-[134px] text-[16px] px-[17px]  h-[36px] border-[0.5px] border-solid border-[#b0b0b0] bg-[#702963] text-[#FFF] hover:text-[#b0b0b0] my-auto whitespace-nowrap"
                >
                  Add comment
                </button>
              </div> */}
                    {ticket.id == dropToSee && (
                      <div className="flex py-3 px-6 bg-[#fff] flex-col border-1 border-primary-gray border-solid gap-2">
                        <p className="w-full text-heading font-bold ">
                          Description
                        </p>
                        <div className="flex items-center flex-row justify-between">
                          <p className="flex overflow-x-hidden text-ellipsis flex-col justify-between w-[60%] text-left">
                            {ticket.description}
                          </p>
                          <p className="text-[14px] text-[#293241] text-left">
                            Status
                          </p>

                          {/* <p className="text-[14px] text-[#293241] text-left font-medium">
                    In Progress
                  </p> */}

                          <div className="bg-[#f5f5f5d6] w-[117px] h-[26px] cursor-pointer font-bold text-[14px] relative ">
                            <div
                              className="relative flex flex-row items-center justify-start "
                              onClick={() =>
                                setPopUps((prev) => ({
                                  ...prev,
                                  progress: !prev.progress,
                                }))
                              }
                            >
                              <p className="text-primary-dark capitalize text-[#702963] ml-2 mt-1">
                                {ticket.status}
                              </p>
                              {/* <div className="absolute top-3 right-1">
                        <svg
                          width="7"
                          height="4"
                          viewBox="0 0 7 4"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M3.5 4L0 0H7L3.5 4Z" fill="#939393" />
                        </svg>
                      </div> */}
                            </div>
                            {popUps.progress && (
                              <span className="absolute  gap-1 top-5 bg-[#fff0] left-0 shadow-md w-[115px] py-2 flex flex-col justify-between">
                                <span
                                  onClick={() =>
                                    handleStatus("close", ticket.id)
                                  }
                                  className="hover:bg-[#F3EBF2] bg-[#fff] cursor-pointer font-thin py-1 px-3"
                                >
                                  Close
                                </span>
                                <span
                                  onClick={() =>
                                    handleStatus("in-progress", ticket.id)
                                  }
                                  className="hover:bg-[#F3EBF2] bg-[#fff] cursor-pointer font-thin py-1 px-3"
                                >
                                  In Progress
                                </span>
                                <span
                                  onClick={() =>
                                    handleStatus("open", ticket.id)
                                  }
                                  className="hover:bg-[#F3EBF2] bg-[#fff] cursor-pointer font-thin py-1 px-3"
                                >
                                  Open
                                </span>
                              </span>
                            )}
                          </div>

                          <button
                            onClick={() => {
                              setPopUps((prev) => ({
                                ...prev,
                                addComment: true,
                              }));
                              handleAddComment(ticket);
                            }}
                            className="align-top w-[134px] h-[36px] text-[16px] text-[#fff] bg-primary-dark"
                          >
                            Add Comments
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                ))
              )}
            </div>
          </div>
          <div className="absolute bottom-[-4rem] right-0 flex flex-row w-full justify-end px-2">
            <div className="flex flex-row gap-3 w-fit">
              <button
                onClick={() => handlePaginationPrev()}
                className="w-[20px] h-[20px] flex justify-center items-center"
              >
                <svg
                  width="9"
                  height="15"
                  viewBox="0 0 7 11"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.47794 1.50781L1.42688 5.49912L5.47794 9.49042"
                    stroke="#BDBDBD"
                    stroke-linecap="square"
                  />
                </svg>
              </button>

              <span className="w-fit h-[23px] hover:rounded-[50%] text-center ">
                {page} - {totalPages()}
              </span>

              <button
                onClick={() => handlePaginationNext()}
                className="w-[20px] h-[20px] flex justify-center items-center"
              >
                <svg
                  width="9"
                  height="15"
                  viewBox="0 0 7 11"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.52209 9.49433L5.57316 5.50302L1.52209 1.51172"
                    stroke="#BDBDBD"
                    stroke-linecap="square"
                  />
                </svg>
              </button>
            </div>
          </div>

          {/* Popups ---------------------------------------------- */}

          {popUps.isOpen && (
            <DialogBox show={popUps.isOpen}>
              <div className="bg-primary-white flex flex-col justify-between relative w-full max-w-[430px] lg:max-w-none lg:w-pop-up max-h-[100vh] h-[100%] lg:h-[784px] py-2 px-2 lg:p-2 rounded-t-xl rounded-tr-xl lg:rounded-t-none ">
                <div className="flex justify-between py-1 px-3 items-center">
                  <h4 className="text-thumb h-[35px] font-semibold px-3 text-h4">
                    Create New Ticket
                  </h4>
                  <button
                    onClick={() =>
                      setPopUps((prev) => ({
                        ...prev,
                        isOpen: false,
                      }))
                    }
                  >
                    <svg
                      width="12"
                      height="13"
                      viewBox="0 0 12 13"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.2181 11.8715C10.948 12.1364 10.5817 12.2852 10.1999 12.2852C9.81796 12.2852 9.4517 12.1364 9.18165 11.8715L6.00045 8.30485L2.81925 11.8703C2.68594 12.0032 2.52712 12.109 2.35194 12.1814C2.17677 12.2538 1.98871 12.2914 1.79863 12.2922C1.60854 12.293 1.42018 12.2568 1.24441 12.1858C1.06864 12.1148 0.908943 12.0103 0.774529 11.8785C0.640115 11.7466 0.533644 11.59 0.461257 11.4176C0.38887 11.2452 0.352 11.0604 0.352774 10.8739C0.353548 10.6875 0.391951 10.503 0.465766 10.3312C0.539582 10.1593 0.64735 10.0035 0.782855 9.87276L4.09245 6.16486L0.781655 2.4546C0.646151 2.32383 0.538382 2.16804 0.464567 1.9962C0.390751 1.82437 0.352348 1.63991 0.351574 1.45344C0.3508 1.26698 0.38767 1.08221 0.460057 0.909795C0.532444 0.737379 0.638915 0.580728 0.773329 0.448878C0.907743 0.317028 1.06744 0.212587 1.24321 0.141581C1.41898 0.0705753 1.60734 0.0344087 1.79743 0.035168C1.98751 0.0359272 2.17557 0.0735971 2.35074 0.146005C2.52592 0.218413 2.68474 0.324125 2.81806 0.457045L6.00045 4.02487L9.18165 0.457045C9.31497 0.324125 9.47379 0.218413 9.64897 0.146005C9.82414 0.0735971 10.0122 0.0359272 10.2023 0.035168C10.3924 0.0344087 10.5807 0.0705753 10.7565 0.141581C10.9323 0.212587 11.092 0.317028 11.2264 0.448878C11.3608 0.580728 11.4673 0.737379 11.5397 0.909795C11.612 1.08221 11.6489 1.26698 11.6481 1.45344C11.6474 1.63991 11.609 1.82437 11.5351 1.9962C11.4613 2.16804 11.3536 2.32383 11.2181 2.4546L7.90846 6.16486L11.2181 9.87276C11.3519 10.0039 11.4582 10.1597 11.5306 10.3312C11.6031 10.5027 11.6404 10.6865 11.6404 10.8721C11.6404 11.0578 11.6031 11.2416 11.5306 11.413C11.4582 11.5845 11.3519 11.7403 11.2181 11.8715Z"
                        fill="black"
                        fill-opacity="0.53"
                      />
                    </svg>
                  </button>
                </div>
                <p className="text-mobile-h4 font-semibold mx-6">
                  Enter required details
                </p>
                <div className="py-1 h-full flex flex-col mx-6 justify-start overflow-y-auto scrollbar ">
                  <form className="flex relative mt-4 flex-col justify-between ">
                    <div className="relative flex flex-col">
                      <div className="lg:hidden flex items-center justify-center w-[100%]">
                        <div className="flex items-center justify-center bg-neutral-10 rounded-full w-[144px] h-[144px]">
                          <p className="text-secondary-light text-center">
                            Add a profile <br /> picture
                          </p>
                        </div>
                      </div>
                      <>
                        <label className="text-p1 font-semibold text-secondary-dark -mb-2">
                          Name*
                        </label>
                        <input
                          type="name"
                          name="name"
                          disabled={true}
                          value={user.fullName}
                          placeholder="Enter name"
                          className="pl-2 mt-4 mb-3 text-p1 text-secondary-light outline-primary-light border-1 border-solid border-[#eee] font-semibold lg:w-40 h-12 py-[14px]   bg-primary-white  "
                        />
                      </>

                      <>
                        <label className="text-p1 mt-4 font-semibold text-secondary-dark -mb-2">
                          Email address*
                        </label>
                        <input
                          type="email"
                          name="email"
                          disabled={true}
                          value={user.email}
                          placeholder="Enter your email"
                          className="pl-2  mt-4 mb-3  text-p1 text-secondary-light outline-primary-light border-1 border-solid border-[#eee] font-semibold lg:w-[221px] h-12 py-[14px] bg-primary-white  "
                        />
                      </>

                      <>
                        <label className="text-p1 mt-4 font-semibold text-secondary-dark mb-2">
                          Raise Ticket To*
                        </label>
                        <div className="relative z-40 cursor-pointer border-1 border-solid pl-2 h-[47px] border-[#eee] lg:w-[221px] max-h-12  px-[15px] py-2 flex justify-between items-center">
                          <p
                            onClick={() =>
                              setDropdownActive((prev) => ({
                                ...prev,
                                RaiseTicketTo: true,
                              }))
                            }
                            className="font-semibold text-p1 text-secondary-light"
                          >
                            {dropDownValue.raiseTicket}
                          </p>
                          <button
                            onClick={(e) => {
                              e.preventDefault();
                              setDropdownActive((prev) => ({
                                ...prev,
                                RaiseTicketTo: !dropdownActive.RaiseTicketTo,
                              }));
                            }}
                          >
                            <svg
                              width="18"
                              height="15"
                              viewBox="0 0 18 15"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                width="18"
                                height="15"
                                rx="2"
                                fill="#EDEDED"
                              />
                              <path d="M9.5 11L5 5H14L9.5 11Z" fill="#7A7976" />
                            </svg>
                          </button>
                          {dropdownActive.RaiseTicketTo && (
                            <div className="absolute top-[48px] bg-primary-white left-0 w-full shadow-[0px_3px_3px_2px_rgba(135,133,133,0.13)] z-50 h-fit">
                              <button
                                onClick={(e) => {
                                  //   e.preventDefault();
                                  //   e.stopPropagation();
                                  //   setDropdown(dep.departmentName);
                                  //   setDepId(dep.id)
                                  setDropdownValue((prev) => ({
                                    ...prev,
                                    // raiseTicket: e.target.innerText,
                                    raiseTicket: "plbAdmin",
                                  }));
                                  setDropdownActive((prev) => ({
                                    ...prev,
                                    RaiseTicketTo: false,
                                  }));
                                  search();
                                }}
                                className="capitalize w-full bg-[#f4f4f4a9] text-left p-2 hover:bg-[#99999905] text-secondary-light font-semibold"
                              >
                                ePrakashak Admin
                              </button>
                            </div>
                          )}
                        </div>
                      </>
                      {/* {extraInput && (
                  <>
                    <label className="text-p1 mt-4 font-semibold text-secondary-dark mb-2">
                    Publisher*
                    </label>
                    <div className="relative z-40 cursor-pointer border-1 border-solid pl-2 h-[47px] border-[#eee] lg:w-[221px] max-h-12  px-[15px] py-2 flex justify-between items-center">
                      <p
                        onClick={() => setDropdownActive((prev) => ({...prev, pubName: true,}))}
                        className="font-semibold text-p1 text-secondary-light"
                      >
                        {dropDownValue.publisherName}
                      </p>
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          setDropdownActive((prev) => ({...prev, pubName: !dropdownActive.pubName,})) 
                        }}
                      >
                        <svg
                          width="18"
                          height="15"
                          viewBox="0 0 18 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect width="18" height="15" rx="2" fill="#EDEDED" />
                          <path d="M9.5 11L5 5H14L9.5 11Z" fill="#7A7976" />
                        </svg>
                        
                      </button>
                      {dropdownActive.pubName && (
                        <div className="absolute top-[48px] bg-primary-white left-0 w-full shadow-[0px_3px_3px_2px_rgba(135,133,133,0.13)] z-50 h-fit">
                        <button
                          onClick={(e) => {
                          //   e.preventDefault();
                          //   e.stopPropagation();
                         
                            setDropdownValue((prev) => ({...prev, publisher: e.target.innerText}))
                            setDropdownActive((prev) => ({...prev, pubName: false}));
                          }}
                          className="capitalize w-full text-left p-2 hover:bg-[#f0f0f0] text-secondary-light font-semibold"
                        >
                           ePrakashak Admin
                        </button>
                        </div>
                      )}
                    </div>
                  </>
                )} */}
                      <>
                        <label className="text-p1 mt-4 font-semibold text-secondary-dark mb-2">
                          Category*
                        </label>
                        <div className="relative z-40 cursor-pointer border-1 border-solid pl-2 h-[47px] border-[#eee] lg:w-[221px] max-h-12  px-[15px] py-2 flex justify-between items-center">
                          <p
                            onClick={() =>
                              setDropdownActive((prev) => ({
                                ...prev,
                                Category: true,
                              }))
                            }
                            className="font-semibold text-p1 text-secondary-light"
                          >
                            {dropDownValue.Category}
                          </p>
                          <button
                            onClick={(e) => {
                              e.preventDefault();
                              setDropdownActive((prev) => ({
                                ...prev,
                                Category: !dropdownActive.Category,
                              }));
                            }}
                          >
                            <svg
                              width="18"
                              height="15"
                              viewBox="0 0 18 15"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                width="18"
                                height="15"
                                rx="2"
                                fill="#EDEDED"
                              />
                              <path d="M9.5 11L5 5H14L9.5 11Z" fill="#7A7976" />
                            </svg>
                          </button>
                          {dropdownActive.Category && (
                            <div className="absolute top-[48px] bg-primary-white left-0 w-full shadow-[0px_3px_3px_2px_rgba(135,133,133,0.13)] h-fit">
                              <button
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  setDropdownValue((prev) => ({
                                    ...prev,
                                    Category: e.target.innerText,
                                  }));
                                  setDropdownActive((prev) => ({
                                    ...prev,
                                    Category: false,
                                  }));
                                }}
                                className=" w-full bg-[#f4f4f4a9] text-left p-2 hover:bg-[#99999905] text-secondary-light font-semibold"
                              >
                                type-1
                              </button>
                              <button
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  setDropdownValue((prev) => ({
                                    ...prev,
                                    Category: e.target.innerText,
                                  }));
                                  setDropdownActive((prev) => ({
                                    ...prev,
                                    Category: false,
                                  }));
                                }}
                                className=" w-full bg-[#f4f4f4a9] text-left p-2 hover:bg-[#99999905] text-secondary-light font-semibold"
                              >
                                type-2
                              </button>
                              {/* <button
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              setDropdownValue((prev) => ({
                                ...prev,
                                Category: e.target.innerText,
                              }));
                              setDropdownActive((prev) => ({
                                ...prev,
                                Category: false,
                              }));
                            }}
                            className="capitalize w-full bg-[#f4f4f4a9] text-left p-2 hover:bg-[#99999905] text-secondary-light font-semibold"
                          >
                            type-3
                          </button> */}
                            </div>
                          )}
                        </div>
                      </>

                      <>
                        <label className="text-p1 mt-4 font-semibold text-secondary-dark mb-1">
                          Subject*
                        </label>
                        <input
                          type="text"
                          name="subject"
                          placeholder="Subject"
                          value={dropDownValue.subject}
                          onChange={(e) =>
                            setDropdownValue((prev) => ({
                              ...prev,
                              subject: e.target.value,
                            }))
                          }
                          className="pl-2  mt-4 mb-3 h-[50px]  outline-primary-light border-1 border-solid border-[#eee] py-[14px] font-semibold lg:w-[466px]  bg-primary-white  "
                        />
                      </>
                      <>
                        <label className="text-p1 mt-2 font-semibold text-secondary-dark mb-2">
                          Description*
                        </label>
                        <input
                          type="text"
                          name="description"
                          placeholder="Write description"
                          value={dropDownValue.description}
                          onChange={(e) =>
                            setDropdownValue((prev) => ({
                              ...prev,
                              description: e.target.value,
                            }))
                          }
                          className="pl-2  mt-4 mb-3 h-[114px]  outline-primary-light border-1 border-solid border-[#eee] py-[14px] font-semibold lg:w-[466px]  bg-primary-white  "
                        />
                      </>
                      <>
                        <label className="text-p1 mt-4 font-semibold text-secondary-dark mb-1">
                          File Upload (jpg, pdf)
                        </label>
                        <input
                          type="file"
                          name="file"
                          onChange={(e) => handleFile(e)}
                          className="pl-2  mt-4 mb-3 h-[50px]  outline-primary-light border-1 border-solid border-[#eee] py-[14px] font-semibold lg:w-[466px]  bg-primary-white  "
                        />
                      </>
                    </div>
                    <div className="flex flex-row gap-2 ml-auto">
                      <BtnSmallWhite
                        onClick={() => {
                          setPopUps((prev) => ({ ...prev, isOpen: false }));
                          setDropdownValue((prev) => ({
                            raiseTicket: "",
                            Category: "",
                            publisherName: "",
                            subject: "",
                            description: "",
                            file: [],
                          }));
                        }}
                      >
                        Cancel
                      </BtnSmallWhite>
                      <BtnSmall onClick={handleNewTickets}>Save</BtnSmall>
                    </div>
                  </form>
                </div>
              </div>
            </DialogBox>
          )}

          {popUps.confirmOpen && (
            <DialogBox show={popUps.confirmOpen}>
              <div
                className={`flex flex-col w-[500px] h-fit max-h-[425px] overflow-hidden  bg-primary-white shadow-2xl absolute top-[150px] left-[30%] z-[999]`}
              >
                <div className="flex justify-between py-1 px-3 items-center">
                  <h4 className="text-thumb w-full h-[60px] font-semibold text-h4 p-3 text-left">
                    Support ticket confirmation
                  </h4>
                  <button
                    onClick={() =>
                      setPopUps((prev) => ({
                        ...prev,
                        confirmOpen: false,
                      }))
                    }
                  >
                    <svg
                      width="12"
                      height="14"
                      viewBox="0 0 12 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.2176 12.8204C10.9475 13.0904 10.5813 13.2421 10.1994 13.2421C9.81747 13.2421 9.45122 13.0904 9.18117 12.8204L5.99997 9.1844L2.81877 12.8192C2.68545 12.9547 2.52663 13.0625 2.35145 13.1363C2.17628 13.2101 1.98823 13.2485 1.79814 13.2493C1.60805 13.2501 1.41969 13.2132 1.24392 13.1408C1.06815 13.0684 0.908454 12.9619 0.77404 12.8275C0.639626 12.6931 0.533155 12.5334 0.460769 12.3576C0.388382 12.1819 0.351512 11.9935 0.352286 11.8034C0.35306 11.6133 0.391462 11.4253 0.465278 11.2501C0.539094 11.0749 0.646862 10.9161 0.782367 10.7828L4.09197 7.0028L0.781167 3.2204C0.645662 3.08708 0.537894 2.92826 0.464078 2.75309C0.390263 2.57791 0.35186 2.38986 0.351086 2.19977C0.350312 2.00968 0.387182 1.82132 0.459569 1.64555C0.531955 1.46978 0.638426 1.31009 0.77284 1.17567C0.907255 1.04126 1.06695 0.934787 1.24272 0.862401C1.41849 0.790014 1.60685 0.753144 1.79694 0.753918C1.98703 0.754692 2.17508 0.793095 2.35025 0.86691C2.52543 0.940726 2.68425 1.04849 2.81757 1.184L5.99997 4.8212L9.18117 1.184C9.31448 1.04849 9.47331 0.940726 9.64848 0.86691C9.82365 0.793095 10.0117 0.754692 10.2018 0.753918C10.3919 0.753144 10.5802 0.790014 10.756 0.862401C10.9318 0.934787 11.0915 1.04126 11.2259 1.17567C11.3603 1.31009 11.4668 1.46978 11.5392 1.64555C11.6116 1.82132 11.6484 2.00968 11.6476 2.19977C11.6469 2.38986 11.6085 2.57791 11.5347 2.75309C11.4608 2.92826 11.3531 3.08708 11.2176 3.2204L7.90797 7.0028L11.2176 10.7828C11.3515 10.9165 11.4577 11.0754 11.5301 11.2502C11.6026 11.425 11.6399 11.6124 11.6399 11.8016C11.6399 11.9908 11.6026 12.1782 11.5301 12.353C11.4577 12.5278 11.3515 12.6867 11.2176 12.8204Z"
                        fill="black"
                        fill-opacity="0.53"
                      />
                    </svg>
                  </button>
                </div>
                <div className="py-1 pt-5 h-full flex flex-col justify-start">
                  <h4 className="text-semibold my-3 px-6 w-full text-left">
                    Your Ticket number is{" "}
                    <span className="text-thumb font-bold align-top underline">
                      Created
                    </span>
                  </h4>
                  <p className="text-[13px] text-[#293241] my-10 px-6 w-full text-left">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Eget utvest-ibulum bibendum egestas. Enim mi arcu convallis
                    enim purus interdum. Ut neque at adipiscing tortor facilisis
                    sagittis libero. Ac bibendum vel blandit.
                  </p>
                </div>
                <div className="flex justify-end my-2 lg:px-2">
                  <button
                    onClick={() => {
                      setPopUps((prev) => ({
                        ...prev,
                        confirmOpen: false,
                        isOpen: false,
                      }));
                      navigate(ROUTES.SETTINGS);
                    }}
                    className=" shadow-xl bg-primary-dark text-primary-white w-[135px] ml-4 text-center h-[45px]"
                  >
                    Back to Settings
                  </button>
                </div>
              </div>
            </DialogBox>
          )}

          {popUps.addComment && (
            <DialogBox show={popUps.addComment}>
              <div
                className={`relative p-2 pb-2 flex-col w-[566px] h-fit min-h-[425px] overflow-hidden bg-primary-white `}
              >
                <h4 className="text-thumb bg-[#fff] w-full h-[60px] font-semibold text-h4 p-3 text-left">
                  Subscription
                </h4>
                <div className=" py-1 pt-5 h-full flex flex-col justify-start px-3">
                  <div className="flex items-center mb-3 px-6 flex-row justify-between">
                    <p className="flex flex-col gap-0 justify-between items-center">
                      <span>{getTicketNo(selectedTicket.id)}</span>
                      <span className="text-[12px] text-[#293241] text-left">
                        Ticket Number
                      </span>
                    </p>
                    <p className="flex flex-col gap-0 justify-between items-center">
                      <span className="border-l-2 border-r-2  px-4 border-[#000] border-solid">
                        {moment(selectedTicket.createdAt).format("DD/MM/YYYY")}
                      </span>
                      <span className=" text-[12px] flex flex-row text-[#293241] text-left">
                        Ticket Date
                      </span>
                    </p>
                    <p className="flex flex-col gap-0 justify-between items-center">
                      <span>
                        {selectedTicket.resolutionDate === null
                          ? "--"
                          : moment(selectedTicket.resolutionDate).format(
                              "DD/MM/YYYY"
                            )}
                      </span>
                      <span className=" text-[12px] text-[#293241] text-left">
                        Resolution Date
                      </span>
                    </p>
                    <p className="flex flex-col justify-between items-center">
                      <span className="capitalize text-thumb font-400 align-top">
                        {selectedTicket.status}
                      </span>
                      <span className="text-[12px] text-[#293241] text-left">
                        Status
                      </span>
                    </p>
                  </div>
                  <div className=" h-[200px] overflow-y-scroll scrollbar">
                    {comments?.map((comment, index) => (
                      <>
                        {user.publicationId === comment.fromPortalId && (
                          <div
                            key={comment.id}
                            className="flex items-center justify-end my-3 mt-5"
                          >
                            <p className="text-[12px] text-[#00000071] font-[500px] p-2 w-[345px] border-1 rounded border-[#F3F3F3] shadow border-solid h-fit">
                              {comment.comment}
                            </p>
                          </div>
                        )}
                        {user.publicationId !== comment.fromPortalId && (
                          <div
                            key={comment.id}
                            className="flex flex-col my-3 mt-0 "
                          >
                            <p className="text-[12px] text-[#00000071] font-[500px] bg-[#bb7aaf3b] shadow p-2 w-[345px] border-1 rounded border-[#F3F3F3] border-solid h-fit">
                              {comment.comment}
                            </p>
                          </div>
                        )}
                      </>
                    ))}
                  </div>
                  <div className="w-[516px] h-[141px] ml-auto border-[#F3F3F3] border-1 border-solid  flex flex-col items-center justify-end shadow my-3 mt-5">
                    <input
                      type="text"
                      name="comment"
                      placeholder="Write your response here....."
                      value={comment}
                      onChange={(e) => setComment(e.target.value)}
                      autoComplete={false}
                      className="text-[15px]  p-2 focus:outline-none text-[#000000a6] font-[500px] w-full h-full "
                    />
                    <button
                      className="w-[90px] text-[15px] ml-auto mr-3 mb-3 h-[29px] bg-[#702963] border-1 border-solid border-[#702963] text-[#fff] "
                      onClick={() => addComments()}
                    >
                      Submit
                    </button>
                  </div>
                  {
                    <p
                      className={`hidden text-primary-white bg-thumb my-2 mx-[-12px] text-[12px] text-center`}
                    >
                      Support Ticket Resolved on 30.03.2021
                    </p>
                  }

                  {/* <div className="w-full flex justify-end items-center gap-3 my-4 mt-7 ">
                <button
                  onClick={() => {
                    setPopUps((prev) => ({ ...prev, addComment: false }));
                    setSelectedTicket(null);
                  }}
                  className="w-[150px] h-[54px] bg-[#fff] border-1 border-solid border-[#cacaca] text-[#000] "
                >
                  Cancel
                </button>
                <button
                  onClick={() => addComments()}
                  className="w-[150px] h-[54px] bg-[#702963] border-1 border-solid border-[#702963] text-[#fff] "
                >
                  Confirm
                </button>
              </div> */}
                </div>
                <button
                  className="absolute top-[24px] right-[15px] "
                  onClick={() => {
                    setPopUps((prev) => ({ ...prev, addComment: false }));
                    setSelectedTicket(null);
                  }}
                >
                  <svg
                    width="12"
                    height="13"
                    viewBox="0 0 12 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.2176 12.4864C10.9475 12.7492 10.5813 12.8968 10.1994 12.8968C9.81747 12.8968 9.45122 12.7492 9.18117 12.4864L5.99997 8.94749L2.81877 12.4852C2.68545 12.6171 2.52663 12.722 2.35145 12.7938C2.17628 12.8657 1.98823 12.903 1.79814 12.9038C1.60805 12.9046 1.41969 12.8687 1.24392 12.7982C1.06815 12.7278 0.908454 12.6241 0.77404 12.4933C0.639626 12.3625 0.533155 12.2071 0.460769 12.036C0.388382 11.8649 0.351512 11.6816 0.352286 11.4966C0.35306 11.3116 0.391462 11.1285 0.465278 10.958C0.539094 10.7875 0.646862 10.633 0.782367 10.5032L4.09197 6.82417L0.781167 3.1428C0.645662 3.01304 0.537894 2.85846 0.464078 2.68797C0.390263 2.51747 0.35186 2.33444 0.351086 2.14943C0.350312 1.96442 0.387182 1.78109 0.459569 1.61002C0.531955 1.43894 0.638426 1.28351 0.77284 1.15269C0.907255 1.02186 1.06695 0.918237 1.24272 0.847784C1.41849 0.777331 1.60685 0.741446 1.79694 0.742199C1.98703 0.742952 2.17508 0.780329 2.35025 0.852173C2.52543 0.924017 2.68425 1.02891 2.81757 1.16079L5.99997 4.70084L9.18117 1.16079C9.31448 1.02891 9.47331 0.924017 9.64848 0.852173C9.82365 0.780329 10.0117 0.742952 10.2018 0.742199C10.3919 0.741446 10.5802 0.777331 10.756 0.847784C10.9318 0.918237 11.0915 1.02186 11.2259 1.15269C11.3603 1.28351 11.4668 1.43894 11.5392 1.61002C11.6116 1.78109 11.6484 1.96442 11.6476 2.14943C11.6469 2.33444 11.6085 2.51747 11.5347 2.68797C11.4608 2.85846 11.3531 3.01304 11.2176 3.1428L7.90797 6.82417L11.2176 10.5032C11.3515 10.6334 11.4577 10.7879 11.5301 10.9581C11.6026 11.1282 11.6399 11.3106 11.6399 11.4948C11.6399 11.679 11.6026 11.8613 11.5301 12.0315C11.4577 12.2016 11.3515 12.3562 11.2176 12.4864Z"
                      fill="black"
                      fill-opacity="0.53"
                    />
                  </svg>
                </button>
              </div>
            </DialogBox>
          )}
          <ToastContainer
            position="top-right"
            autoClose={3000}
            closeOnClick
            pauseOnFocusLoss
            pauseOnHover
          />
        </div>
      )}
    </>
  );
}
