import React from "react";
import Select from "react-dropdown-select";

const CustomDropDown = ({
  noDataRenderer,
  values,
  style,
  searchable,
  options,
  onChange,
  labelField,
  disabled
}) => {
  const noDataComp = () => <div />;

  return (
    <Select
      values={values}
      options={options}
      onChange={onChange}
      labelField={labelField}
      searchable={searchable ?? false}
      style={style}
      disabled={disabled}
      noDataRenderer={noDataRenderer ?? noDataComp}
    />
  );
};

export default CustomDropDown;
