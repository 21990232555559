import React from 'react'

const TopBar = ({ tabs, selectedTab, onChange }) => {

    const Tab = ({ tab }) => {
        let selected = tab == selectedTab
        return (
            <div
                className={` text-secondary-dark transition-[border] duration-100 tracking-wide mr-[40px] outline-0 border-0 text-montserrat font-semibold text-[18px] px-2
                cursor-pointer ${selected && "text-tertiary-dark border-b-[1.1px] border-b-solid border-b-primary-dark "
                    }`}
                    onClick={()=>onChange(tab)}
            >
                <p>{tab}</p>
            </div>)
    }

    return (
        <div className='flex items-center border-b border-[#c5c1c4] w-fit' >
            {tabs.map(t => <Tab tab={t} />)}
        </div>
    )
}

export default TopBar