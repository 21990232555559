import React from "react";

export default function BtnSmallWhite({ children, margin, ...restProps }) {
  return (
    <button
      {...restProps}
      className={`flex items-center justify-center text-center border-1 border-solid border-primary-dark-20 bg-primary-white hover:border-2 hover:border-primary-dark hover:border-solid w-150 h-14 text-tertiary-dark`}
    >
      {children}
    </button>
  );
}
