import React from "react";

const BookExpirationInfo = ({ book }) => {
  function calculateExpiry(expireDate, createdDate) {
    const date1 = Date.parse(expireDate);

    const date2 = Date.parse(createdDate);

    const difference = date1 - date2;

    const day = Math.round(difference / (3600000 * 24));

    const percent = day / (book.validity * 365);

    return { day, percent: percent * 100 };
  }
  if (!book) return null;
  return (
    <div className="mt-3 md:mt-0 ">
      <div className=" p-2 border-1 border-solid border-primary-dark-20 w-full h-fit -48 mb-8">
        <div className=" px-4">
          <h2 className="text-heading font-bold text-h5 md:text-h4 text-left mb-2">
            Expiration Date
          </h2>
          <div className="flex justify-between items-center mb-2">
            <p className="flex-1 text-secondary-light text-semibold text-h5 mb-2">
              {/* {book?.title} */}
            </p>
            <p className="text-secondary-dark text-semibold text-h5 ">
              {
                calculateExpiry(book.expiresOn, book.createdAt, book.validity)
                  .day
              }{" "}
              days
            </p>
          </div>
          <div className=" h-[18px] bg-primary-light mb-6 rounded-full">
            <div
              className="h-full bg-primary-dark rounded"
              style={{
                rounded: "50px",
                width:
                  calculateExpiry(book.expiresOn, book.createdAt, book.validity)
                    .percent + "%"
              }}
            ></div>
          </div>
        </div>
        {/* {expired ? (""
              // <button className="text-heading font-semibold text-p1 h-[41px] w-full border-1 border-solid border-primary-dark-20">
              //   Reactivate
              // </button>
            ) : (
              <button className="text-heading font-semibold text-p1 h-[41px] w-full border-1 border-solid border-primary-dark-20">
                Renew
              </button>
            )} */}
      </div>

      <div className="  p-4 border-1 border-solid border-primary-dark-20 w-full ">
        <h2 classNaame="text-heading font-bold text-h4 text-left mb-2">
          Details
        </h2>
        <div className="my-4 mb-0 flex justify-between">
          <div className="w-1/2">
            <div className="mb-4">
              <h3 className="text-h5 text-secondary-dark font-semibold">
                Edition
              </h3>
              <p className="text-h4 text-tertiary-dark font-semibold">
                {book?.edition}
              </p>
            </div>

            {/* <div className="mb-4">
                        <h3 className="text-h5 text-secondary-dark font-semibold">
                          Book ID
                        </h3>
                        <p className="text-ellipsis overflow-hidden whitespace-nowrap mr-6 text-h4 text-tertiary-dark font-semibold">
                          {book?.id}
                        </p>
                      </div> */}

            <div className="mb-4">
              <h3 className="text-h5 text-secondary-dark font-semibold">
                Page Count
              </h3>
              <p className="text-h4 text-tertiary-dark font-semibold">
                {book?.countOfPages} Pages
              </p>
            </div>

            <div className="mb-4">
              <h3 className="text-h5 text-secondary-dark font-semibold">
                Print
              </h3>
              <p className="text-h4 text-tertiary-dark font-semibold">
                {book?.allowPrint ? "Yes" : "No"}
              </p>
            </div>
            {book?.customFieldName && (
              <div className="mb-4">
                <h3 className="text-h5 text-secondary-dark font-semibold">
                  {book?.customFieldName}
                </h3>
                <p className="text-h4 text-tertiary-dark font-semibold">
                  {book?.customFieldContents}
                </p>
              </div>
            )}

            {book?.ISBN && (
              <div className="mb-4">
                <h3 className="text-h5 text-secondary-dark font-semibold">
                  ISBN NO.
                </h3>
                <p className="text-h4 text-tertiary-dark font-semibold">
                  {book?.ISBN}
                </p>
              </div>
            )}
          </div>

          <div className="w-1/2">
            <div className="mb-4">
              <h3 className="text-h5 text-secondary-dark font-semibold">
                Category
              </h3>
              <p className="text-h4 text-tertiary-dark font-semibold">
                {book?.Bcategories?.map((elm, index) => (
                  <p
                    key={index}
                    className=" capitalize text-h4 text-tertiary-dark font-semibold"
                  >
                    {elm?.bookCategoryName}
                  </p>
                ))}
              </p>
            </div>

            <div className="mb-4">
              <h3 className="text-h5 text-secondary-dark font-semibold">
                Author
              </h3>
              <p className="text-h4 text-tertiary-dark font-semibold">
                {book?.Bauthors?.map((elm, index) => (
                  <p
                    key={index}
                    className="text-h4 text-tertiary-dark font-semibold"
                  >
                    {elm?.bookAuthorName}
                  </p>
                ))}
              </p>
            </div>

            <div className="mb-4">
              <h3 className="text-h5 text-secondary-dark font-semibold">
                Publishers
              </h3>
              {book?.Bpublications?.map((elm, index) => (
                <p
                  key={index}
                  className="text-h4 text-tertiary-dark font-semibold"
                >
                  {elm?.bookPublicationName}
                </p>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookExpirationInfo;
